<template>
  <div>
    <div class="row d-flex mb-3" style="min-height: 100vh;">
      <div class="col-xl-10 pe-0 d-flex flex-column">

        <div class="card card-body h-100">

          <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
          <div class="row">
            <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card card-body">
                <div class="row">
                  <p class="text-30">
                    {{ question }}
                  </p>
                </div>
                <div class="row">
                  <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: contain"> 
                </div>
              </div>
            </div>
            <div class="col-md-6">
                <div class="card card-body h-100">
                    <p class="lead" style="font-size: x-large;">
                        Need help? 
                    </p>
      

                    <button v-if="!showOptions" class="btn btn-secondary btn-lg mt-2"  @click="showOptions=true;showedOptions=true" >Get choices <i class="bi bi-list-ol"  style="margin-left: 3mm;"></i></button>

               
                    <div v-if="showOptions" >
                        <div class="card card-body mt-4" style="background-color: grey;">
                            <div class="row mb-4">
                                <span style="font-size: x-large;color: white;">Get choices: use one of the words below to name the picture</span>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-5">
                                <button class="custom-button-BV" @click="bv_tts.say(answer_options[0])">{{answer_options[0]}} 
                                    <br>
                                    <img :src="audioImg" style="width:35px;;cursor: pointer;"> 
                                </button>
                                </div>
                                <div class="col-2 text-center">
                                <p class="lead" style="font-size: x-large;color: white;">or</p>
                                </div>
                                <div class="col-5">
                                <button class="custom-button-BV" style="margin-left:2%" @click="bv_tts.say(answer_options[1])" >{{answer_options[1]}} 
                                    <br>
                                    <img :src="audioImg" style="width:35px;;cursor: pointer;" >
                                </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row justify-content-center" v-if="showOptions || userType=='slp'">
                            <button class="btn btn-bd-yellow btn-lg mt-4" style="width: 95%;" @click="showAnswer()" >Show answer <i class="bi bi-file-earmark-check" style="margin-left: 3mm;"></i></button>
                        </div>
                    </div>
                   
                </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div class="col-xl-2" style="padding-left: 0px">
        <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <div v-if="recognition_setting==0">
            <button v-if="!started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()"><strong>{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></strong></button>
            <button v-if="started && !congatulationsOnce" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>
            <button v-if="started && congatulationsOnce" disabled class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button>
          </div>
          <button class="btn btn-info btn-lg w-100 mt-4" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {toWords} from '@/utils/utils'

import {getSeconds} from '@/media_input/utils'

import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import SpeechRec from '@/media_input/SpeechRecognition'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {getData,getUserDataDir,updateDataDirectory} from '@/firebase/index'

import {logActivity,removePunctuationFromString} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'

import {BV_Points} from '@/utils/bv_points'

/*

{
   "Address":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "category":"vegetables",
   "difficulty":"easy",
   "exercise_type":"name_a_picture_easy",
   "hints":[
      "This is a vegetable",
      "This vegetable has layers",
      "This vegetable grows in the ground"
   ],
   "instruct":"Press Record. Say the word that matches the picture below",
   "name":"onion;onions;red_onion;white_onion",
   "word_type":"noun",
   "wrong_words":"tomato;sweetcorn;pepper",
   "plan_name":"Picture naming",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Picture naming/16",
   "ex_path":"Picture naming/16",
   "today":"2023-07-11 17:20",
   "audioIns1":null,
   "imageAddress":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"name_a_picture_easy",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Press Record. Say the word that matches the picture below",
   "module_name":"Picture naming",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
export default {
    name: 'count_in_images',
    props: {
      exForm: {
        type: Object,
      },
    },
    data() {
      return {
        sound_hints:false,
        recognition_setting: 0,
        client_demo: true,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        showOptions:false,
        showedAnswer:false,
        delay:0,
        task_end:0,
        task_start:0,
        answer_text: '',
        question:'',
        total_exercises:0,
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showedOptions:false,
        showedAnswer:false,
        curr_image_name:'',
        topic:'',
        first_exercise:true,
        tested_words_phonetics:{},
        target_words_phonetics:{},
        chosen_image: '',
        target_word: '',
        single_target_word: '',
        audio_player: null,
        phoneme_single_target_word: '',
        target_img_number: '',
        instructions_msg: '',
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Start',
        curr_exercise_number: 0,
        number_exercises:1,
        speech_rec: null,
        started: false,
        target_words: [],
        answer_options:[],
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        half_word_url: null,
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this

      logActivity( 'accessed', 'picture_naming')

      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))

      this.setup();

    },
    mounted()
    {
      const _this = this


    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()
      this.speech_rec.stop()

      clearTimeout(window.countDownTimer)
      clearTimeout(window.countDownTimer2)
    },
    computed: {

    currentSentence_found: async function () {
      

      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
      {


        let curr_sentence = this.currentSentence.toString().toLowerCase()

        curr_sentence = removePunctuationFromString(curr_sentence)

        console.debug('curr_sentence = ' + curr_sentence)

        let found_something = false

        let split_sentence = curr_sentence.split(' ')

        let best_answer = null
        let minimum_distance = 100

        let convert_words = {'1st':'first','2ns':'second','3rd':'third','qing':'chin','ting':'chin','p':'pea','b':'bee','10':'ten','2':'two','3':'three','4':'four','6':'six','9':'nine'}
        console.debug('this.target_words = ' + this.target_words)

        
        for(let n in split_sentence)
        {
          let curr_word = split_sentence[n]

          // if(convert_words[curr_word]!=undefined)
          // {
          //   curr_word = convert_words[curr_word]
          // }



          for(let i in this.target_words)
          {
            let distance = 100

            console.debug('this.target_words[i] = ' + this.target_words[i])
            console.debug('curr_word = ' + curr_word)

            
            
            distance = LevenshteinDistance(this.target_words[i],curr_word)
            distance = distance/Math.max(this.target_words[i].length,1)

            if(distance<minimum_distance && distance<0.30)
            {
              best_answer = this.target_words[i]
              found_something = true
              minimum_distance = distance

            }

          
          }
          if(best_answer!=null)
          {   
            if (this.congatulationsOnce == false) {

                startConfetti(this);
                this.congatulationsOnce = true;
            }
            this.found_correct_word(best_answer)
          }
          if(!found_something)
          {
            this.found_target_word = 'false'
          }
          else
          {
            return curr_sentence

          }
        }


      

        if(!found_something)
        {
          this.found_target_word = 'false'
        }
        else
        {
          return curr_sentence

        }

      }

      return ''

    },
    },
    
    methods: 
    {
      saveResults()
      {
        const _this = this

        if(this.results['correct']!=0 || _this.results['total']!=0)
        {
          this.delay = this.task_end-this.task_start

          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1}


          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}


          let results = {all_results:all_results, plot_results:plot_results}


          var data = {resultsTablePre: {exercise_type: 'Count in images', exercise: this.topic, results: results, audio_address:'',category:_this.topic,difficulty:'NA','showedAnswer':this.showedAnswer,'showOptions':this.showOptions,delay:this.delay}}


          save_pre_computed_results(_this.exForm, _this.$firebase, data)


          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', data);
        }
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      setup()
      {
        let _this = this;

        console.debug('setup')

        _this.answer_text = '';

        _this.first_exercise = !_this.exForm.has_results


        let client_demo = _this.exForm.client_demo


        if(client_demo!=undefined && client_demo!=null)
        {
            _this.client_demo = client_demo
        }

        let count_in_images_dir = 'Exercises/Resources/Count_in_images'


        console.debug('count_in_images_dir = ' + count_in_images_dir)
        getData(count_in_images_dir , function (err, result) {

          console.debug('here!!')

            let outputs = result.val()

            console.debug(outputs)

            let directories = Object.keys(outputs)

            let curr_dir_name = directories[Math.floor(Math.random() * directories.length)]

            let curr_dir = outputs[curr_dir_name]

            let image_options = Object.keys(curr_dir)

            let curr_image_option = image_options[Math.floor(Math.random() * image_options.length)]

            let using_folder = curr_dir[curr_image_option]

            _this.topic = curr_dir_name

            _this.curr_image_name = curr_image_option

            _this.questions = using_folder['questions']
            _this.img_address = using_folder['imageAddress']

            let random_question = Math.floor(Math.random() * _this.questions.length)

            _this.question = _this.questions[random_question].Question

            _this.answer = _this.questions[random_question].Answer

            _this.bv_tts.say(_this.question) 

            _this.task_start = getSeconds()



            let numeral_answer = toWords(_this.answer)

            _this.target_words = [numeral_answer,_this.answer]


            let second_option = 1

            if(_this.answer==1)
            {
              second_option = 2

            }
            else if(_this.answer==2)
            {
              second_option = 1
            }
            else if(_this.answer==3)
            {
              second_option = 4
            }
            else if(_this.answer>=4)
            {
              second_option = Math.floor(_this.answer/2)
            }

            _this.answer_options = [second_option,_this.answer]

            _this.single_target_word = _this.answer[0]

            var storageRef = _this.$firebase.storage().ref();
              storageRef.child(_this.img_address).getDownloadURL().then(function(url) {
                  _this.imgSrc = url;
              }).catch(function(error) {

              });






        })

        

 
        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises
        _this.retest_count = null


       
        



        // _this.answer_options = [_this.target_words[0],wrong_words[Math.floor(Math.random() * wrong_words.length)]]
        // _this.hints = [_this.exForm.hints[Math.floor(Math.random() * _this.exForm.hints.length)]]

      },
      showAnswer()
      {
        let _this = this;
        _this.showOptions = true
        _this.answer_text = 'The answer is '+_this.single_target_word;
        _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'

        if(_this.results['correct'] == 0)
        {

          this.task_end = getSeconds()

          _this.showedAnswer = true
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          _this.results['total'] = 1
        }
      },

      playPause()
      {
        let _this = this;

        _this.play_status = true;
        _this.resumeRecording()
        _this.results['total'] = 1
      },
      resumeRecording()
      {
        let _this = this;
        // recordAudioMedia()
        _this.is_recording = true;
        _this.congatulationsOnce = false;
        // _this.runTimer()
        _this.start_recording()
      },
      start_recording() {
        const _this = this
        this.speech_rec.start(this.target_words,false,this.region)
        this.started = true
        this.play_pause_text = 'Recording'
        console.debug('started')

        clearTimeout(window.countDownTimer)

        clearTimeout(window.countDownTimer2)
        
        window.countDownTimer2 = setTimeout(() => {
          _this.answer_text = 'It is recording now, name the picture out loud'
          _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        }, 500)
          
        window.countDownTimer = setTimeout(() => {
          if(_this.play_status)
          {
            _this.started = false
            _this.play_pause_text = 'Start'

            _this.answer_text = "Oops we missed what you said! Press Start and try again"
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }
          _this.stop_recording()
        }, 45000)
      },
      stop_recording() {
        const _this = this
        _this.play_status = false;
        _this.currentSentence = ''
        _this.speech_rec.stop()
      },
      found_correct_word(curr_sentence)
      {
        const _this = this

        _this.results['total'] = 1

        _this.best_answer = curr_sentence

        _this.answer_text = 'Congratulations! You said the right word: ' + curr_sentence
        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
        _this.play_pause_text = 'Start'


        _this.results['correct'] = 1
        _this.results['incorrect'] = 0

        _this.task_end = getSeconds()


        clearTimeout(window.countDownTimer)
        clearTimeout(window.countDownTimer2)
      },
      manualAnswer(is_correct)
      {
        const _this = this
        _this.results['total'] = 1

        if(is_correct)
        {
          // _this.answer_text = 'Congratulations! You said the right word: ' + found_word
          _this.results['correct'] = 1
          _this.results['incorrect'] = 0
          this.task_end = getSeconds()

        }
        else
        {
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          this.task_end = getSeconds()

        }
        _this.nextExercise()
      },
      pause()
      {
        let _this = this;
        _this.play_status = false;
        _this.play_pause_text = 'Start'
      },
    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>

<!-- Results Descriptions
{
  "Name a picture": {
    "<key>": { 
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming",
        "exercise_type": "Word Naming",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed"

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture"
  }
} -->


<!--
{
  "Name a picture": { --decide the module name ID (this or 'Word Naming')
    "<key>": { --move under 'exercises'
      "audio_address": "<empty>", --not used remove
      "resultsTablePre": {
        "audio_address": "<empty>", --not used remove
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming", --change to 'Name of picture'
        "exercise_type": "Word Naming", --change to 'Name a picture'
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed" --not used remove

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture" --not used remove
  }
} -->