<template>
  <div>
  <div class="row d-flex mb-3" >
      <div class="col-xl-10 pe-0 d-flex flex-column">
        <div class="card card-body h-100">
          <div class="card card-body">
              <span class="read-text">{{ instructions }}
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="read_instructions()"></span>
          </div>
          <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->

          <div class="row justify-content-center mt-4">
            <!-- <div class="col-md-4" @click="wordTTS()" > -->
        
              
              
              <div v-if="task==0 && words_found.length !== 0" class="row justify-content-center">
                <!-- <div v-for="wd,id in words_found"> -->
                  <div class="col-md-6">
                    <!-- <div class="card card-body custom-card_blue mt-2"> -->
                      <div v-for="wd,id in words_found">
                        <span v-if="id%2 == 0" class="card card-body custom-card_blue mt-2">{{words_found[id]}} </span>
                      </div>
                    <!-- </div> -->
                  </div>
                  <div class="col-md-6">
                    <div v-for="wd,id in words_found">
                      <span v-if="id%2 == 1" class="card card-body custom-card_blue mt-2">{{words_found[id]}} </span>
                    </div>
                  </div>
                <!-- </div> -->
              </div>
              <div v-if="task==1">
                <div class="row justify-content-center">
                  <div class="col-md-3">
                    <div class="form-check">
                      <div v-for="wd,id in words_found">
                        <div v-if="id%2 == 0">
                          <div v-if="id<5">
                            <input class="form-check-input mt-4" type="checkbox" v-model="words_found_checked[id]" :value="words_found[id]" @change="update_wordlist()" :id="id" checked>
                            <!-- <label class="form-check-label" :for="id"> -->
                              <div class="card card-body custom-card_blue_fix mt-2">
                                <span style="font-size: x-large;" @click="bv_tts.say(wd)">{{wd}} <img :src="audioImg" style="width:30px;"></span>
                              </div>
                            <!-- </label> -->
                          </div>
                          <div v-else>
                            <input class="form-check-input mt-4" type="checkbox" :id="id" v-model="words_found_checked[id]" :value="words_found[id]" @change="update_wordlist()">
                            <!-- <label class="form-check-label" :for="id"> -->
                              <div class="card card-body custom-card_blue_fix mt-2">
                                <span style="font-size: x-large;" @click="bv_tts.say(wd)">{{wd}} <img :src="audioImg" style="width:30px;"></span>
                              </div>
                            <!-- </label> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <div class="form-check">

                      <div v-for="wd,id in words_found">
                        <div v-if="id%2 == 1">
                          <div v-if="id<5">
                            <input class="form-check-input mt-4" type="checkbox" v-model="words_found_checked[id]" :value="words_found[id]" @change="update_wordlist()" :id="id" checked>
                            <!-- <label class="form-check-label" :for="id"> -->
                              <div class="card card-body custom-card_blue_fix mt-2">
                                <span style="font-size: x-large;" @click="bv_tts.say(wd)">{{wd}} <img :src="audioImg" style="width:30px;"></span>
                              </div>
                            <!-- </label> -->
                          </div>
                          <div v-else>
                            <input class="form-check-input mt-4" type="checkbox" :id="id" v-model="words_found_checked[id]" :value="words_found[id]" @change="update_wordlist()">
                            <!-- <label class="form-check-label" :for="id"> -->
                              <div class="card card-body custom-card_blue_fix mt-2">
                                <span style="font-size: x-large;" @click="bv_tts.say(wd)">{{wd}} <img :src="audioImg" style="width:30px;"></span>
                              </div>
                            <!-- </label> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  


                  
                </div>
                
              </div>
              
            <div v-if="task==2" class="col-md-4" style="margin-top: 3%;" @click="wordTTS()" >
                <div class="card card-body custom-card_blue">
                  <span class="read-text">{{selected_word.substring(0,answer.length)}} <b style="color:#f56c6c"> {{selected_word.substring(answer.length,answer.length+1)}}  </b> {{ selected_word.substring(answer.length+1,selected_word.length) }}<img :src="audioImg" @click="bv_tts.say(selected_word.substring(answer.length,answer.length+1))" style="width:35px;;cursor: pointer;"></span>
                </div>
              </div>
              <div v-if="task>2" class="col-md-4">
                <div class="card card-body custom-card_blue">
                  <span class="read-text">{{selected_word}} <img :src="audioImg" @click="bv_tts.say(selected_word)" style="width:35px;;cursor: pointer;"></span>
                </div>
              </div>
              

              <!-- <div class="card card-body custom-card_blue">
                <span class="read-text">{{selected_word}} <img :src="audioImg" style="width:35px;;cursor: pointer;"></span>
              </div> -->

            <!-- </div> -->
            
          
          </div>

          <div v-if="task>1" style="margin-top: 6mm;">
            <div class="row mt-2 justify-content-center">
              <span style="font-size: x-large;"> {{your_answer_txt}} </span>

              <input v-model="answer"  @input="auto_answer_checker()"  style="font-size: x-large; width:40%"/>
              <!-- <button v-if="button_check" class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check()">
                Submit answer <i class="bi bi-send" style="margin-left: 5mm;"></i>
              </button> -->
            </div>
          </div>

          <div style="margin-top:1%;margin-bottom: 1%">
            <span class="empty_item" :style="msg_colour" style="margin-top:2%">{{result_message}}</span>
          </div>
          
        </div>
      </div>

      <div class="modal fade" id="level_up_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Level up</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="m-3">
                  <div class="row">
                    <h4 >{{ level_up_message }}</h4>
                  </div>
                  <div class="row mt-2">
                    <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      <div class="col-md-2" style="padding-left: 0px">
        <div class="card card-body h-100">
          <!-- <p class="lead">Exercise {{ex_counter}} of {{number_exercises}}</p> -->
          <p v-if="task<2" class="lead">Word selection</p>
          <p v-else class="lead">Word {{curr_word_count+1}} of {{words_found.length}}</p>

          <hr/>
          <p v-if="task<2" class="lead" font-size="medium">Task {{task+1}} of {{2}}</p>
          <p v-else class="lead" font-size="medium">Task {{task-1}} of {{2}}</p>

          <!-- <p class="lead" font-size="medium">Task {{Math.min(task,n_tasks)+1}} of {{n_tasks+1}}</p> -->

          <button v-if="task==0 && !play_status" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button>
          <button v-if="task==0 && play_status" class="btn btn-primary btn-lg w-100 mt-2" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
          </button>
          <button v-if="task==0 && play_status && this.words_found.length" class="btn btn-info btn-lg w-100 mt-2"  @click="nextTask()">Stop <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
          <button v-else-if="task==1 && this.words_found_checked.some(Boolean)" class="btn btn-info btn-lg w-100 mt-2"  @click="nextTask()">Next <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
          <button v-else-if="task>1  || this.words_found.length" class="btn btn-info btn-lg w-100 mt-2"  @click="nextTask()">Next <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
          <button v-if="show_results" class="btn btn-outline-warning btn-lg w-100 mt-2" @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
    
</template>
<script>

import SpeechRec from '@/media_input/SpeechRecognition'

import {getData, getUserDataDir, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import audioImg from '@/assets/images/audio_logo.png'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'
import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'
import words from '@/media_input/words'

import {LevenshteinDistance} from '@/utils/text_distance_metrics'

export default {
  name: 'Copy_word',
  props: 
  {
    exForm: {
      type: Object,
    },
    /*
      exForm input example
      {
        "Name":"Antonym easy",
        "difficulty":"easy",
        "exercise_type":"antonym_exercise",
        "plan_name":"Antonym",
        "assessment_type":"SLP_TRAINING",
        "curr_exercise_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "ex_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "today":"2023-07-11 16:20",
        "audioIns1":null,
        "imageAddress":null,
        "videoAddress":null,
        "audioAddress":null,
        "audioIns2":null,
        "videoIns":null,
        "exercise_type":"antonym_exercise",
        "url":null,
        "url2image":null,
        "audioIns":null,
        "module_name":"Antonym",
        "has_results":false,
        "current_exercise_index":0,
        "number_exercises":7
      }

    */
  },
  data(){
    return {

      curr_word_count:0,
      common_words:[],
      type:'starting_letter',
      play_status: false,
      starting_letter:'d',
      play_pause_text: 'Start',
      started: false,
      is_recording: false,
      speech_rec: null,
      currentSentence:'',
      words_found:[],
      words_found_checked:[],
      level_address:'',
      correct_counter:0,
      incorrect_counter:0,
      n_tasks:3,
      task:0,
      answer:'',
      instructions:'',
      result_message: '',
      selected_word: '',
      level_up_counter: 0,
      total:0,
      step:'word',
      ex_counter: this.exForm.current_exercise_index + 1,
      failed_already: false,
      number_exercises: this.exForm.total_all_type_exercises,
      difficulty: 'easy',
      past_exercises: [],
      your_answer_txt: 'Your answer',
      level_up_message:'',
      msg_colour:'',
      first_exercise: false,
      show_results: false,
      audioImg:audioImg,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {
    const _this = this

    _this.show_results = _this.exForm.has_results

    this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))


      if('letter' in this.exForm)
      {
        this.type = 'starting_letter'
        this.starting_letter = this.exForm.letter

        for(let k in words.word_2_phoneme)
        {
          if(k[0]==this.starting_letter)
          {
            this.common_words.push(k)
          }

        }
        console.debug('this.common_words = ' + this.common_words)

      }

      if('topic' in this.exForm)
      {
        this.type = 'topic'
        this.topic = this.exForm.topic

        this.common_words = this.exForm.name.toLowerCase().split(';')




        console.debug('this.common_words = ' + this.common_words)

        
      }








    logActivity( 'accessed', 'copy_word_exercise')

    _this.setup_task()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  computed: {

currentSentence_found: function () {

  if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
  {


    let curr_sentence = this.currentSentence.toString().toLowerCase()

    var regex = /[.,]/g;

    curr_sentence = curr_sentence.replace(regex, '');

    let words = curr_sentence.split(' ')

    console.debug('words = ' + words)

    let convert_words = {'1st':'first','2ns':'second','3rd':'third','qing':'chin','ting':'chin','p':'pea','b':'bee','10':'ten','2':'two','3':'three','4':'four','6':'six','9':'nine','t':'tea'}


    for(let i in words)
    {


      let curr_word = words[i]


      curr_word = curr_word.replace(/[^A-Za-z]/g, '');

      if(convert_words[curr_word]!=undefined)
      {
        curr_word = convert_words[curr_word]
      }


        this.check_word_condition(curr_word)
        
    }
    return curr_sentence
  }

  return ''

},
},

  methods: {
    check_word_condition(word)
    {

      if(this.type==='starting_letter')
      {
        if(this.starting_letter_condition(word))
        {
            if(this.words_found.indexOf(word)==-1)
            {
                this.words_found.push(word)
            }

        }


      }
      else if(this.type==='topic')
      {

        if(this.common_words.indexOf(word)!=-1)
        {
            if(this.words_found.indexOf(word)==-1)
            {
                this.words_found.push(word)
            }

            

        }
        else
        {
          
          let minimum_distance = 100

          let found_something = false

          let best_answer = null

          for(let k in this.common_words)
          {
            let target_word = this.common_words[k]

            let distance = LevenshteinDistance(target_word,word)

            distance = distance/Math.max(target_word.length,1)

            if(distance<minimum_distance && distance<0.30)
            {
              best_answer = target_words
              found_something = true
              minimum_distance = distance

            }
            

            
          }

          if(best_answer!=null)
          {   

            if(this.words_found.indexOf(best_answer)==-1)
            {
                this.words_found.push(best_answer)
            }
          }


        }

      }

  



    },
    starting_letter_condition(word)
    {
        if(word && word[0]==this.starting_letter)
        {
            return true
        }
        else
        {
            return false
        }

    },
    update_wordlist()
    {
      console.debug("wordlist = "+this.words_found_checked)
    },
    playPause()
      {
        let _this = this;

        _this.play_status = true;
        _this.resumeRecording()
      },
      resumeRecording()
      {
        let _this = this;
        _this.is_recording = true;
        _this.start_recording()
      },
      start_recording() {
        const _this = this



        this.speech_rec.start(this.common_words,true)
        this.started = true
        this.play_pause_text = 'Recording'


        clearTimeout(window.countDownTimer2)
        

        window.countDownTimer = setTimeout(() => {
          if(_this.play_status)
          {
            _this.started = false
            _this.play_pause_text = 'Start'

            _this.answer_text = "Oops we missed what you said! Press Start and try again"
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }
          _this.stop_recording()
        }, 45000)
      },
      stop_recording() {
        const _this = this
        _this.play_status = false;
        _this.currentSentence = ''
        _this.speech_rec.stop()
      },


    auto_answer_checker()
    {
      const _this = this

      console.debug('this.task = ' + this.task)


      let selected_word_lower = _this.selected_word.toLowerCase()
      let answer_lower = _this.answer.toLowerCase()

      if(this.task==2 && answer_lower.length>0)
      {

        if(selected_word_lower.length>=answer_lower.length && selected_word_lower[answer_lower.length-1] === answer_lower[answer_lower.length-1])
        {
          this.correct_single_letter()

        }
        else
        {
          if(!this.failed_already)
          {
            this.failed_already = true
            this.total = this.total+1
            this.incorrect_counter = this.incorrect_counter+1
          }
          this.incorrect_single_letter()
        }

      }

      

      if(_this.answer.toLowerCase()==_this.selected_word.toLowerCase() && (_this.task==2 || this.task==3))
      {
        if(!_this.failed_already)
        {
          _this.correct_counter = _this.correct_counter+1
          this.total = this.total+1
          startConfetti(this)

        }

        console.debug('_this.correct_counter = ' + _this.correct_counter)
        _this.result_message = "Congrats! You got the right word"
        _this.msg_colour = "color:green;fontSize:20px;";
        _this.level_up_counter = _this.level_up_counter+1
 

        // if(_this.correct_streak>=4)
        // {
        //   if(_this.difficulty=='easy')
        //   {
        //     _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
        //     let level_up_pop = new Modal(document.getElementById('level_up_pop'));
        //     level_up_pop.show()

        //     _this.difficulty='medium'
        //     _this.correct_streak = 0
        //     _this.past_exercises = []
        //   }
        //   else if(_this.difficulty=='medium')
        //   {
        //     _this.past_exercises = []

        //     _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
        //     let level_up_pop = new Modal(document.getElementById('level_up_pop'));
        //     level_up_pop.show()

        //     _this.correct_streak = 0

        //     _this.difficulty='hard'
        //   }
        // }

        

        updateDataDirectory(_this.level_address,{current_level:_this.current_level,level_up_counter:_this.level_up_counter})

        // setTimeout(() => {
        //     this.nextExercise()
        //     }, 900);


      }
    },
    wordTTS()
    {
      if(this.task==2)
      {
        this.bv_tts.say(this.selected_word.substring(this.answer.length,this.answer.length+1))
      }
      else
      {
        this.bv_tts.say(this.selected_word)
      }

    },
    correct_single_letter()
    {

      if(this.selected_word!==this.answer)
      {

        let correct_single_letter_message = 'Correct. ' + this.selected_word[this.answer.length]
        this.bv_tts.say_sentences(['Correct. ', this.selected_word[this.answer.length]])
      }
      else
      {
        if(!this.failed_already)
        {
          this.correct_counter = this.correct_counter+1
          this.total = this.total+1
        }
        this.bv_tts.say('Good job.')
        this.nextTask()
      }

    },
    incorrect_single_letter()
    {
      let incorrect_single_letter_message = this.answer[this.answer.length-1] + ' is not the right letter. The letter is ' + this.selected_word[this.answer.length-1]

      this.answer = this.answer.substring(0,this.answer.length-1)
      this.bv_tts.say_sentences([incorrect_single_letter_message])
    },
    nextExercise()
    {
      const _this = this;
      _this.saveResults()
      _this.$emit('nextExercise');
    },
    goToResults()
    {
      this.saveResults()
      
      let goToResultsData = {curr_ex_done:false}
      this.$emit('goToResults', goToResultsData);
    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:this.correct_counter+this.incorrect_counter,type:this.type}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
      
        let results = {all_results:all_results, plot_results:plot_results}

        var copy_word_data = {resultsTablePre: {exercise_type: 'Copy Words', exercise: 'Copy Words', results: results, audio_address:''}}

        save_pre_computed_results(_this.exForm,_this.$firebase,copy_word_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', copy_word_data);
      }
    },

      nextTask()
      {

        if(this.task==0)
        {
          for(let k in this.words_found)
          {
       
            this.words_found_checked.push(k<5)
          }
          this.stop_recording()

        }
        else if(this.task==1)
        {
          console.debug('this.words_found_checked')

          console.debug(this.words_found_checked)

          let temp_words_found = []

          for(let k in this.words_found_checked)
          {
            if(this.words_found_checked[k])
            {
              temp_words_found.push(this.words_found[k])
            }
          }

          this.words_found = temp_words_found

          console.debug('this.words_found = ' + this.words_found)
        }


        this.bv_tts.cancel()

        this.task = this.task + 1


        if(this.task>this.n_tasks)
        {

            if(this.curr_word_count>=this.words_found.length-1)
            {
                this.nextExercise()
            }
            else
            {
                this.curr_word_count = this.curr_word_count+1
                this.task=2
                this.setup_task()
            }
        }
        else
        {
          this.setup_task()
        }

      },
      setup_task()
      {

        console.debug('setup_task')
        this.failed_already = false

        if(this.task==0)
        {
          this.setup_task_0()
        }
        else if(this.task == 1)
        {

          this.setup_task_1()

        }
        else if(this.task==2)
        {
          this.answer = ''

          this.setup_task_2()
        }
        else{
          this.answer = ''

          this.setup_task_3()
        }

      },
      read_instructions()
      {
        if(this.task==0)
        {
          this.setup_task_0()

        }
        else if(this.task == 1)
        {
          this.setup_task_1()

        }
        else if(this.task==2)
        {
          this.setup_task_2()
        }
        else
        {
          this.setup_task_3() 
        }
      },
      setup_task_0()
      {
        const _this = this

        if(this.type==='starting_letter')
        {
          this.instructions = 'Press Start and say some words starting with the letter ' + this.starting_letter.toUpperCase()
          this.task_instructions = [this.instructions]
        }
        else if(this.type=='topic')
        {
          this.instructions = this.exForm.instruct
          this.task_instructions = [this.instructions]


        }


        this.bv_tts.say_sentences(this.task_instructions, 125)
        console.debug('setup_task_0')
      
      },
      setup_task_1()
      {
        const _this = this
        this.instructions = 'Select which of the words you want to practice writing'
        this.task_instructions = [this.instructions]

        this.bv_tts.say_sentences(this.task_instructions, 125)
        console.debug('setup_task_0')



      },
      setup_task_2()
      {
        const _this = this

        this.selected_word = this.words_found[this.curr_word_count]

        this.task_instructions = ["Let's write the word","Type the letter. "+ this.selected_word[this.answer.length]]
        this.instructions = 'Write the word letter by letter'
        this.bv_tts.say_sentences(this.task_instructions)

      
      },
      setup_task_3()
      {
        const _this = this

        this.instructions = "Now type the word by yourself"

        this.task_instructions = [this.instructions]

        this.bv_tts.say_sentences(this.task_instructions)

      }
  },
}
</script>

<style scoped>

.inline-block-child {
  display: inline-block;
}

.custom-card_blue{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
}
.custom-card_blue_fix{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
    width: 60mm;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.empty_item:empty:before {
  content: "\200b";
}


</style>

<!-- Result Description

{
  "Antonym Exercise": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Antonyms": { //Need to remove this layer
          "exercises": { //Need to remove this layer
            "easy": { //Need to remove this layer
              "<key>": {
                "audio_address": "<empty>",
                "resultsTablePre": {
                  "audio_address": "<empty>",
                  "exercise": "Antonym Exercise",
                  "exercise_type": "Antonym Exercise",
                  "results": {
                    "all_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Number_Hints": "<int>",
                      "Total": "<int>"
                    },
                    "plot_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Total": "<int>"
                    }
                  }
                },
                "results_type": "pre_computed"
              }
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Antonym Exercise"
  }
}
-->