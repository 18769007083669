<template>
  <div class="">
    
    <v-tour name="homeTour" :steps="steps" :callbacks="tourCallbacks"></v-tour>    
    <div class="row g-5 mb-5">
      
      <div class="col-lg-8">
        <h1 class="display-1">Hi {{ displayName }}!</h1>
        <h1 class="display-6 pb-2">Let's get started...</h1>
        <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <h4 class="mt-4"><strong>Activities</strong></h4>
        <p class="lead">Practise your speech and language skills individually or as a group</p>
        <div class="row text-center">
          <div class="col-md-6">
            <div @click="gotoTraining()" class="v-step-1">                      
              <div class="card text-start shadow-sm">
                <div class="position-relative">
                  <span v-if="issue_flag[1]||issue_flag[2]||issue_flag[0]" class="badge rounded-pill bg-danger position-absolute m-2">NEW</span>
                  <button type="button" class="btn btn-success rounded-circle position-absolute" style="bottom:-15%; left: 80%"><i class="bi bi-arrow-right-short" style="color:white; font-size: 1.5rem;"></i></button>
                  <img :src="trainingImg" class="card-img-top" style="cursor:pointer">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Daily training</h5>
                  <p class="card-text">Practise your speech and language skills</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div @click="goToAssignments()" class="v-step-2">
              <div class="card text-start shadow-sm">
                <div class="position-relative">
                  <span v-if="foundAssignment" class="badge rounded-pill bg-danger position-absolute m-2"><div v-if="newAssignment">NEW</div><div v-else>You have assignments</div></span>
                  <span v-else class="badge rounded-pill bg-danger position-absolute m-2" style="z-index: 1">No homework assigned</span>
                  <img :src="homework" class="card-img-top" :style="!foundAssignment ? { 'filter': 'grayscale(100%)', 'cursor': 'pointer'} : { 'cursor': 'pointer' }">
                  <button type="button" :class="['btn', foundAssignment?'btn-success':'btn-secondary', 'rounded-circle', 'position-absolute']" style="bottom:-15%; left: 80%"><i class="bi bi-arrow-right-short" style="color:white; font-size: 1.5rem;"></i></button>
                </div>
                <div class="card-body">
                  <h5 class="card-title">Home assignments</h5>
                  <p class="card-text">Complete exercises set by your therapist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-md-6">
            <div @click="groupActivities()" class="v-step-3">
              <div class="card text-start shadow-sm">
                <div class="position-relative">
                  <button type="button" class="btn btn-success rounded-circle position-absolute" style="bottom:-15%; left: 80%"><i class="bi bi-arrow-right-short" style="color:white; font-size: 1.5rem;"></i></button>
                  <img :src="groupActImg" class="card-img-top" style="cursor:pointer">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Group activities</h5>
                  <p class="card-text">Practise speech and language as a group</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div data-bs-toggle="modal" data-bs-target="#assessment_modal" class="v-step-4">
              <div class="card text-start shadow-sm">
                <div class="position-relative">
                  <button type="button" class="btn btn-success rounded-circle position-absolute" style="bottom:-15%; left: 80%"><i class="bi bi-arrow-right-short" style="color:white; font-size: 1.5rem;"></i></button>
                  <img :src="assessImg" class="card-img-top" style="cursor:pointer">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Self-check</h5>
                  <p class="card-text">Identify areas of weakness and strength</p>
                </div>
              </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="assessment_modal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5">Self check</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <h4 class="mb-4"> What type of assessment would you like to do? </h4>
                    <div class="row mb-4">
                      <div class="col">
                        <button class="btn btn-success btn-lg" type="button" data-bs-dismiss="modal" @click="gotoAphasiaAssessment()">Language</button>
                      </div>
                      <div class="col">
                        <button class="btn btn-success btn-lg" type="button" data-bs-dismiss="modal" @click="gotoIntelligibility()" >Speech</button>
                      </div>
                      <div class="col">
                        <button class="btn btn-success btn-lg" type="button" data-bs-dismiss="modal" @click="gotoLoudnessAssessment()" >Loudness</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body m-2">
            <div class="row" v-show="firstname">
              <h2><i class="bi bi-person" style="font-size: 3rem; color:purple"></i> {{ firstname }}  {{ surname }}</h2> 
            </div>
            <div class="row mt-2 v-step-5 justify-content-between">
              <div class="col m-1 p-2 text-center shadow-sm" style="background-color: rgba(86, 3, 173, 0.08); border-radius:10px 10px 10px 10px;" :class="{'animate__animated animate__headShake': goals_hover}" @mouseover="goals_hover=true" @mouseout="goals_hover=false">
                <div style="cursor:pointer" class="d-flex flex-column justify-content-center h-100" @click="$router.push({'name': 'goals'})">
                  <i class="bi bi-crosshair fs-2" style="color:darkgoldenrod"></i>
                  <p class="mb-0"><strong>Goals</strong></p>
                </div>
              </div>
              <div class="col m-1 p-2 text-center shadow-sm" style="background-color: rgba(86, 3, 173, 0.08); border-radius:10px 10px 10px 10px;">
                <div class="d-flex flex-column justify-content-center h-100">
                  <i class="bi bi-star" style="font-size: 1.5rem;color:darkgoldenrod"></i>
                  <p class="mb-0"><strong>Beautiful points</strong></p>
                  <p class="mb-0"><strong>{{BV_total_points}}</strong></p>
                </div>
              </div>
            </div>
            <div class="row mt-4 v-step-6" v-if="userCategory == 'patient'" >
              <h4><strong>Your progress</strong></h4>
              <div class="text-center">
                <div @click="pastResults()"> 
                  <div class="card text-start">
                    <img :src="progressImg" class="card-img-top" style="cursor:pointer">
                    <div class="card-body">
                 
                      <p class="card-text">Check your progress and see your improvement</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 v-step-7">
              <h4><strong>Your therapist</strong></h4>
              <p> Your connected therapist reviews your progress and can assign to you homework to improve your speech and language.</p>
              <div v-if="selected_slt_uid && slt_entries.length > 0">
                <div class="row mt-2 mb-2">
                    <select class="form-select rounded-pill" v-model="selected_slt_uid" @change="set_selected_slt()">
                      <option v-for="slt_entry of slt_entries" :value="slt_entry[0]" :selected="slt_entry==selected_slt_uid">
                        <h4>{{slt_entry[1].firstname}} {{slt_entry[1].surname}}</h4>
                      </option>
                    </select>
                </div>
                <div class="row mt-2 ">
                  <button class="btn btn-outline-primary rounded-pill" @click="$router.push({name: 'training_room', query: {slt: selected_slt_uid}})">Attend Assessment <i class="bi bi-person-video3"></i> </button>
                  <button class="btn btn-outline-success rounded-pill mt-2" data-bs-toggle="offcanvas" href="#offcanvasVideoCall" role="button" aria-controls="offcanvasVideoCall">Join Live Meeting <i class="bi bi-telephone-forward-fill"></i> </button>
                </div>
              </div>
              <div v-else>
                <p><i>You are currently not connected with any speech language therapist.</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>



import assessImg from '@/assets/images/dashboard/patient/selfcheck.svg'
import trainingImg from '@/assets/images/dashboard/patient/daily_training.svg'
import homework from '@/assets/images/dashboard/patient/homework.svg'
import groupActImg from '@/assets/images/dashboard/patient/group_activities.svg'
import progressImg from '@/assets/images/dashboard/patient/progress.jpg'

import docImg from '@/assets/images/doctor.png'

import {updateDataDirectory, getUserDataDir, setData} from '@/firebase/index'

import {substractDayFromWeekInfo, getWeekNumber} from '@/media_input/utils'

import { toRaw } from 'vue';

export default {
  components: {  },
  name: 'patient_dashboard',
  props: 
  {

  },
  data(){
    return {
      firstname: '',
      surname: '',
      displayName: '',
      BV_total_points:0,
      BV_streaks:0,
      userCategory: localStorage.getItem('userCategory'),
      
      assessImg:assessImg,
      trainingImg:trainingImg,
      progressImg:progressImg,
      homework:homework,
      groupActImg:groupActImg,
      docImg:docImg,

      foundAssignment: false,
      newAssignment: false,
      
      issue_flag:[true,true,true,true],
      issue_lib:['Slurred/choppy speech','Difficulty in moving lips, jaw or tongue','Hoarse/breathy voice','Dysphasia/aphasia'],
      
      hover: false,
      selected_slt_uid: null,
      trophy_hover: false,
      goals_hover: false,
      slt_entries: [],
      steps: [
        {
          target: '.v-step-0',
          content: 'Let’s get to know Beautiful Voice better!',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-1',
          content: 'In daily training, you can find exciting and fun exercises to practise every day!',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-2',
          content: 'If you have a speech and language therapist, you can find the homework they made for you here.',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-3',
          content: 'Have fun with your family and friends in group-based exercises!',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-4',
          content: 'With self-check you can assess your progress at any time.',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-5',
          content: 'Earn beautiful point for every exercise that you complete. The higher the score the better!',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-6',
          content: '“Here you can track your progress. Find out what works the best for you.',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-7',
          content: 'You can find additional details about your therapist. Respond to their invites for face-to-face meetings and assessments here.',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-8',
          content: 'You can change your settings here. Make the platform more personalised to you.',
          params: {
            highlight: true
          }
        },
        {
          target: '.v-step-9',
          content: 'You can always go back to your main menu by clicking "Home".',
          params: {
            highlight: true
          }
        },

      ],
      tourCallbacks: {
        onFinish: ()=>this.hideNavNotifications(false)
      }
    }
  },
  created() {

    let _this = this
    this.userCategory = localStorage.getItem('userCategory');

    getUserDataDir('Data', function (err, result) {
      let data = result.val();
      
      if (data.hasOwnProperty('onboarding') ) {
        if (!data['onboarding'].hasOwnProperty('finished')) {
          _this.$router.push({ name: 'register'})                
        }
      } 

    });
    

    let enteredOnce = true;

    getUserDataDir('Data' , function (err, result) {
      if (enteredOnce) {

        enteredOnce = false;

        let startTour = result.child('startTour').val();
        
        if (_this.userCategory === 'slp' && (startTour['patient_home_dashboard'] === undefined || startTour['patient_home_dashboard'] === false)) {
          
          _this.$tours['homeTour'].start()
          
          if (typeof startTour !== "object") {
            startTour = {}
          }

          startTour['patient_home_dashboard'] = true

          let uid = localStorage.getItem('uid')
          let ref_path = 'Users/' + uid + '/Data/'

          updateDataDirectory(ref_path, {'startTour': startTour})
          
        } else if (_this.userCategory === 'patient') {
        
          if (startTour === null || startTour) {
            _this.$tours['homeTour'].start()

            let uid = localStorage.getItem('uid')
            let ref_path = 'Users/' + uid + '/Data/'
            
            updateDataDirectory(ref_path, {'startTour': false})
          }
        }

        _this.firstname = result.child('Firstname').val();
        _this.surname = result.child('Surname').val();

        if (result.hasChild('userName')) {
          let name = result.child('userName').val();

          if(name !== '' || name != undefined || name != null) {
            _this.displayName = " " + name
          } else {
            _this.displayName = "";
          }
        } else if (_this.displayName === "" && result.child('Firstname').val() != null) {
          _this.displayName = " " + result.child('Firstname').val();
        } else {
          _this.displayName = ""
        }

        let slt_handle = result.child('SLT').val()

        _this.slt_entries = Object.entries(slt_handle).filter(([key, value]) => value.hasOwnProperty('email'));
        //sort latest to oldest
        _this.slt_entries.sort((a, b) => Number(b[1].timestamp) - Number(a[1].timestamp));

        _this.selected_slt_uid = _this.slt_entries[0][0]
        _this.set_selected_slt()

        _this.getSettings()
      }
    });
  },
  mounted() {

    this.checkAssignments()
    this.readCondition();
    this.computeTotalPoints();
  },
  methods: {
    set_selected_slt() {
      if (this.selected_slt_uid) {
        localStorage.setItem('call_uid', this.selected_slt_uid);
      }
    },
    getSettings()
    {
      getUserDataDir('Settings' , function (err, result) {

        let settings = result.val()

        if(settings && settings.hasOwnProperty('intelligibility_grading_selection')) {
          localStorage.setItem('intelligibility_grading_selection', settings['intelligibility_grading_selection']);
        }
      })

    },
    hideNavNotifications(state) {
      this.$emit("hideNavNotifications", state)
    },
    setCallUID() {
      
    },
    gotoTraining()
    {
      this.$router.push({ name: 'daily_training'});
    },
    pastResults()
    {
      if(this.userCategory=='slp') {
        alert('You cannot preview this feature as an slt');
      } else {
        this.$router.push({name: 'myProgress'})
      }
    },
    groupActivities()
    {
      let _this=this;
      _this.$router.push({ name: 'groupActivities'});
    },
    readCondition()
    {
      let _this  = this;
      _this.issue_flag = [false,false,false,false];
      _this.userCategory = localStorage.getItem('userCategory');
      let user_page_dir = 'Data/';
      var do_once = true;

      getUserDataDir(user_page_dir , function (err, result) {

        //Inputs new exercise on the current user's database

        if (do_once) 
        {
          do_once = false;

          if (result.hasChild('Qs_diseaseType')) {
            let result_condition = result.child('Qs_diseaseType')

            let num_issue = result_condition.numChildren();

            if(num_issue==undefined || num_issue==null || num_issue<1) {
              for(let i=0;i<4;++i) {
                _this.issue_flag[i] = true;
              }
            } else {
              for (let i=0;i<num_issue;i++) {
                let issue = result_condition.child(i).val();
                let issue_id = _this.issue_lib.indexOf(issue);
                _this.issue_flag[issue_id] = true;
              }
            }
          }
          else {
            for(let i=0;i<4;++i) {
              _this.issue_flag[i] = true;
            }
          }

          localStorage.setItem('issue_flag',_this.issue_flag);

          let loudness_target = "65"

          if(result.hasChild('loudness_target'))
          {
            loudness_target = result.child('loudness_target').val()
          }
          localStorage.setItem('loudness_target',loudness_target)



          let interests_array = []


          if(result.hasChild('interests'))
          {
            let temp_interests_array = result.child('interests').val()

            if(temp_interests_array!=undefined && temp_interests_array!=null)
            {
              interests_array = temp_interests_array
            } 
          }
          localStorage.setItem('interests_array',JSON.stringify(interests_array))
        }

      });
    },
    goToAssignments()
    {
      const _this = this

      if(_this.userCategory=='slp')
      {
        alert('You cannot preview this feature as an slt');
      } else {

        if(_this.foundAssignment) {
          let uid = localStorage.getItem('uid');
          let ref_homework = 'Users/' + uid + '/Assignments/homework/'

          updateDataDirectory(ref_homework, {new: false})

          let ref_assessment = 'Users/' + uid + '/Assignments/assessment/'
          updateDataDirectory(ref_assessment, {new:false})
          
          _this.$router.push({ name: 'homework' });
        }
        else {
          alert("We haven't found an exercise plan for this account. \nContact your speech therapist so that they can send you training through BeautifulVoice.")
        }

      }

    },
    checkAssignments()
    {
      const _this = this;

      let homework_address = '/Assignments/homework/'
      let assessment_address = '/Assignments/assessment/'

      getUserDataDir(homework_address, function (err, result) {
        let homework = result.val()
        if (homework) {

          if(homework.hasOwnProperty('new') && homework['new'] == true)
          {
            _this.newAssignment = true
          }
          if (homework.hasOwnProperty('general')) {
            if (Object.keys(homework['general']).length > 0) {
              _this.foundAssignment = true
            }
          }
          if (homework.hasOwnProperty('package')) {
            _this.foundAssignment = true
          }
        }
      });

      getUserDataDir(assessment_address, function (err, result) {
        let assessment = result.val()

        if (assessment) {
          if(assessment.hasOwnProperty('new') && assessment['new'] == true)
          {
            _this.newAssignment = true
          }

          if (assessment.hasOwnProperty('general')) {
            if (Object.keys(assessment['general']).length > 0) {
              _this.foundAssignment = true
            }
          }

          if (assessment.hasOwnProperty('packages')) {
            _this.foundAssignment = true
          }
        }
        
      });
    },
    computeTotalPoints() {
      const _this = this

      let do_once = true

      let week_info = getWeekNumber()

      getUserDataDir('Points',function (err, result) {

        if(do_once) {
          do_once = false

          let total_points = 0

          result.forEach(function(yearSnapshot) {
            yearSnapshot.forEach(function(weekSnaphot){
              weekSnaphot.forEach(function(daySnapshot){

                let points = daySnapshot.val()

                if(points!=undefined && points!=null) {
                  total_points+=points
                }

              });
            });            
          });

          _this.BV_total_points = total_points

          let on_a_streak = true
          let streak_length = 1

          while(on_a_streak)
          {

            week_info = substractDayFromWeekInfo(week_info)

            let points = result.child(week_info[0]).child(week_info[1]).child(week_info[2]).val()

            if(points!=undefined && points!=null) {
              streak_length = streak_length+1
            }
            else {
              on_a_streak = false
            }

            _this.BV_streaks = streak_length
          }
        }
      })
    },
    gotoLoudnessAssessment() {
      this.$router.push({ name: 'loudness_assessment' }); 
    },
    gotoAphasiaAssessment() {
      this.$router.push({ name: "language_assessment" });
    },
    gotoIntelligibility() {
      this.$router.push({ name: "speech_assessment" });
    },   
  },
  
  
}
</script>
<style scoped>

.custom-text{
    font-size: 22px;
}

.animate__animated.animate__bounce {
  --animate-duration: 2s;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}

</style>