<template>
  <div class="row d-flex mb-3" style="min-height: 100vh;">
    <div class="col-xl-10 pe-0 d-flex flex-column">
			<div class="card card-body">
      <div class="row">
        <div>
			<span v-if="use_standard_instructions" class="custom-text" style="font-weight: bold;">Press Start. Read the news below aloud. <br> Try to keep your pace with the highlighted word. No need to match exactly. Press Next when finished.</span>
			<span v-else class="custom-text" style="font-weight: bold;">{{ex_instruction}}</span>
        </div>
        <div class="card card-body">
          <div class="row">
            <p class="read-text">{{pre_text}} <span class="read-text" style="color:#FF0000">{{highlighted_text}} </span> {{post_text}}</p>
          </div>
        </div>
      </div>
      <div class="row mt-3">
				<p class="lead" style="margin-bottom: 0px">Current speed (words per minute) <b style="font-size: x-large;"><span style="color:#409EFF">{{wpm}}</span></b></p>
				<p style="margin-top: 0px">Drag slider to change the speed  </p>

				<div class="col-1">
					<p style="font-size: x-large;">20</p>
				</div>
				<div class="col-10">
					<input type="range" class="form-range" v-model.number="wpm" @change="changeWPM" min="20" max="150" step="10" />
				</div>
				<div class="col-1">
					<p style="font-size: x-large;">150</p>
				</div>
      </div>
      <div class="row justify-content-center" id="meter-row">
        <div class="col-6">
            <canvas id="meter" height="30"></canvas>
        </div>
      </div>
    </div>
  </div>
	<div class="col-xl-2" style="padding-left: 0px">
		<div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
      <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
      <button class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} 
		<i v-bind:class="[play_pause_text==='Start' ? 'bi bi-play-circle' : 'bi bi-arrow-clockwise']" style="margin-left: 5mm;"></i>
		</button>
      <button class="btn btn-info btn-lg w-100 mt-4" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 5mm;"></i></button>
      <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
    </div>
  </div>
</div>
</template>

<script>


import homeplanImg from '@/assets/images/homeplan.png'
import {generate_results_path} from '@/utils/results/data_save_utils'

import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {checkPermissionsMedia, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
import {removeWeirdCharactersFromString,removeQuotesAndSimilarForAnalysis} from '@/media_input/utils'
import {updateDataDirectory } from '@/firebase/index'
import {roundNumber} from '@/utils/utils'
import {logActivity} from '@/media_input/utils'

export default {
	name: 'Pace_training',
	/*

	{
   "Description":"The Australia men’s head coach, Andrew McDonald, has said that all options are on the table when it comes to selection for the fourth Ashes Test in Manchester – but, with nine days’ break after an intense few weeks that began with winning the World Test Championship final, the first priority is giving his team a breather. ",
   "Title":"‘We’ve got a decision to make’: Australia mull changes before fourth Ashes Test",
   "Type":"article",
   "Url":"https://www.theguardian.com/sport/2023/jul/11/weve-got-a-decision-to-make-australia-mull-changes-before-fourth-ashes-test",
   "Urltoimage":"https://i.guim.co.uk/img/media/bd4e07e223e26a6a6efeac6ca9284052483febbd/0_70_3500_2100/master/3500.jpg?width=465&dpr=1&s=none",
   "assist":[
      "spm_live"
   ],
   "exercise_type":"pace_training",
   "instruct":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "name":"‘We’ve got a decision to make’: Australia mull changes before fourth Ashes Test",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"‘We’ve got a decision to make’: Australia mull changes before fourth Ashes Test. \n The Australia men’s head coach, Andrew McDonald, has said that all options are on the table when it comes to selection for the fourth Ashes Test in Manchester – but, with nine days’ break after an intense few weeks that began with winning the World Test Championship final, the first priority is giving his team a breather. ",
   "plan_name":"Pace training",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Pace training/8",
   "ex_path":"Pace training/8",
   "today":"2023-07-11 17:16",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"pace_training",
   "url":"https://www.theguardian.com/sport/2023/jul/11/weve-got-a-decision-to-make-australia-mull-changes-before-fourth-ashes-test",
   "url2image":"https://i.guim.co.uk/img/media/bd4e07e223e26a6a6efeac6ca9284052483febbd/0_70_3500_2100/master/3500.jpg?width=465&dpr=1&s=none",
   "audioIns":null,
   "Ex_Text":"‘We’ve got a decision to make’: Australia mull changes before fourth Ashes Test. \n The Australia men’s head coach, Andrew McDonald, has said that all options are on the table when it comes to selection for the fourth Ashes Test in Manchester – but, with nine days’ break after an intense few weeks that began with winning the World Test Championship final, the first priority is giving his team a breather. ",
   "Ex_Description":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "module_name":"Pace training",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5,
   "wpm":70
}

	*/
	props: {
			exForm: {
			type: Array,
			},
	},
	data() {
		return {
			wpm: 50,
			canvasContext: '',
			dropdown_title: 'Words per minute',
			play_pause_text: 'Start',
			finished_msg: '',
			play_status: false,
			ex_instruction: '',
			use_standard_instructions:false,
			standard_instructions:"Press start. Read the news below aloud, keeping pace with the highlighted word. You don't need to match exactly. Drag the slider if you want to change the speed. Press Next when you have finished.",
			paragraph_text: '',
			pre_text: '',
			post_text: '',
			curr_news_number: -1,
			highlighted_text: '',
			curr_exercise_number: 0,
			number_exercises: 1,
			current_word_number: 0,
			homeplanImg: homeplanImg,
			crossImg: crossImg,
			doneIcon: doneIcon,
			inputAcc:'',
			wordTimeStep: 900,
			avg_loudness: 0,
			avg_loudness_show: 0,
			max_loudness:0,
			last_loudness: 0,
			total_loudness: 0,
			counter_loudness: 0,
			sound_l:'',
			sound_l2:'',
			drawInterval: null,
			canvasContext: '',
			modules_results_path: null,
			first_exercise: false,
			resultsTableBack: [],
			is_recording: false,
			userType: 'patient',
			client_number: null,
			region:'UK',
			uid: '',
		}
	},
	created() {
		let _this = this;
		checkPermissionsMedia();  

		let uid = localStorage.getItem('uid');
		_this.uid = uid;
		console.debug('uid = ' + uid)

		let region = localStorage.getItem('region');
		
		if(region!=undefined && region!=null)
		{
			_this.region = region
		}


		logActivity( 'accessed', 'pace_training')

		_this.userType = localStorage.getItem('userCategory');

		if(this.userType=='slp')
		{
			let client_number = localStorage.getItem('client_uid');

			if(client_number!='undefined' && client_number!=null && client_number!='')
			{
				_this.client_number = client_number; 
			}
		}
		
		let session_wpm = sessionStorage.getItem('wpm')
		if (session_wpm) {
			_this.wpm = session_wpm
		}

		_this.nextNews();		
	},
	beforeUnmount()
	{
		const _this = this

		clearInterval(window.refreshIntervalId)

		clearInterval(window.refreshIntervalId2)

		if(_this.is_recording)
		{
			_this.endRecordingAudio(true);
		}
		else
		{
			_this.endRecordingAudio(false);
		}
	},
	methods: 
	{
			nextExercise()
			{
				const _this = this
				if(this.is_recording)
				{
					this.endRecordingAudio(true)
				}

				if(_this.resultsTableBack.length>0)
				{
					var paceData = {wpm: _this.wpm, resultsTableBack: _this.resultsTableBack}

					_this.$emit('logExerciseResults', paceData);
				}
				
				this.$emit('nextExercise');
			},
			drawLoop(sound_l) {

			const _this = this;

			_this.canvasContext = document.getElementById("meter").getContext("2d");

			var WIDTH = 400;//document.getElementById('meter').clientWidth;
			var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

			var green_threshold = 50;

			// console.debug("Width = " + WIDTH);
			
			// console.debug("HEIGHT = " + HEIGHT);


			if(_this.canvasContext != undefined)
			{
					_this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
			
					if (sound_l > green_threshold) {
					_this.canvasContext.fillStyle = "green";

					// if(_this.silence_flag)
					// {
					//   _this.Repeat_Counter = _this.Repeat_Counter + 1;

					//   _this.silence_flag = false;

					//   if(_this.Repeat_Counter>=1)
					//   {
					//     _this.Repeat_Counter =0;
					//     _this.getNextExercise();
					//   }
					// }

					}
					else {

					// _this.silence_flag = true;

					_this.canvasContext.fillStyle = "red";
					}
					_this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
									// _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


				}

	// draw a bar based on the current volume


			},
			nextNews()
			{
					const _this = this;

					console.debug(_this.is_recording)
					
					if(_this.is_recording)
					{
							// _this.endRecordingAudio(true)
					}


					_this.pause();

					_this.loadSingleExercise()


					// _this.curr_news_number = _this.curr_news_number + 1;
					// _this.firebase_exercise_folder = '/Exercises/NewsUS/entertainment/' + String(_this.curr_news_number) + '/';

					// _this.loadExercises();


			},
			loadSingleExercise()
			{
					const _this = this

					_this.current_word_number = 0;
					_this.ex_instruction = _this.exForm.instruct; 

					if(_this.ex_instruction===_this.standard_instructions)
					{
						_this.use_standard_instructions = true
					}
					_this.paragraph_text = _this.exForm.text;
					_this.pre_text = _this.paragraph_text;

					_this.curr_news_number = _this.exForm.current_exercise_index
					_this.curr_exercise_number = _this.exForm.current_exercise_index + 1
					// _this.first_exercise = (_this.exForm.current_exercise_index==_this.exForm.current_exercise_index)
					_this.first_exercise = !_this.exForm.has_results

					_this.total_exercises = _this.exForm.total_all_type_exercises

					_this.paragraph_text = removeWeirdCharactersFromString(_this.paragraph_text)


					_this.changeWPM()

					_this.goToNextWord()



			},
			startRunning()
			{
					const _this = this;

					_this.goToNextWord();

			},        
			changeWPM()
			{
					const _this = this;
					let word_rate = 1/(_this.wpm/60)*1000;
					console.debug(word_rate)
					_this.wordTimeStep = word_rate;

					if(_this.play_status)
					{

							// _this.startRunning();
							
					}
					sessionStorage.setItem('wpm', _this.wpm)

			},
			runTimer() {
	
					var interval_time = 250;

					const _this = this;

					clearInterval(window.refreshIntervalId2)


					window.refreshIntervalId2 = setInterval(function () {

					if (soundMeter != null) {

							
							let element = document.getElementById("meter")



							{

									// var sound_l = soundMeter.instant.toFixed(2) ;
									var sound_l = soundMeter.db.toFixed(2);
									_this.sound_l2 = soundMeter.db2.toFixed(2);


									// let sound_calibration = 20;



									let sound_threshold = 40;
									

									console.debug('sound_l = ' + sound_l)
									if(sound_l>sound_threshold)
									{
											
											_this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
											
											_this.avg_loudness_show = roundNumber(_this.avg_loudness,0)

											console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)

											_this.max_loudness = Math.max(_this.max_loudness,sound_l)


											_this.counter_loudness += 1;
									}


									_this.canvasContext = element.getContext("2d");

									_this.drawLoop(sound_l);

									
							
							}

					}

					}, interval_time);
			

			},
			drawLoop(sound_l) {
					// clear the background

					const _this = this;


					var WIDTH = 400;//document.getElementById('meter').clientWidth;
					var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

					var green_threshold = 50;

					// console.debug("Width = " + WIDTH);
					
					// console.debug("HEIGHT = " + HEIGHT);

					_this.sound_l = sound_l;



					if(_this.canvasContext != undefined )
					{
							_this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
					
							if (sound_l > green_threshold) {
							_this.canvasContext.fillStyle = "green";

							}
							else {

							// _this.silence_flag = true;

							_this.canvasContext.fillStyle = "red";
							}
							_this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
											// _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


					}

					// draw a bar based on the current volume


			},
			goToResults()
			{
				const _this = this

				var goToResultsData = {curr_ex_done:false}

				if(_this.play_status)
				{
					
					_this.endRecordingAudio(true)

					var paceData = {wpm:_this.wpm, resultsTableBack: _this.resultsTableBack}


					goToResultsData.curr_ex_done = true

					_this.$emit('paceClosed', paceData);
				}

				_this.$emit('goToResults', goToResultsData);

			},
			playPause()
			{
					let _this = this;

					if(_this.play_status)
					{

							_this.endRecordingAudio(false)

							_this.play_status = false;
							_this.play_pause_text = 'Start'

							_this.current_word_number = 0

							_this.goToNextWord()

							_this.pause()   
							
					}
					else
					{
							_this.play_status = true;
							_this.play_pause_text = 'Reset'
							_this.max_loudness = 0


							window.refreshIntervalId = setTimeout(this.goToNextWord,_this.wordTimeStep)

							if(!_this.is_recording)
							{
									_this.resumeRecording()
							}
							_this.runTimer()

					}

			},
			resumeRecording()
			{
					var _this = this;
			
					// resumeRecordingMedia();
					recordAudioMedia()
					_this.is_recording = true;
	
			},
			pauseRecordingAudio()
			{

					var _this = this;
					
					console.debug('pause recoding audio 1')


					if(_this.is_recording)
					{
							console.debug('pause recoding audio 2')

							endRecordingAudio(save_audio)


							_this.is_recording = false;

					}
			
					clearInterval(window.refreshIntervalId2)


			},
			endRecordingAudio(save_audio)
			{
			
					var _this = this;

	
					if(save_audio)
					{

						let results_addresses = generate_results_path(this.exForm)

						let random_identifier = results_addresses['random_identifier']

						let exercises_results_path = results_addresses['exercises_results_path']
						let modules_results_path = results_addresses['modules_results_path']



						let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

						if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
							audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
						}
							
						var audio_address = 'audios/' + audio_folder + '.mp3';

						audio_address = audio_address.replaceAll('//','/')


						console.debug("Saving " + audio_address);

						let ground_truth = _this.exForm.text


						if(ground_truth!=undefined && ground_truth!=null)
						{

							ground_truth = removeQuotesAndSimilarForAnalysis(ground_truth)

						}
						else{
							ground_truth = ''
						}



						
						let today = _this.exForm.today
						
						let voicetype = _this.exForm.voicetype
						let speechtype = _this.exForm.speechtype
					
						console.debug('exercises_results_path = ' + exercises_results_path)
						console.debug('plan_name = ' + _this.exForm.module_name)

						_this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

						updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})
						console.debug('Saving ' + _this.exForm.name)

						let push_structure = {
							'date': today, 
							'name': _this.exForm.name,
							'path': exercises_results_path , 
							'audio_address': audio_address, 
							'uid':_this.uid,
							'loudness': this.avg_loudness_show,
							'localisation':this.region
						}
						
						console.debug(push_structure)

						if(voicetype!=null && voicetype!=undefined)
						{

								if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
								{
										push_structure['sentencegibility'] = ground_truth
								}
								else if('sentencegibility' in voicetype)
								{
										push_structure['sentencegibility'] = ground_truth
								}

								// if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
								if(true)
								{
										push_structure['speech_rate'] = '1'
								}    
								
									if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
								{
										push_structure['jitter_shimmer'] = '1'
								}
								else if('jitter_shimmer' in voicetype)
								{
										push_structure['jitter_shimmer'] = '1'
								}

						}

						if(speechtype!=null && speechtype!=undefined)
						{
								if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
								{
										push_structure['intelligibility'] = ground_truth
								}
								else if('Intelligibility' in speechtype)
								{
										push_structure['intelligibility'] = ground_truth
								}

								if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
								{
										push_structure['sentencegibility'] = ground_truth
								}
								else if('sentencegibility' in speechtype)
								{
										push_structure['sentencegibility'] = ground_truth
								}

								// if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
								if(true)
								{
										push_structure['speech_rate'] = '1'
								}

								if(Array.isArray(speechtype) && speechtype.includes('jitter_shimmer'))
								{
										push_structure['jitter_shimmer'] = '1'
								}
								else if('jitter_shimmer' in speechtype)
								{
										push_structure['jitter_shimmer'] = '1'
								}

						}

						console.debug('push structure = ' + push_structure)

						for(let k in push_structure)
						{
							console.debug('push_structure['+k+'] = ' + push_structure[k])
						}
						
						_this.resultsTableBack.push(exercises_results_path)

						_this.modules_results_path = modules_results_path

						console.debug('path = ' + modules_results_path)
						stopRecordingMedia(audio_address, push_structure, _this);

						// _this.$firebase.database().ref('ProcessRequest').push(push_structure)                

					}
					else
					{
							stopRecordingMedia(null,null,null);
					}

					this.is_recording = false;

			}, 
			pause()
			{
					const _this = this;
					_this.play_status = false;
					_this.play_pause_text = 'Start'

					clearInterval(window.refreshIntervalId)
					clearInterval(window.refreshIntervalId2)

			},
			goToNextWord()
			{
					const _this = this;

					const string_array = this.paragraph_text.split(" ");

					if(string_array.length>1)
					{
						if(string_array[0]=='')
						{
							string_array.splice(0,1)
							
						}
					}


					let numberWords = string_array.length;

					if(_this.current_word_number>=numberWords)
					{
							_this.play_status = false;
							_this.play_pause_text = 'Start';
							_this.current_word_number = 0;

							_this.endRecordingAudio(true)

					}
					else
					{
							_this.pre_text = '';
							_this.post_text = '';
							_this.highlighted_text = '';

							for(let i=0;i<_this.current_word_number;++i)
							{
									_this.pre_text = _this.pre_text + string_array[i] + ' '; 
							}
							_this.highlighted_text = string_array[_this.current_word_number]


							for(let i=(_this.current_word_number+1);i<numberWords;++i)
							{
									_this.post_text = _this.post_text + string_array[i] + ' '; 
							}
							_this.current_word_number = _this.current_word_number + 1;



							if(_this.play_status)
							{
									let n_letters = _this.highlighted_text.length;

									console.debug("wordTimeStep = " + _this.wordTimeStep)

									let time_interval = _this.wordTimeStep*(n_letters/10+0.5);

									window.refreshIntervalId = setTimeout(this.goToNextWord,time_interval)

									// console.debug("time_interval = " + time_interval)
							}



					}

			},
	}
}
</script>

<style scoped>
</style>
<style>
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }

.custom-text{
    font-size: 20px;
}
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 60%;
    margin:auto;
  }

  .demo-input-label {
    display: inline-block;
    width: 130px;
  }
</style>


<!-- Result Description
{
  "Pace training":{
    "<key>": {
      "audio_address": "<storage_audio_address>",
      "loudness": {
        "loudness": "<int>"
      },
      "name": "<exercise_name>", // Back end analysis results (if they are requested):
      "sentencegibility": "<sentencegibility_dict_see_below>", // daily news, pub quiz, jokes, tongue twisters, fun facts, sentence intelligibility and speech rate (Assessments)
      "speechrate_dictionary":"<speech_rate_dict_see_below>", //sentence intelligibility and speech rate (Assessments)
    },
    "assessment_type": "<USER_TRAINING/SLP_TRAINING/SLP_ASSESSMENT>",
    "plan_name": "Pace training"
  }
}

{
  "<sentencegibility_dict>":
  {
    "accuracy_score": "<int>",
    "audio_duration": "<float in sec>",
    "fluency_score": "<int>",
    "reference_text": "<target_text>",

    "Words":[
      // For each word recognised
      {
        "word":"<ground truth word>",
        "accuracy_score": "<int>",
        "error_type": "<string>",
        "phonemes": [
          // For each phoneme in the word
          {
            "accuracy_score":"<int>",
            "phoneme":"<using iso text>"
          }
        ]
      }

    ]

  }
}


{
  "speechrate_dictionary":{
    "ASD(speakingtime by nsyll)": "<float>",
    "articulation rate(nsyll by phonationtime)": "<float>",
    "audio_duration": "<float>",
    "dur(s)": "<float>",
    "npause": "<int>",
    "nsyll": "<int>",
    "phonationtime(s)": "<float>",
    "speechrate(nsyll by dur)": "<float>"
  }
}
 -->