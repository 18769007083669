<template>
  <div class="row d-flex mb-3" style="min-height: 100vh;">
          <div class="col-xl-10 pe-0 d-flex flex-column">
            <div class="card card-body h-100">
  
          <div class="row text-center justify-content-center">
            <span class="title_txt" style="fontSize:26px">{{ex_description}}</span>
            <img :src="audioImg" style="width:55px; cursor: pointer;" @click="bv_tts.say_sentences(ex_description)">
          </div>
          <div class="row">
            <p class="title_txt" style="fontSize:26px;color:blueviolet;white-space: pre-wrap">{{answer_text}}</p>            
          </div>
          <div class="row">
            <p class="title_txt" style="fontSize:26px;color:#228B22;white-space: pre-wrap">{{found_words_text}}</p>            
          </div>

          <div class="row justify-content" id="meter-row">
            <div class="col-6">
                <canvas id="meter" height="30"></canvas>
            </div>
          </div>

          <div class="row">
            <!-- <span >{{currentSentence_found}}</span> -->
            <span hidden>{{currentSentence_found}}</span>
            <!-- DO NOT COMMENT THIS LINE -->
          </div>

          <div class="row justify-content-center">
            <!-- <div class="col-md-6">
              <div class="card card-body custom-card_pink" style="display:flex;position;relative;align-items:center;justify-content:center;"> 
                  <span style="fontSize:20px;">{{hint_word}}</span>
              </div>
            </div> -->
            <div class="col-md-3">
                <button class="btn btn-secondary btn-lg w-100" @click="get_hint()">{{ hint_btn_text }} <i class="bi bi-eye"></i></button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-bd-yellow btn-lg w-100" @click="showAnswer()" >Show answer <i class="bi bi-file-earmark-check"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2" style="padding-left: 0px">
        <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <button v-if="play_pause_text==='Record'" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()"> <strong>{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i> </strong></button>
          <button v-else class="btn btn-primary btn-lg w-100 mt-2" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
          </button>
          <button class="btn btn-info btn-lg w-100 mt-4" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
        </div>
      </div>
  </div>
</template>

<script>

import homeplanImg from '@/assets/images/homeplan.png'
import {checkPermissionsMedia } from '@/media_input/microphone_recording'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import SpeechRec from '@/media_input/SpeechRecognition'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import audioImg from '@/assets/images/audio_logo.png'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'

/*


{
   "difficulty":"easy",
   "exercise_type":"word_finding",
   "instruct":"Press Record. Count from 1 to 20.",
   "name":"1;2;3;4;5;6;7;8;9;10;11;12;13;14;15;16;17;18;19;20;one;two;three;four;five;six;seven;eight;nine;ten;eleven;twelve;thirteen;fourteen;fifteen;sixteen;seventeen;eighteen;nineteen;twenty",
   "topic":"Counting",
   "plan_name":"Auto Speech",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Auto Speech/Counting",
   "ex_path":"Auto Speech/Counting",
   "today":"2023-07-11 17:17",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"word_finding",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Press Record. Count from 1 to 20.",
   "module_name":"Auto Speech",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":3
}


{
   "difficulty":"easy",
   "exercise_type":"word_finding",
   "instruct":"Press Record. Name as many vegetables as you can.",
   "keywords":[
      "Lettuce",
      "Radish",
      "Potato",
      "Spinach",
      "Tomato",
      "Cucumber",
      "Pepper",
      "Parsley"
   ],
   "name":"Artichoke;Arugula;Asparagus;Avocado;Bamboo Shoots;Bean;Bean Sprouts;Beet;Beetroot;Belgian Endive;Bell Pepper;Broccoli;Brussels Sprouts;Cabbage;Calabash;Capers;Carrot;Cassava;Yuca;Cauliflower;Celery;Celtuce;Chayote;Corn;Maize;Cucumber;English Cucumber;Gherkin;Pickling Cucumbers;Daikon Radish;Edamame;Eggplant;Aubergine;Garlic;Endive;Escarole;Fennel;Fiddlehead;Galangal;Garlic;Ginger;Grape Leaves;Green Beans;String Beans;Snap Beans;Wax Beans;Greens;Beet Greens;Collard Greens;Dandelion Greens;Kale;Kohlrabi Greens;Mustard Greens;Rapini;Spinach;Swiss Chard;Turnip Greens;Hearts of Palm;Horseradish;Jerusalem Artichoke;Sunchokes;Jícama;Kale;Curly;Ornamental;Kohlrabi;Leeks;Lemongrass;Lettuce;Iceberg;Romaine;Lotus Root;Lotus Seed;Mushrooms;Napa Cabbage;Nopales;Okra;Olive;Onion;Green Onions;Scallions;Parsley;Parsnip;Peas;green peas;snow peas;sugar snap peas;Peppers;Plantain;Potato;Pumpkin;Purslane;Radicchio;Radish;Rutabaga;Shallots;Spinach;Sweet Potato;Swiss Chard;Taro;Tomatillo;Tomato;Turnip;Water Chestnut;Water Spinach;Watercress;Winter Melon;Yams;Zucchini;chinese leaf",
   "topic":"Vegetables",
   "plan_name":"module_name",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"module_name/Vegetables",
   "ex_path":"module_name/Vegetables",
   "today":"2023-07-11 17:17",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"word_finding",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Press Record. Name as many vegetables as you can.",
   "module_name":"module_name",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}
*/
export default {
    name: 'Word_finding',
    props: {
      exForm: {
        type: Object,
      },
    },
    data() {
      return {
        number_hints:0,
        audioImg:audioImg,
        currentSentence: '',
        found_words_text:'',
        region:'UK',
        words_found: [],
        found_target_word: false,
        play_status: false,
        clicked_start: false,
        play_pause_text: 'Record',
        speech_rec: null,
        answer_text: '',
        img_address: '',
        keywords: [],
        hint_word: '*****',
        hint_index: 0,
        hint_level: 0,
        answer_words: [],
        target_word: '',
        target_img_number: '',
        instructions_msg: '',
        homeplanImg: homeplanImg,
        ex_description: '',
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Record',
        curr_exercise_number: 0,
        number_exercises: 1,
        avg_loudness: 0,
        avg_loudness_show: 0,
        last_loudness: 0,
        total_loudness: 0,
        counter_loudness: 0,
        sound_l:'',
        sound_l2:'',
        drawInterval: null,
        canvasContext: '',
        uid: '',
        userType: '',
        resultsTable: [],
        listener_ref: '',
        retest_count: null,
        is_recording: false,
        hint_btn_text: 'Get hint',
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points()
      }        
    },
    created() {
      const _this = this
      console.debug('created word finding')

      checkPermissionsMedia();


      let region = localStorage.getItem('region');
        
      if(region!=undefined && region!=null)
      {
        _this.region = region
      }


      _this.userType = localStorage.getItem('userCategory');

      if(this.userType=='slp')
      {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='')
        {
            _this.client_number = client_number; 
        }
      }

      logActivity( 'accessed', 'word_finding')

      // var user = _this.$firebase.auth().currentUser;

      // let uid = user.uid;

      let uid = localStorage.getItem('uid');
      _this.uid = uid;   

      this.speech_rec = new SpeechRec(function(captions) {
          this.currentSentence = captions.original
      }.bind(this))

      this.loadExercises();
    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()

      this.speech_rec.stop()
    },
    computed: {

      currentSentence_found: function () {

        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
        {

          let curr_sentence = this.currentSentence.toString().toLowerCase()

          var regex = /[.,]/g;

          curr_sentence = curr_sentence.replace(regex, '');

          
          console.debug('this.currentSentence  = ' + this.currentSentence)

          let words = curr_sentence.split(' ')

  
          for(let k in curr_sentence)
          {
            let character = curr_sentence[k]
            if(character>='0' && character<='9')
            {
              words.push(character)
            }
          }


          let words_2 = []

          for(let i in words)
          {
            // creating words without the plural
            let curr_word = words[i]
            if(curr_word[curr_word.length-1]==='s')
            {

              let new_word = curr_word.substring(0,curr_word.length-1)

              words_2.push(new_word)

            }
            words_2.push(curr_word)
          }

          words = words_2

          for(let i in words)
          {
            let found_something = false

            let minimum_distance = 100
            let best_answer = null

            let word = words[i]


            for(let k in this.answer_words)
            {

              let distance = LevenshteinDistance(this.answer_words[k].toLowerCase(),word)


              distance = distance/Math.max(this.answer_words[k].length+1,1)

              // console.debug('this.answer_words[k] = ' + this.answer_words[k])

              if(distance<minimum_distance && distance<0.25)
              {
                best_answer = this.answer_words[k]
                found_something = true
                minimum_distance = distance
              }
            }

            if(best_answer!=null)
            {
              const index = this.answer_words.indexOf(best_answer);
              // if (index >= 0) { 
              //   this.answer_words.splice(index, 1); // 2nd parameter means remove one item only
              // }

              

              const hint_2_remove = this.keywords.indexOf(best_answer);
              if (hint_2_remove >= 0) { 

                if(this.hint_index==hint_2_remove)
                {
                  this.hint_level = 0
                  this.answer_text = 'It is recording now, say the words from this category'

                }
                else if (this.hint_index>hint_2_remove)
                {
                  this.hint_index = this.hint_index-1

                }
                this.keywords.splice(hint_2_remove, 1); // 2nd parameter means remove one item only
              }

              console.debug('Found = ' + best_answer)

              this.found_correct_word(best_answer) 
            }

            if(!found_something)
            {
              this.found_target_word = 'false'
            }
          }                
          return curr_sentence
        }
        return ''
      },
    },
    methods: 
    {
      nextExercise()
      {
        this.saveResults()
        this.$emit('nextExercise');
      },
      saveResults()
      {
        const _this = this

        if(this.clicked_start)
        {
          let topic = _this.exForm.topic
          
          let all_results = {Number_Words: _this.words_found.length,Number_Hints:_this.number_hints, Topic:topic}

          let plot_results = {Number_Words: _this.words_found.length, Number_Hints:_this.number_hints}
      
          let results = {all_results:all_results, plot_results:plot_results}

          var word_finding_data = {resultsTablePre: {exercise_type: 'Word Finding', exercise: 'Word Finding', results: results, audio_address:''}}


          console.debug('here!!!')
          save_pre_computed_results(_this.exForm,_this.$firebase,word_finding_data)

          _this.bv_points.add(20)

          _this.$emit('logExerciseResults', word_finding_data);


        }

      },
      loadExercises()
      {
        var _this = this;
        _this.setup();
      },
      get_hint()
      {
        const _this = this

        if(_this.hint_level==0)
        {
          _this.number_hints = _this.number_hints + 1
          _this.hint_level = 1

          console.debug('_this.keywords = ' + _this.keywords)

          console.debug('_this.hint_index = ' + _this.hint_index)

          _this.hint_word = _this.keywords[_this.hint_index]

          console.debug('hint_word = ' + this.hint_word)

          let n_letters = Math.ceil(_this.hint_word.length/2)
          _this.hint_word = _this.hint_word.substring(0,n_letters)

          for(let i=0;i<(_this.keywords[_this.hint_index].length-n_letters);++i)
          {
              _this.hint_word = _this.hint_word + ' * '

          }
          _this.hint_btn_text = 'More hints'
        }
        else
        {
          _this.hint_level = 0
          _this.hint_word = _this.keywords[_this.hint_index]

          _this.hint_index = _this.hint_index + 1
          if(_this.hint_index>=_this.keywords.length)
          {
              _this.hint_index = 0
          }
          _this.hint_btn_text = 'Get hint'
        }
        _this.answer_text = _this.hint_word
      },
      revealLetter()
      {
        const _this = this
        _this.revealed_letters = _this.revealed_letters+1

        _this.hint_antonym = _this.selected_antonym.substring(0,_this.revealed_letters)

        _this.correct_streak = _this.correct_streak -0.5

        for(let i=0;i<_this.selected_antonym.length-_this.revealed_letters;++i)
        {
          _this.hint_antonym = _this.hint_antonym + ' * '
        }
      },
      setup()
      {
        let _this = this;

        _this.answer_text = '';

        _this.target_word = _this.exForm.name

        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises

        _this.ex_description = _this.exForm.Ex_Description
        _this.retest_count = null

        if(_this.exForm.topic==undefined)
        {
          _this.exForm.topic = 'No topic'
        }

        console.debug('_this.exForm.topic = ' + _this.exForm.topic)

        _this.answer_words = _this.target_word.split(';');

        _this.keywords = _this.exForm.keywords


        if(_this.keywords==undefined || _this.keywords==null || _this.keywords.length<1)
        {
          _this.keywords = _this.answer_words
        }
      },
      playPause()
      {
        let _this = this;
        _this.play_status = true;
        _this.clicked_start = true
        _this.resumeRecording()
      },
      resumeRecording()
      {
        let _this = this;
        _this.is_recording = true;
        _this.start_recording()
      },
      showAnswer()
      {
        let _this = this;

        let show_answer_word_list = []


        if(_this.keywords==undefined || _this.keywords==null || _this.keywords.length<5)
        {
          let answerNum = 10;
          for(let n=0;n<answerNum;n++)
          {
            let randNum = Math.floor(Math.random() * this.answer_words.length); 

            if(randNum<_this.answer_words.length)
            {
              let word_already_found = false
              for(let k in _this.words_found)
              {
                if(this.words_found[k].toLowerCase()===this.answer_words[randNum].toLowerCase())
                {
                  word_already_found = true
                  break

                }

              }
              if(!word_already_found)
              {
                show_answer_word_list.push(' '+_this.answer_words[randNum]);

              }
            }
          }
        }
        else
        {
          let answerNum = 10;

          if(_this.keywords.length<=answerNum)
          {
              answerNum = _this.keywords.length
          }

          for(let n=0;n<answerNum;n++)
          {

            let word_already_found = false

            for(let k in _this.words_found)
            {
              if(this.words_found[k].toLowerCase()===this.keywords[n].toLowerCase())
              {
                word_already_found = true
                break

              }

            }
            if(!word_already_found)
            {
              show_answer_word_list.push(' '+_this.keywords[n]);

            }

          }
        }

        _this.answer_text = show_answer_word_list.length+' answer examples are:'+show_answer_word_list;
      },
      start_recording() {
        const _this = this
        this.speech_rec.start(this.answer_words,false,this.region)
        this.started = true
        this.play_pause_text = 'Recording'
        console.debug('started')

        window.countDownTimer2 = setTimeout(() => {
          _this.answer_text = 'It is recording now, say the words from this category'
          _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        }, 500)
      
        window.countDownTimer = setTimeout(() => {

          if(_this.play_status)
          {
            if(_this.words_found.length<1)
            {
              _this.started = false
              _this.play_pause_text = 'Record'

              _this.answer_text = "Oops we missed what you said! Press Record and try again"
              _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
            }
            else
            {
              _this.started = false
              _this.play_pause_text = 'Record'
              _this.answer_text = "Good job! We managed to pick up about " + _this.words_found.length + " words said!"
              _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
            }
          }
          _this.stop_recording()
        }, 120000)
      },
      stop_recording() {
        const _this = this
        _this.play_status = false;
        _this.currentSentence = ''
        _this.speech_rec.stop()
      },
      found_correct_word(curr_sentence)
      {
        const _this = this

        if(_this.words_found.length<1)
        {
          _this.found_words_text = 'You have said: '
        }

        if(!(_this.words_found.includes(curr_sentence)))
        {
          if(_this.words_found.length>=1)
          {
              _this.found_words_text += ' - '
          }
          _this.words_found.push(curr_sentence)

          _this.found_words_text += curr_sentence
        }
      },
      pause()
      {
          let _this = this;
          _this.play_status = false;
          _this.play_pause_text = 'Record'
          clearInterval(window.refreshIntervalId)
      },
    }
}
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both
    }

    
  .inline-block-child {
    display: inline-block;
  }

.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}


  .box-card {
    width: 60%;
    margin:auto;
  }
  .demo-input-label {
    display: inline-block;
    width: 130px;
  }
</style>

<!-- Results Description
{
  "Word Finding": {
    "<key>": { 
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "exercise": "Word Finding",
        "exercise_type": "Word Finding",
        "results": {
          "all_results": {
            "Number_Hints": "<int>",
            "Number_Words": "<int>",
            "Topic": "<string>"
          },
          "plot_results": {
            "Number_Words": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Word Finding"
  }
} -->