<template>
  <div>
    <div class="row d-flex mb-3" style="min-height: 100vh;">
        <div class="col-xl-10 pe-0 d-flex flex-column">

        <div class="card card-body h-100">

          <div class="row">
            <p class="text-30">
              {{ instructions }}
              <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(instructions)">

            </p>

            <!-- <p class="fs-4" :style="answer_text_color">{{answer_text}}</p> -->
          </div>
          <div class="row">
            
            <div class="col-md-4">

              <div class="card card-body h-100">
                
                <!-- <div class="row mt-2  mb-2 justify-content-center">
                  <button class="btn btn-primary btn-lg mt-2 w-100" @click="sayInstructions()" ><i class="bi bi-play" style="font-size: 1.5rem"></i> Hear word</button>
                </div> -->

                <div class="row justify-content-center">
                  <!-- <div class="card card-body"> -->

                    <div class="row">
                    <img id="img_naming" class="img-thumbnail" v-bind:src="hangmanImg"  width="150" height="150" style="object-fit: cover"> 
                  <!-- </div> -->

                    <p v-if="!showHints" class="lead" style="font-size: x-large;">
                      Need help? 
                    </p>  


                    <p  class="lead" style="font-size: x-large;">
                      <button  class="btn btn-secondary btn-lg mt-2 w-80" @click="reveal_letter()" >Reveal next letter <i class="bi bi-eye"></i></button>

                    </p>  
                    
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-8">
                <div class="card card-body h-100">

                  
                <p v class="lead" style="font-size: x-large;">
                        The word is: {{hints[0]}}
                        <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(hints[0])"><br>
                    </p>  
                  <div v-show="true">
                  
                  
                    <div class="row mt-2  mb-2 justify-content-center">
                      <button v-for="letter, index in word_array_missing_letter" type="text" class="display-button" >
                        {{word_array_missing_letter[index]}}
                      </button>
                    </div>

                    <!-- <p class="text-30">
                      {{ instructions_msg }}
                    </p> -->


                    <div class="row justify-content-center" style="margin-top: 12mm;">
                      <button v-for="letter, index in option_buttons" type="text" @click=select_letter(letter,index) :class="option_buttons_class[index]" >
                        {{option_buttons[index]}}
                      </button>
                    </div>
                  </div>

                 

                <div class="row justify-content-center" v-if="full_sound_hints || (cue_type==='Audio' && showImage)">
                        <button class="btn btn-bd-yellow btn-lg mt-4 w-50" style="width: 95%;" @click="showAnswer()" >Show answer <i class="bi bi-file-earmark-check" style="margin-left: 3mm;"></i></button>
                    </div>

              
                
                    
                </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="modal fade" id="level_up_pop" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Level up</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="m-3">
                <div class="row">
                  <h4 >{{ level_up_message }}</h4>
                </div>
                <div class="row mt-2">
                  <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2" style="padding-left: 0px">
        <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <button class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i> </button>
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>



import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import words from '@/media_input/words'
import constants from '@/utils/constants'
import { Modal } from 'bootstrap'


import hangman_1 from '@/assets/images/hangman_images/Pic_1.png'
import hangman_2 from '@/assets/images/hangman_images/Pic_2.png'
import hangman_3 from '@/assets/images/hangman_images/Pic_3.png'
import hangman_4 from '@/assets/images/hangman_images/Pic_4.png'
import hangman_5 from '@/assets/images/hangman_images/Pic_5.png'
import hangman_6 from '@/assets/images/hangman_images/Pic_6.png'
import hangman_7 from '@/assets/images/hangman_images/Pic_7.png'
import hangman_8 from '@/assets/images/hangman_images/Pic_8.png'



import {getData,getUserDataDir,updateDataDirectory} from '@/firebase/index'

import {logActivity} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'

/*

{
   "Address":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "category":"vegetables",
   "difficulty":"easy",
   "exercise_type":"name_a_picture_easy",
   "hints":[
      "This is a vegetable",
      "This vegetable has layers",
      "This vegetable grows in the ground"
   ],
   "instruct":"Press Record. Say the word that matches the picture below",
   "name":"onion;onions;red_onion;white_onion",
   "word_type":"noun",
   "wrong_words":"tomato;sweetcorn;pepper",
   "plan_name":"Picture naming",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Picture naming/16",
   "ex_path":"Picture naming/16",
   "today":"2023-07-11 17:20",
   "audioIns1":null,
   "imageAddress":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"name_a_picture_easy",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Press Record. Say the word that matches the picture below",
   "module_name":"Picture naming",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
export default {
    name: 'hangman',
    props: {
      exForm: {
        type: Object,
      },
    },
    data() {
      return {
        n_errors:0,
        hangmanImg:hangman_1,
        hangman_img_array:[hangman_1,hangman_2,hangman_3,hangman_4,hangman_5,hangman_6,hangman_7,hangman_8],
        cue_type:'Image',
        showImage:false,
        half_sound_hints:false,
        full_sound_hints:false,
        hovering_letter:false,
        hover_sound_on:true,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        answer_text: '',
        content_data:null,
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showHints:false,
        answer_options:['Saucepan','Fridge'],
        type_your_answer_text:'Write the word in the box below',
        placeholder_text: ' _ _ _ _ _ _',
        written_answer: '',
        first_written_answer:'',
        level_up_message:'',
        total_exercises:1,
        level_properties:{
         1: {'level':1,'word_difficulty':'Easy'},
         2: {'level':2,'word_difficulty':'Medium'},
         3: {'level':3,'word_difficulty':'Hard'}  
        },
        task_type:'write',
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        first_exercise:true,
        current_level:2,
        level_up_counter:0,
        level_up_target:3,
        curr_level_properties:null,
        word_array_missing_letter:[],
        word_array:[],
        missing_letters:[],
        missing_letter_indexes:[],
        n_missing_letters:2,
        option_buttons:[],
        option_buttons_class:[],
        target_word: '',
        base_level_address:'/Levels/Hangman',
        level_address:'',
        single_target_word: '',
        audio_player: null,
        phoneme_single_target_word: '',
        target_img_number: '',
        instructions:'Use the alphabet to guess the hidden word',
        inputAcc:'',
        play_pause_text: 'Start',
        curr_exercise_number: 0,
        number_exercises:1,
        use_reveal_letter:0,
        started: false,
        target_words: [],
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: '',
        userType: '',
        resultsTable: [],
        half_word_url: null,
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this
      _this.userType = localStorage.getItem('userCategory');

      this.bv_tts.say('')

      if('setting' in this.exForm && this.exForm.setting==='Audio')
      {
        this.cue_type = 'Audio'
      }

      if(this.userType=='slp')
      {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='')
        {
          _this.client_number = client_number; 
        }
      }

      let uid = localStorage.getItem('uid');
      _this.uid = uid;   

      logActivity( 'accessed', 'hangman')

      this.level_address = this.level_address

      this.getUserLevel()


    },
    mounted()
    {

    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()

    },
    methods: 
    {
      reveal_letter()
      {

        let first_letter_index = -1

        for(let k in this.word_array_missing_letter)
        {
          if(this.word_array_missing_letter[k]=='?')
          {
            first_letter_index = k
            break
          }
        }

        if(first_letter_index>=0)
        {
          let first_mising_letter = this.missing_letters[first_letter_index]

          let index = 0

          for(let k in this.option_buttons)
          {
            if(this.option_buttons[k]==first_mising_letter)
            {
              index = k

            }
          }

          this.use_reveal_letter = this.use_reveal_letter+1

          this.select_letter(first_mising_letter,index)

        }

        


      },
      getUserLevel()
      { 
        const _this = this

        let do_once = true

        let userType = localStorage.getItem('userCategory');


        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address


        if(userType!=undefined || userType==='slp')
        {
            let client_number = localStorage.getItem('client_uid');

            if(client_number!='undefined' && client_number!=null && client_number!='')
            {

                this.level_address = '/Users/'+ client_number + this.base_level_address

            }
        }


        getData(_this.level_address, function (err, result) {

          if(do_once)
          {
            do_once = false
            
            let current_level = result.child('current_level').val()
            let lvl_up_counter = result.child('level_up_counter').val()


            if(current_level==undefined || lvl_up_counter==undefined)
            {
              current_level = 1
              lvl_up_counter = 0
              updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
            }

            _this.level_up_counter = lvl_up_counter

            if(current_level<1 || current_level>Object.keys(_this.level_properties).length)
            {
              current_level = 1
              
            }
     
            _this.current_level = current_level

            console.debug('current_level = ' + current_level)

            _this.curr_level_properties = _this.level_properties[_this.current_level]

          


            _this.getContentData()
          }
        })


      },
      updateLevel(correct)
      {

        if(correct)
        {
          this.level_up_counter++
          if(this.level_up_counter == this.level_up_target)
          {
              this.level_up_counter = 0
              this.current_level++

              if(this.current_level>Object.keys(this.level_properties).length)
              {
                this.current_level = Object.keys(this.level_properties).length
              }
              else
              {
                this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
                let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                level_up_pop.show()

                updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

                return true
              }

          }
        }
        else
        {
          this.level_up_counter--

          if(this.level_up_counter<0)
          {
            this.level_up_counter = 0
          }
        }

        updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

        return false
      },

      saveResults()
      {
        const _this = this

        if(this.results['correct']!=0 || _this.results['total']!=0)
        {
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1, Show_Answer:_this.showedAnswer, showHints: this.showHints, word:_this.single_target_word,word_difficulty:_this.curr_level_properties.word_difficulty,use_reveal_letter:this.use_reveal_letter}

          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}

          let results = {all_results:all_results, plot_results:plot_results}

          var hangman_data = {resultsTablePre: {exercise_type: 'Hangman', exercise: 'Hangman', results: results, audio_address:'',word:_this.single_target_word,word_difficulty:_this.curr_level_properties.word_difficulty}}


          save_pre_computed_results(_this.exForm, _this.$firebase, hangman_data)

          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', hangman_data);
        }
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      mouseOverButton(letter)
      {

        if(this.hover_sound_on)
        {
          this.bv_tts.say(letter)

          this.hover_sound_on = false

          setTimeout(() => {
            this.hover_sound_on = true
            }, 900);



        }

      },
      listenFullWord()
      {
        const _this = this

        if(_this.cue_type==='Image')
        {
          _this.full_sound_hints = true

        }


        _this.bv_tts.say(_this.single_target_word)

      },
      listenBeginningSound()
      {
        const _this = this
        _this.half_sound_hints = true

        if(_this.half_word_url!=null)
        {
          _this.playSound(_this.half_word_url)
        }
        else
        {
          _this.listenPhoneme()
        }
      },
      listenPhoneme()
      {
        const _this = this
        let phoneme = _this.phoneme_single_target_word[1]

        let simple_representation_phoneme = ''
        for(let i in constants.phonemeDisplayDictionaryIPA)
        {
          if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
          {
            simple_representation_phoneme = i
            break
          }
        }
        if(simple_representation_phoneme!='')
        {
          let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'
          // let audio_file_location = 'audios/phoneme_pronunciation_new/'+phoneme+'.mp4'
          console.debug(audio_file_location)
          this.playAudio(audio_file_location)
        }
      },
      get_half_word_url(word)
      {
        let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'

        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.half_word_url = url
          console.debug('Found half word url!!')
        })
        .catch((error) => {
          console.debug('error ' + error )
        });
      },
      playAudio(audio_file_location)
      {
        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.playSound(url);
        })
        .catch((error) => {
          console.debug('error ' + error )
        });      
      },
      playSound(url) {
        const _this = this
        if(_this.audio_player==null)
        {
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
        else
        {
          if(!_this.audio_player.paused)
          {
            _this.audio_player.pause();
          }
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
      },
      getContentData()
      {
        let _this = this;
        let do_once = true

        let resources_address = '/Exercises/Resources/Hangman/'

        let difficulty = this.curr_level_properties.word_difficulty

        resources_address = resources_address+difficulty

        getData(resources_address, function (err, result) {

          if(do_once)
          {
            do_once = false

            let resources_data = result.val()



            _this.content_data = resources_data[Math.floor(Math.random() * resources_data.length)]


            _this.setup()
          }
        })

      },
      setup()
      {
        const _this = this

        _this.answer_text = '';

        _this.first_exercise = !_this.exForm.has_results

        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises
        _this.retest_count = null
        _this.target_word = _this.content_data.word

        _this.target_word = _this.target_word.toLowerCase()
        _this.single_target_word = _this.target_word

  

       
        this.sayInstructions()
        


        _this.prepare_target_word()

  

        _this.get_half_word_url(_this.single_target_word)

        _this.phoneme_single_target_word = words.word_2_phoneme[_this.single_target_word]

        if(_this.phoneme_single_target_word==undefined || _this.phoneme_single_target_word==null)
        {
            _this.phoneme_single_target_word = ''
        }

        _this.hints = [_this.content_data.hint]

      },
      prepare_target_word()
      {

        const _this = this
        _this.word_array = []
        _this.word_array_missing_letter = []

        for(let k in _this.single_target_word){
          _this.word_array.push(_this.single_target_word[k])
          _this.word_array_missing_letter.push('?')
        }

        this.missing_letter_indexes = []

        this.missing_letters = []

        for(let k in _this.word_array)
        {
            this.missing_letter_indexes.push(k)
            this.missing_letters.push(this.word_array[k])
        }

        this.n_missing_letters = this.missing_letter_indexes.length


        this.create_option_buttons()


        
      },
      select_letter(letter,index)
      {
        let correct_letter = false

        console.log(this.missing_letters)
    
        for(let k in this.missing_letters)
        {
            if(this.missing_letters[k]===letter)
            {
                correct_letter = true
                this.word_array_missing_letter[this.missing_letter_indexes[k]] = letter

                this.n_missing_letters = this.n_missing_letters-1



            }
        }
        this.$forceUpdate()

        if(!correct_letter)
        {
            this.incorrect_answer()


            this.option_buttons_class[index] = 'keyboard-button-incorrect'

        }
        else
        {
            this.option_buttons_class[index] = 'keyboard-button-correct'

            let audio_text_options = ['Yes',"Getting there",'Good guess','Nice','hooray']

            let audio_text = audio_text_options[Math.floor(Math.random()*audio_text_options.length)]
            // this.bv_tts.say(audio_text)
        }

    


        if(this.n_missing_letters==0)
        {

          this.correct_answer(this.single_target_word)

        }
        

      },
      sayInstructions()
      {
        // if(this.cue_type==='Audio')
        // {
        //   let text = 'Write the word ' + this.single_target_word

        //   this.bv_tts.say(text)
        // }
        // else
        // {
        //   let text = 'Name the picture'

        //   this.bv_tts.say(text)

        // }

        this.bv_tts.say(this.instructions)

      },
      correct_answer(word)
      {
        const _this = this


        if(this.results['correct']==0)
        {

     

          startConfetti(this)



          _this.answer_text = 'Congratulations! You wrote the right word: ' + word

          let audio_text_options = ['Correct',"That's right",'Good job']

          let audio_text = audio_text_options[Math.floor(Math.random()*audio_text_options.length)]

          _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'

          this.bv_tts.say(audio_text)

          if(this.results['total']==0)
          {
            let levelled_up = this.updateLevel(true)
          
            _this.results['total'] = 1

            _this.results['correct'] = 1
            _this.results['incorrect'] = 0


            if(levelled_up==false)
            {
                
              setTimeout(() => {
                this.nextExercise()
              }, 2000);

            }


          

          }
        }

      },
      incorrect_answer()
      {
        const _this = this
        this.n_errors = this.n_errors+1
        console.log('n_err = '+this.n_errors+' img = '+this.hangman_img_array.length)
        if(this.n_errors<this.hangman_img_array.length-1)
        {
          this.hangmanImg = this.hangman_img_array[this.n_errors]
          this.$forceUpdate

          _this.answer_text = 'Not quite right, try again!';



            _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'



            // _this.bv_tts.say(_this.answer_text)



        }
        else
        {
          this.hangmanImg = this.hangman_img_array[this.hangman_img_array.length-1]
          this.$forceUpdate

          console.debug(this.results['incorrect'])
          console.debug(this.results['total'])

          if(this.results['incorrect']==0 && this.results['total']==0)
          {
            this.results['incorrect'] = 1
            this.results['total'] = 1

            this.updateLevel(false)


            this.hangmanImg = this.hangman_img_array[this.n_errors]
            _this.bv_tts.say("Oops. You didn't guess it right. The word is "+this.single_target_word)
            setTimeout(() => {
              this.word_array_missing_letter = this.single_target_word
              this.$forceUpdate
            }, 1500);
          


          }


        }



        


      },
      create_option_buttons()
      {
        const _this = this
        let temp_option_buttons = []

        const characters ='abcdefghijklmnopqrstuvwxyz';

        for(let n in characters)
        {
            this.option_buttons.push(characters[n])

            this.option_buttons_class.push('keyboard-button')

        }

        console.debug('temp_option_buttons.length = ' + temp_option_buttons.length)



        // this.shuffle_option_letters(temp_option_buttons)




        this.$forceUpdate

      },
      shuffle_option_letters(temp_option_buttons)
      {

        this.n_option_letters = temp_option_buttons.length

        let all_indexes = []


        let reshuffled_option_letters = []


        for(let n in temp_option_buttons)
        {
          all_indexes.push(n)
          reshuffled_option_letters.push('0')

        }

        this.option_buttons_class = []


        for(let n in temp_option_buttons)
        {
          let rand_index = Math.floor(Math.random()*all_indexes.length)

          reshuffled_option_letters[all_indexes[rand_index]] = temp_option_buttons[n]

          all_indexes.splice(rand_index,1)

          this.option_buttons_class.push('keyboard-button')



        }


        this.option_buttons = reshuffled_option_letters

        this.$forceUpdate


      },
      found_correct_word(curr_answer)
      {
        const _this = this


        _this.best_answer = curr_answer

        _this.answer_text = 'Congratulations! You wrote the right word: ' + curr_answer
        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
        _this.play_pause_text = 'Start'

        if(_this.results['total']==1)
        {
          _this.results['total'] = 1

          _this.results['correct'] = 1
          _this.results['incorrect'] = 0
          


        }

      },
      answer_check(type)
      {
        const _this = this

        let found_any_answer = false

        for(let k in _this.target_words)
        {
          let curr_target_word = _this.target_words[k]

          if(_this.written_answer.toLowerCase()==curr_target_word.toLowerCase())
          {

            found_any_answer = true
            break
          }
        }

    

        if(found_any_answer)
        {
          if(this.first_written_answer==='')
          {
            this.first_written_answer = this.written_answer
          }
          this.correct_answer(_this.written_answer)

        }
        else if(type=='manual')
        {
          if(this.first_written_answer==='')
          {
            this.first_written_answer = this.written_answer
          }

          this.incorrect_answer()
          
        }
      },
      showAnswer()
      {
        let _this = this;
        _this.answer_text = 'The word is '+_this.single_target_word;
        _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'
        this.bv_tts.say(_this.answer_text)


        if(_this.results['correct'] == 0)
        {
          _this.showedAnswer = true
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          _this.results['total'] = 1
        }
      },
    }
}
</script>

<style scoped>
.display-button
{
  width: 20mm;
  height: 20mm;
  font-size: xxx-large;
  margin-left: 1mm;
  background-color: white;
  border-color: blueviolet;
}

.keyboard-button
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  background-color: white;
  border-color: blue;

}

.keyboard-button-correct
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  color: white;
  background-color: green;
  border-color: green;

}

.keyboard-button-incorrect
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  color: white;
  background-color: red;
  border-color: red;

}

.keyboard-button:hover
{
  background-color:lightblue;
  transition: 0.7s;

}



.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>



<!-- Results Descriptions
{
  "Name a picture": {
    "<key>": { 
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming",
        "exercise_type": "Word Naming",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed"

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture"
  }
} -->


<!--
{
  "Name a picture": { --decide the module name ID (this or 'Word Naming')
    "<key>": { --move under 'exercises'
      "audio_address": "<empty>", --not used remove
      "resultsTablePre": {
        "audio_address": "<empty>", --not used remove
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming", --change to 'Name of picture'
        "exercise_type": "Word Naming", --change to 'Name a picture'
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed" --not used remove

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture" --not used remove
  }
} -->