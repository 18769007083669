<template>
    <div>
      <speech_table :speech_table="results['speechTable']" v-if="results['speechTable'] && results['speechTable'].length > 0"></speech_table>
      <ddk :ddk_table="results['ddk_table']" v-if="results['ddk_table'] && results['ddk_table'].length>0"></ddk>
      <transcription :transcription="results['transcription']" v-if="(results['transcription'] && results['transcription'].length>0)"></transcription>
   <!-- Transcription prob should go to language section when we start using it -->
   
      <unscripted_speech_recognition :transcription="results['unscripted_speech_recognition']" v-if="(results['unscripted_speech_recognition'] && results['unscripted_speech_recognition'].length>0)"></unscripted_speech_recognition>

      <phonation 
        :q_data="results['maxphonationTable']" 
        v-if="results['maxphonationTable'] && results['maxphonationTable'].length>0 && !is_isession">
      </phonation>
      <jittershimmer_table :jittershimmer_table="results['jittershimmerTable']" 
      v-if="results['jittershimmerTable'] && results['jittershimmerTable'].length>0 && !is_isession"></jittershimmer_table>
      <isession_phonation 
        :results="results"
        v-if="results['jittershimmerTable'] && results['jittershimmerTable'].length>0"
        @is_isession="is_isession=true">
      </isession_phonation>
    </div>
</template>    

<script>
import {ref, onUpdated, onMounted} from 'vue'
import speech_table  from '@/views/session/Result/type/speech_table.vue'
import ddk  from '@/views/session/Result/type/ddk.vue'
import transcription  from '@/views/session/Result/type/transcription.vue'
import unscripted_speech_recognition  from '@/views/session/Result/type/unscripted_speech_recognition.vue'

import jittershimmer_table from '@/views/session/Result/type/jittershimmer_table.vue';
import phonation  from '@/views/session/Result/type/phonation.vue'
import isession_phonation from '@/views/session/Result/type/isession_phonation.vue'

export default {
    name: 'speech_section',
    components: {
      speech_table,
      ddk,
      transcription,
      unscripted_speech_recognition,
      jittershimmer_table,
      phonation,
      isession_phonation
    },
    props: ['results'],
    setup(props) {

      const is_isession = ref(false)

      onMounted( () => {

      });

      return {
        is_isession,
      };

    }
  }
  </script>