<template>
  <div>
    <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='AutoSpeech';openModal('language_semantic_modal')">
    <div class="row">
      <div class="col-3" style="padding: 0px 0px 0px 10px;">
        <img :src="Language_semantic" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: orange">
      </div>
      <div class="col-9 d-flex align-items-center">
        <strong>Expressive Semantics</strong>
      </div>
    </div>
  </div>
  <div class="modal fade" id="language_semantic_modal" tabindex="-1" aria-labelledby="language_semantic_modal" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Expressive Semantics</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('language_semantic_modal')"></button>
        </div>
        <div class="modal-body">
          <div class="row" style="height:50vh">
            <div class="col-4 border-3 border-success border-end">
              <button :class="['btn','mb-2', 'w-100', selected_module == 'AutoSpeech'?'btn-primary':'btn-light']" type="button" @click="selected_module='AutoSpeech'">
                Spontaneous naming
              </button> 
              <button :class="['btn','mb-2', 'w-100', selected_module == 'WordFinding'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordFinding'">
                Semantic fluency
              </button> 
              <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->
              <button :class="['btn','mb-2', 'w-100', selected_module == 'CommonSayings'?'btn-primary':'btn-light']" type="button" @click="selected_module='CommonSayings'">
                Common sayings
              </button> 
     
              <button :class="['btn','mb-2', 'w-100', selected_module == 'WordCategory'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordCategory'">
                Word category
              </button> 

              <button :class="['btn','mb-2', 'w-100', selected_module == 'PictureNaming'?'btn-primary':'btn-light']" type="button" @click="selected_module='PictureNaming'">
                Confrontational picture naming
              </button>
              <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'Count_in_images'?'btn-primary':'btn-light']" type="button" @click="selected_module='Count_in_images'">
                Count in images
              </button> 
            </div>
            <div class="col-8">
              <div v-show="selected_module == 'AutoSpeech'">
                <p class="lead">Practise basic functional divergent naming</p>
                <p> Days of the week &emsp;  Months of the year &emsp; Counting to 10</p>
              </div>
              <div v-show="selected_module == 'WordFinding'">
                <p class="lead">Practise divergent naming</p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose difficulty</label>
                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['difficulty']">
                      <option value="Easy">Easy</option>
                      <!-- <option value="Medium">Medium</option> -->
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                      }"
                      messages-class="text-danger"
                      validation="required|min:1|max:15"/>
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'CommonSayings'">
                <p class="lead">Practise spontaneous recall with common sayings</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                      }"
                      messages-class="text-danger"
                      validation="required|min:1|max:15"/>
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'WordCategory'">
                <p class="lead">Practise identification of semantic features</p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose type</label>
                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['word_category_type']">
                      <option value="all">A mix of all types</option>
                      <option value="find">Find images that fit a category</option>
                      <option value="add">Add images to a category</option>
                      <option value="exclude">Exclude images that don't fit</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'PictureNaming'">
                <p class="lead">Practise confrontation naming</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'Count_in_images'">
                <p class="lead">Practise counting items on an image</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
          <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
        </div>
      </div>
    </div>
    </div> 
  </div>
</template>
<script>

import Language_semantic from '@/assets/images/dashboard/slt/training/Language_semantic.svg'

import { Modal } from 'bootstrap'


export default {
  name: 'semantic',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Language_semantic: Language_semantic, 
      modal_references: {},
      module_configuration: {
        difficulty: "Easy",
        word_category_type: 'all',
      },
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "language_semantic_modal": new Modal(document.getElementById('language_semantic_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('language_semantic_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('language_semantic_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        difficulty: "Easy",
        word_category_type: 'all',
      }
    },

  }
}
</script>