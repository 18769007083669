<template>
  <div>
    <!-- <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div> -->
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='WordArticulation';openModal()">
    <div class="row">                    
      <div class="col-3" style="padding: 0px 0px 0px 10px;">
        <img v-if="section=='words_n_phrases'" :src="Voice_Speech_text" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline">
        <img v-else :src="Voice_Speech_text" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline">
      </div>
      <div class="col-9 d-flex align-items-center">
        <strong>Words & Phrases</strong>
      </div>
    </div>
  </div>
  <div class="modal fade" :id="section" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Words & Phrases</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">

          <div class="row" style="height:40vh">
            <div class="col-4 border-3 border-success border-end">
              <button :class="['btn','mb-2', 'w-100', selected_module == 'WordArticulation'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordArticulation'">
                Words
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'FunctionalSentences'?'btn-primary':'btn-light']" type="button" @click="selected_module='FunctionalSentences'">
                Functional phrases
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'TongueTwistersSelection'?'btn-primary':'btn-light']" type="button" @click="selected_module='TongueTwistersSelection'">
                Tongue twisters
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'SpeakNumbers'?'btn-primary':'btn-light']" type="button" @click="selected_module='SpeakNumbers'">
                Speak numbers
              </button>
            </div>
            <div class="col-8">
              <div v-show="selected_module == 'WordArticulation'">
                <p class="lead">Practise phonation with choice of words </p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:30" />
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose a phoneme</label>
                  <div class="col-6">
                    <select class="form-select" v-model="module_configuration['phoneme']">
                      <option v-for="(item, key, index) in phonemeDisplayDictionary" :value="item.simple_representation" :key="index">{{item.IPA}} {{item.example_0}}<b style="color:#FF9333">{{item.example_1}}</b>{{item.example_2}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Phoneme location</label>
                  <div class="col-6">
                    <select class="form-select" v-model="module_configuration['phoneme_position']">
                      <option v-for="item in phoneme_position_options" :key="item.key" :value="item">{{ item }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Number of syllables</label>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-6">
                      <select class="form-select form-select-inline" v-model="module_configuration['n_syllables_comparator']">
                        <option key="any" value="any">any</option>
                        <option key="greater" value="greater">greater than</option>
                        <option key="lower" value="lower">lower than</option>
                      </select>
                      </div>
                      <div class="col-6">
                      <select class="form-select form-select-inline" v-model="module_configuration['n_syllables']">
                        <option key=1 value=1>1</option>
                        <option key=2 value=2>2</option>
                        <option key=3 value=3>3</option>
                        <option key=4 value=4>4</option>
                        <option key=5 value=5>5</option>
                      </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Cluster options</label>
                  <div class="col-6">
                    <select class="form-select" v-model="module_configuration['cluster_conditions']">
                      <option v-for="(item, index) in cluster_options" :key="index" :value="index">{{ item }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label">Avoid</label>
                  <div class="col-6 text-start">
                    <div class="form-check">
                      <label class="form-check-label" for="6M">Diphthong</label>
                      <input class="form-check-input" type="checkbox" v-model="avoid_manners['diphtong']" id="diphtong" value="diphtong">
                    </div>
                    <div class="form-check">
                      <label class="form-check-label" for="6M">Approximants</label>
                      <input class="form-check-input" type="checkbox" v-model="avoid_manners['approximants']" id="approximants" value="approximants">
                    </div>
                    <div class="form-check">
                      <label class="form-check-label" for="6M">Affricates</label>
                      <input class="form-check-input" type="checkbox" v-model="avoid_manners['affricates']" id="affricates" value="affricates">
                    </div>
                    <div class="form-check">
                      <label class="form-check-label" for="6M">Fricative</label>
                      <input class="form-check-input" type="checkbox" v-model="avoid_manners['fricative']" id="fricative" value="fricative">
                    </div>
                  </div>
                </div>
                <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" @click="autoGenerate" :disabled="local_number_exercises<=0">Generate words</button>
                <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Generating...
                </button>
                <div v-if="module_configuration['words_form'].text.length>0 && module_configuration['words_form'].name === 'WordArticulation'" class="mt-2">
                  <div class="row">
                    <div class="col">
                      <div v-for="id in Math.ceil(module_configuration['words_form'].text.length/2)" :key="id">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control" v-model="module_configuration['words_form'].text[id-1]">
                          <div class="input-group-append">
                            <button class="btn btn-outline-danger" type="button" @click="deleteWord(id-1)">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div v-for="id in module_configuration['words_form'].text.length - Math.ceil(module_configuration['words_form'].text.length/2)" :key="id">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control" v-model="module_configuration['words_form'].text[id-1+Math.ceil(module_configuration['words_form'].text.length/2)]">
                          <div class="input-group-append">
                            <button class="btn btn-outline-danger" type="button" @click="deleteWord(id-1+Math.ceil(module_configuration['words_form'].text.length/2))">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'FunctionalSentences'">
                <p class="lead">Practise phonation reading functional phrases</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-if="selected_module == 'TongueTwistersSelection'">
                <p class="lead">Practise dividing attention between phonation and articulation </p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose sound in tongue twister</label>
                  <div class="col-6">
                    <select class="form-select" v-model="module_configuration['tongue_twister_phoneme']">
                    <option v-for="(item, key, index) in tongue_twisters_phonemes_simple_representation" :value="item.simple_representation" :key="index">{{item.IPA}} {{item.example_0}}<b style="color:#FF9333">{{item.example_1}}</b>{{item.example_2}}</option>
                  </select>

                    <!-- <select class="form-select form-select" v-model="tongue_twister_phoneme">
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="ch">CH</option>
                      <option value="d">D</option>
                      <option value="e">E</option>
                      <option value="f">F</option>
                      <option value="g">G</option>
                      <option value="h">H</option>
                      <option value="j">J</option>
                      <option value="k">K</option>
                      <option value="l">L</option>
                      <option value="m">M</option>
                      <option value="n">N</option>
                      <option value="o">O</option>
                      <option value="p">P</option>
                      <option value="r">R</option>
                      <option value="s">S</option>
                      <option value="sh">SH</option>
                      <option value="t">T</option>
                      <option value="th">TH</option>
                      <option value="u">U</option>
                      <option value="v">V</option>
                      <option value="w">W</option>
                      <option value="x">X</option>
                      <option value="y">Y</option>
                      <option value="z">Z</option>

                    
                    </select> -->
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>

              <div v-if="selected_module == 'SpeakNumbers'">
                <p class="lead">Practise speaking written and numeral numbers </p>
                <div class="row mb-2">
       
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
          <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import Voice_Speech_text from '@/assets/images/dashboard/slt/training/Voice_Speech_text.svg'
import {getTodayString} from '@/media_input/utils'


import {find_n_random_words_containing_phonemes_2} from '@/media_input/words'

import { Modal } from 'bootstrap'
import {pub_quiz} from '@/utils/interface_ai'
import constants from '@/utils/constants'

export default {
  name: 'words_n_phrases',
  components: {},
  props: 
  {
    number_exercises: Number,
    section: String
  },
  data(){
    return {
      today: getTodayString(),

      Voice_Speech_text: Voice_Speech_text, 
      modal_reference: null,

      selected_module: '',
      local_number_exercises: 5,


      module_configuration: {
        topic: 'Latest News',
        phoneme: "iy",

        n_syllables_comparator: "any",
        n_syllables:2,

        syllable_conditions: null,
        phoneme_position: 'any',

        words_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following word loudly and clearly.',
          text: [],
          n_item:5,
        },
      },

      phoneme_position_options: ['any','WI','WM','WF'],
      
      cluster_options: ["Avoid clusters","With clusters","Doesn't matter"],
      avoid_manners: {diphtong:false,approximant:false,affricate:false,fricative:false},

      phonetic_conditions:{difficulty_rating:'hard',manners:[],placings:[],voicings:[]},

      phonemeDisplayDictionary: [],
      phonemeDictionary: [],
      generating_word_list: false,

      autoGenWords: [],

      tongue_twisters_phonemes_simple_representation:[],
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    this.phonemeDisplayDictionary = constants.phonemeDisplayListWithExamples;
    this.phonemeDictionary = constants.phonemeDisplayDictionary

    let hasAny = false
    for (let i=0; i<this.phonemeDisplayDictionary.length; i++) {
      if (this.phonemeDisplayDictionary[i]['simple_representation'] === 'any') {
        hasAny = true
        break;
      }
    }
    
    this.tongue_twisters_phonemes_simple_representation = constants.extract_tongue_twisters_phonemes()
    
  },
  mounted() {

    this.modal_reference = new Modal(document.getElementById(this.section))

    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {

      if (this.selected_module === "WordArticulation") { //if name is words or fun facts check that form is valid
        if (this.module_configuration['words_form'].text.length < 1) {
          alert("Please generate some words first.")
          return false
        }
      }
      
      return true
    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal() {
      this.reset()
      if (this.modal_reference) {
        this.$emit('selected_module', this.selected_module)
        this.modal_reference.show()
      }
    },
    closeModal() {
      if (this.modal_reference) {
    
        this.modal_reference.hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset(){
      this.module_configuration = {
        topic: 'Latest News',
        phoneme: "iy",

        n_syllables_comparator: "any",
        n_syllables:2,


        phoneme_position: 'any',

        syllable_conditions: null,

        words_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following word loudly and clearly.',
          text: [],
          n_item:5,
        },
      }

      this.avoid_manners = {diphtong:false,approximant:false,affricate:false,fricative:false}

      this.phonetic_conditions = {difficulty_rating:'hard',manners:[],placings:[],voicings:[]}

    },
    autoGenerate()
    {
      const _this = this

      _this.showExercise = true;

      _this.generating_word_list = true
      _this.autoGenWords = []

      console.debug('this.searchKey = ' + _this.module_configuration['phoneme'])

      _this.module_configuration['syllable_conditions'] = {condition:_this.module_configuration['n_syllables_comparator'], n_syl: _this.module_configuration['n_syllables']}

      if(_this.module_configuration['phoneme'] == '')
      {
        _this.module_configuration['phoneme'] = 'any'
      }

      _this.phonetic_conditions['manners'] = []
      _this.phonetic_conditions['placings'] = []
      _this.phonetic_conditions['voicings'] = []

      let manner_dict = {'close':'0','close-mid':'1','open-mid':'2','central':'3','open':'4','diphthong':'5','plosive':'6','nasal':'7','approximant':'8','fricative':'9','lateral approximant':'10','affricate':'11'}
      let voicing_dict = {'voiced':'0','voiceless':'1'}
      let placement_dict = {'front':'0','central':'1','back':'2','front-back':'3','central-back':'4','back-front':'5','front-central':'6','back-central':'7','bilabial':'8','alveolar':'9','labial-velar':'10','glottal':'11','velar':'12','palatal':'13','postalveolar':'14','affricate':'15','dental':'16','labiodental':'17'}

      for(let k in _this.avoid_manners)
      {
        if(_this.avoid_manners[k])
        {
          _this.phonetic_conditions['manners'].push(manner_dict[k])
        }
      }

      for(let k in _this.avoid_placings)
      {
        if(_this.avoid_placings[k])
        {
          _this.phonetic_conditions['placings'].push(placement_dict[k])
        }
      }

      for(let k in _this.avoid_voicings)
      {
        if(_this.avoid_voicings[k])
        {
          _this.phonetic_conditions['voicings'].push(voicing_dict[k])
        }
      }

      let cluster_conditions = {}

      cluster_conditions['has_clusters'] = _this.module_configuration['cluster_conditions']// 0:no,1:yes,2:doesnt matter
      cluster_conditions['which_cluster'] = 'any' //cluster/'any'

      console.debug("cluster_conditions['has_clusters'] = " + cluster_conditions['has_clusters'])

      _this.phonetic_conditions['cluster_conditions'] = cluster_conditions

      let search_phoneme = this.phonemeDictionary[_this.module_configuration['phoneme']]
      /*
      alert(_this.local_number_exercises)
      alert(search_phoneme)
      alert(_this.module_configuration['phoneme_position'])
      alert(_this.module_configuration['syllable_conditions'])
      alert(_this.phonetic_conditions)*/
      find_n_random_words_containing_phonemes_2(_this.local_number_exercises, search_phoneme, _this.module_configuration['phoneme_position'], _this.module_configuration['syllable_conditions'], _this.phonetic_conditions).then(function(word_list){

        _this.generating_word_list = false

        console.debug('word_list = ' + word_list)
        _this.random_words = true

        if(word_list.length<1)
        {
          alert('No words were found containing these letters');
          _this.autoGenWords = [];
        }
        else
        {
          _this.autoGenWords = word_list;
        }
        _this.module_configuration['words_form'].text = _this.autoGenWords;
      })

      _this.module_configuration['words_form'].name="WordArticulation"
     
    },
    deleteWord(id) {
      this.module_configuration['words_form'].text.splice(id, 1)
      this.local_number_exercises = this.number_exercises - 1
      console.debug(this.module_configuration['words_form'].text)
      console.debug(this.autoGenWords)
    },
  }
}
</script>