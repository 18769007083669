<template>
  <div>
    <div class="card card-body mt-2">
      <span style="display:flex;justify-content:center;font-size:22px"> {{name}}</span>
      <div class="row mt-2 justify-content-center">
        <div class="col-4">
          <div class="row">
            <span class="custom-text"> Correct answers: {{ correct }}</span>
          </div>
          <div class="row">
            <span class="custom-text"> Incorrect answers: {{ incorrect }} </span>
          </div>
        </div>
        <div class="col-2">
          <div style="margin:auto">
            <canvas ref="canvasRef" style="height:150px"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import {ref, onUpdated, onMounted, watch} from 'vue'
  import Chart from 'chart.js/auto';
  import 'chartjs-adapter-luxon';

  export default {
    name: 'correct_incorrect_indicator',
    components: {
    },
    props: {
      correct: {
        type: Number,
        default: null
      },
      incorrect: {
        type: Number,
        default: null
      },
      name: {
        type: String,
        default: null
      }
    },
    setup(props) {
      const chartRef = ref(null);
      const canvasRef = ref(null);

      const createPieChart = (correct, incorrect) => {
        
        const data = {
          labels: ['Correct', 'Incorrect'],
          datasets: [{
            data: [correct, incorrect],
            backgroundColor: ['rgba(99, 255, 64, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            borderColor: ['rgba(99, 255, 64, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1
          }]
        };

        const options = {
          responsive: true,
          maintainAspectRatio: false
        };

        if (chartRef.value) {
          chartRef.value.destroy();
        }
        const ctx = canvasRef.value.getContext('2d');

        
        chartRef.value = new Chart(ctx, {
          type: 'pie',
          data,
          options
        });

        if (canvasRef.value) {
          canvasRef.value.style.height = '150px';
          canvasRef.value.height = 150;
        }
      };
    
      onMounted( () => {
        createPieChart(props.correct, props.incorrect);
      });

      watch([() => props.correct, () => props.incorrect], ([newCorrect, newIncorrect]) => {
        createPieChart(newCorrect, newIncorrect);
      });


      return {
        canvasRef
      };

    }
  }
</script>