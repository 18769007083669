<template>
  <div>
    <div class="row d-flex mb-3" >
      <div class="col-xl-10 pe-0 d-flex flex-column">
        <div class="card card-body h-100">
          <div class="card card-body">
              <span class="read-text">Type in a word with the 
                <b style="color:#f56c6c"> opposite meaning </b> to the word shown in the blue box. 
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say('Type in a word with the opposite meaning to the word shown in the blue box.')"></span>
          </div>

          <div class="row justify-content-center mt-4" >
            <div class="col-md-4" @click="bv_tts.say(selected_word)" >
              <div class="card card-body custom-card_blue">
                <span class="read-text">{{selected_word}} <img :src="audioImg" style="width:35px;;cursor: pointer;"></span>
              </div>
            </div>
            
            <div class="col-md-4">
              <div class="card card-body custom-card_pink"> 
                <span class="read-text">{{hint_antonym}}</span>
              </div>
            </div>

            <div class="col-md-4">
              <button class="mt-3 btn btn-secondary btn-lg w-100" @click="revealLetter()">Reveal Letter <i class="bi bi-eye" style="margin-left: 5mm;"></i></button>
            </div>
          </div>

          <div class="row mt-4" >
            <span style="margin-top:2%;size: x-large;"> {{your_answer_txt}} </span>
            <div class="row mt-2 justify-content-center">
              <input v-model="answer"  @input="auto_answer_checker()" :placeholder="placeholder_text" style="font-size: x-large; width:40%"/>
              <button v-if="button_check" class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check()">
                Submit answer <i class="bi bi-send" style="margin-left: 5mm;"></i>
              </button>
            </div>
          </div>

          <div style="margin-top:1%;margin-bottom: 1%">
            <p class="h5" :style="msg_colour" style="margin-top:2%">{{result_message}}</p>
          </div>
          
        </div>
      </div>

      <div class="modal fade" id="level_up_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Level up</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="m-3">
                  <div class="row">
                    <h4 >{{ level_up_message }}</h4>
                  </div>
                  <div class="row mt-2">
                    <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-xl-2" style="padding-left: 0px">
          <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
            <p class="lead">Exercise {{ex_counter}} of {{number_exercises}}</p>
            <button class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i> </button>
            <button v-if="show_results" class="btn btn-outline-warning btn-lg w-100 mt-2" @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
    
</template>
<script>
import {getData, getUserDataDir, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import audioImg from '@/assets/images/audio_logo.png'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'
import { Modal } from 'bootstrap'

export default {
  name: 'Antonym',
  props: 
  {
    exForm: {
      type: Object,
    },
    /*
      exForm input example
      {
        "Name":"Antonym easy",
        "difficulty":"easy",
        "exercise_type":"antonym_exercise",
        "plan_name":"Antonym",
        "assessment_type":"SLP_TRAINING",
        "curr_exercise_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "ex_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "today":"2023-07-11 16:20",
        "audioIns1":null,
        "imageAddress":null,
        "videoAddress":null,
        "audioAddress":null,
        "audioIns2":null,
        "videoIns":null,
        "exercise_type":"antonym_exercise",
        "url":null,
        "url2image":null,
        "audioIns":null,
        "module_name":"Antonym",
        "has_results":false,
        "current_exercise_index":0,
        "number_exercises":7
      }

    */
  },
  data(){
    return {
      base_level_address:'/Levels/Antonym',
      level_address:'',
      number_hints:0,
      correct_counter:0,
      incorrect_counter:0,
      answer:'',
      placeholder_text: ' _ _ _',
      result_message: '',
      selected_word: '',
      selected_antonym:'',
      hint_antonym:'',
      button_check: true,
      correct_streak: 0,
      ex_counter: this.exForm.current_exercise_index + 1,
      failed_already: false,
      number_exercises: this.exForm.total_all_type_exercises,
      difficulty: 'easy',
      level:1,
      level_properties:{
            1:{'level':1,'difficulty':'easy'},
            2:{'level':2,'difficulty':'medium'},
            3:{'level':3,'difficulty':'hard'}},
      modifiers: {'easy':'e','medium':'m','hard':'h'},      
      revealed_letters:0,
      past_exercises: [],
      your_answer_txt: 'Your answer',
      level_up_message:'',
      msg_colour:'',
      first_exercise: false,
      show_results: false,
      audioImg:audioImg,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {
    const _this = this

    logActivity( 'accessed', 'antonym_exercise')

    _this.getDifficulty()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  methods: {

    auto_answer_checker()
    {
      const _this = this

      if(_this.answer.toLowerCase()==_this.selected_antonym.toLowerCase() && _this.button_check)
      {
        if(!_this.failed_already)
        {
          _this.correct_counter = _this.correct_counter+1
        }

        console.debug('_this.correct_counter = ' + _this.correct_counter)
        _this.button_check = false
        _this.result_message = "Congrats! You got the right word. \n   Press next to do more."
        _this.msg_colour = "color:green;fontSize:20px;white-space: pre-wrap;";
        _this.correct_streak = _this.correct_streak+1
 

        if(_this.correct_streak>=4)
        {
          if(_this.difficulty=='easy')
          {
            _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
            let level_up_pop = new Modal(document.getElementById('level_up_pop'));
            level_up_pop.show()

            _this.difficulty='medium'
            _this.correct_streak = 0
            _this.past_exercises = []
          }
          else if(_this.difficulty=='medium')
          {
            _this.past_exercises = []

            _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
            let level_up_pop = new Modal(document.getElementById('level_up_pop'));
            level_up_pop.show()

            _this.correct_streak = 0

            _this.difficulty='hard'
          }
        }

        updateDataDirectory(_this.level_address,{Level:_this.difficulty,Streak:_this.correct_streak})

      }
    },
    answer_check()
    {
        
      const _this = this
      if(_this.button_check==false)
      {
        if(_this.correct_streak>=4)
        {
          if(_this.difficulty=='easy')
          {
            _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
            _this.difficulty='medium'
            _this.level = 2
            _this.correct_streak = 0
            _this.past_exercises = []
          }
          else if(_this.difficulty=='medium')
          {
            _this.past_exercises = []
            _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
            _this.correct_streak = 0
            _this.level = 3


            _this.difficulty='hard'
          }
        }
      }
      else
      {
        if(_this.answer.toLowerCase()==_this.selected_antonym.toLowerCase())
        {
          _this.button_check = false
          _this.result_message = "Congrats! You got the right word. \n Press next to do more."
          _this.correct_streak = _this.correct_streak+1
          _this.msg_colour = "color:green;fontSize:20px;";
        }
        else
        {
          if(!_this.failed_already)
          {
            _this.incorrect_counter = _this.incorrect_counter+1
          }
          _this.failed_already = true
          _this.correct_streak = _this.correct_streak-1
          _this.result_message = "This wasn't the word we were expecting. \n Try again (you can click 'Reveal Letter' for a tip) "
          _this.msg_colour = "white-space:pre-wrap;color:red;fontSize:20px;";
        }
      }

      updateDataDirectory(_this.level_address,{Level:_this.level, Streak:_this.correct_streak})
    },
    nextExercise()
    {
      const _this = this;
      _this.saveResults()
      _this.$emit('nextExercise');
    },
    goToResults()
    {
      this.saveResults()
      
      let goToResultsData = {curr_ex_done:false}
      this.$emit('goToResults', goToResultsData);
    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1,Number_Hints:_this.number_hints}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
      
        let results = {all_results:all_results, plot_results:plot_results}

        var antonym_data = {resultsTablePre: {exercise_type: 'Antonym Exercise', exercise: 'Antonym Exercise', results: results, audio_address:''}}

        save_pre_computed_results(_this.exForm,_this.$firebase,antonym_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', antonym_data);
      }
    },
    getDifficulty()
    {
      const _this = this

      let userType = localStorage.getItem('userCategory');


      let uid = localStorage.getItem('uid');

      this.level_address = '/Users/' + uid + this.base_level_address


      if(userType!=undefined || userType==='slp')
      {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='')
          {

              this.level_address = '/Users/'+ client_number + this.base_level_address

          }
      }



      let address = _this.level_address

      let do_once = true

      console.debug('address = ' + address)

      getData(address, function (err, result) {

        if(do_once)
        {
          do_once = false

          let level = result.child('Level').val()
          let correct_streak = result.child('Streak').val()


          if(level==undefined || level==null)
          {
            level = 1
            correct_streak = 0

            updateDataDirectory(address, {Level:level, Streak:correct_streak})
            console.debug('creating level')
          }

          if(level==='easy')
          {
            level = 1
            updateDataDirectory(address, {Level:level, Streak:correct_streak})

          }
          else if(level==='medium')
          {
            level = 2
            updateDataDirectory(address, {Level:level, Streak:correct_streak})

          }
          else if(level==='hard')
          {
            level = 3
            updateDataDirectory(address, {Level:level, Streak:correct_streak})

          }

          
          _this.level = level

          console.debug('level = ' + level)



          _this.difficulty = _this.level_properties[level]['difficulty']
          _this.correct_streak = correct_streak

          _this.setup(_this.difficulty)
        }
      })
    },
    setup(difficulty)
    {
      const _this = this;
      _this.failed_already = false

      _this.first_exercise = !_this.exForm.has_results
      _this.show_results = !_this.first_exercise
      console.debug('_this.show_results = ' + _this.show_results)
      console.debug('_this.exForm.current_exercise_index = ' + _this.exForm.current_exercise_index)

      if(_this.ex_counter>_this.total_exercises)
      {
        _this.goToResults()
        return
      }

      _this.result_message = ''
      _this.answer = ''
      _this.button_check = true

      _this.revealed_letters = 0
      var do_once = true;

      let modifier = _this.modifiers[difficulty]      
    
      let targetDir = 'Exercises/General/Antonyms/'+modifier

      getData(targetDir, function (err, result) {

        if(do_once)
        {
          do_once = false
          
          let total_exercises = result.numChildren()

          let random_exercise = Math.floor(Math.random()*total_exercises)


          while(_this.past_exercises.includes(random_exercise))
          {

              random_exercise = Math.floor(Math.random()*total_exercises)
          }

          _this.past_exercises.push(random_exercise)

          console.debug('_this.past_exercises = ' + _this.past_exercises)


          _this.selected_word = result.child(random_exercise).child('word').val()
          _this.selected_antonym = result.child(random_exercise).child('antonym').val()
          _this.hint_antonym = ''
          _this.placeholder_text = ''
          _this.your_answer_txt = 'Type in your answer here (a ' + _this.selected_antonym.length + ' letter word)'

          for(let i=0;i<_this.selected_antonym.length;++i)
          {
            _this.hint_antonym = _this.hint_antonym + ' * '
            _this.placeholder_text = _this.placeholder_text + ' _'
          }
        }

      });  
    },
    revealLetter()
    {
      const _this = this

      _this.number_hints = _this.number_hints + 1

      _this.revealed_letters = _this.revealed_letters+1

      _this.hint_antonym = _this.selected_antonym.substring(0,_this.revealed_letters)

      _this.correct_streak = _this.correct_streak -0.5

      for(let i=0;i<_this.selected_antonym.length-_this.revealed_letters;++i)
      {
        _this.hint_antonym = _this.hint_antonym + ' * '
      }
    },
  },
}
</script>

<style scoped>

.inline-block-child {
  display: inline-block;
}

.custom-card_blue{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.empty_item:empty:before {
  content: "\200b";
}


</style>

<!-- Result Description

{
  "Antonym Exercise": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Antonyms": { //Need to remove this layer
          "exercises": { //Need to remove this layer
            "easy": { //Need to remove this layer
              "<key>": {
                "audio_address": "<empty>",
                "resultsTablePre": {
                  "audio_address": "<empty>",
                  "exercise": "Antonym Exercise",
                  "exercise_type": "Antonym Exercise",
                  "results": {
                    "all_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Number_Hints": "<int>",
                      "Total": "<int>"
                    },
                    "plot_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Total": "<int>"
                    }
                  }
                },
                "results_type": "pre_computed"
              }
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Antonym Exercise"
  }
}
-->