<template>
  <div>
    <div class="row d-flex mb-3">
      <div class="col-xl-10 pe-0 d-flex flex-column">
        <div class="card card-body h-100">
          <div class="card card-body">
              <span class="read-text">{{ instructions }}
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="read_instructions()"></span>
          </div>


          <div  style="margin-top: 6mm;">
            <div class="row mt-2 justify-content-center">
              <span style="font-size: x-large;"> {{your_answer_txt}} </span>

              <input v-model="answer"  @input="auto_answer_checker()"  :placeholder="placeholder_text"  style="font-size: x-large; width:40%"/>
              <button  class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check()">
                Submit answer <i class="bi bi-send" style="margin-left: 5mm;"></i>
              </button>
            </div>
          </div>

          <div style="margin-top: 5%;">
            <div class="row mt-2  mb-2 justify-content-center">
                <button v-for="letter, index in option_buttons" type="text" @click=select_letter(letter,index) class="keyboard-button" >
                {{option_buttons[index]}}
                </button>
            </div>

                <button  type="text" @click=delete_digit() class="keyboard-button-delete" >
                    Delete
                </button>
            <p class="h5" :style="msg_colour" style="margin-top:2%">{{result_message}}</p>

          </div>

        </div>
      </div>

      <div class="modal fade" id="level_up_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Level up</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="m-3">
                  <div class="row">
                    <h4 >{{ level_up_message }}</h4>
                  </div>
                  <div class="row mt-2">
                    <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Let's go</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-xl-2" style="padding-left: 0px">
          <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
            <p class="lead">Exercise {{ex_counter}} of {{number_exercises}}</p>
          <hr/>

          <button class="btn btn-info btn-lg w-100 mt-2"  @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
          <button v-if="show_results" class="btn btn-outline-warning btn-lg w-100 mt-2" @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
    
</template>
<script>
import {getData, getUserDataDir, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import audioImg from '@/assets/images/audio_logo.png'
import {BV_Points} from '@/utils/bv_points'
import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'

// Based on Lemanissier et al., 2023

export default {
  name: 'Write_numbers',
  props: 
  {
    exForm: {
      type: Object,
    },
    /*
      exForm input example
      {
        "Name":"Antonym easy",
        "exercise_type":"antonym_exercise",
        "plan_name":"Antonym",
        "assessment_type":"SLP_TRAINING",
        "curr_exercise_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "ex_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "today":"2023-07-11 16:20",
        "audioIns1":null,
        "imageAddress":null,
        "videoAddress":null,
        "audioAddress":null,
        "audioIns2":null,
        "videoIns":null,
        "exercise_type":"antonym_exercise",
        "url":null,
        "url2image":null,
        "audioIns":null,
        "module_name":"Antonym",
        "has_results":false,
        "current_exercise_index":0,
        "number_exercises":7
      }

    */
  },
  data(){
    return {
      base_level_address:'/Levels/Write_numbers',
      level_address:'',
      correct_counter:0,
      incorrect_counter:0,
      level_up_target:3,
      placeholder_text: ' _ _ _',
      option_buttons:['0','1','2','3','4','5','6','7','8','9'],
      answer:'',
      instructions:'',
      result_message: '',
      selected_word: '',
      current_level:1,

      correct_streak:0,
      total:0,
      ex_counter: this.exForm.current_exercise_index + 1,
      failed_already: false,
      number_exercises: this.exForm.total_all_type_exercises,
      level_properties:{
          1:{'level':1,'n_digits':1},
          2:{'level':2,'n_digits':2},
          3:{'level':3,'n_digits':3},
          4:{'level':4,'n_digits':4},
          5:{'level':5,'n_digits':5},
      },
      n_digits:1,
      past_exercises: [],
      your_answer_txt: 'Your answer',
      level_up_message:'You have been doing really well! Let’s try something harder.',
      msg_colour:'',
      first_exercise: false,
      show_results: false,
      audioImg:audioImg,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {
    const _this = this

    _this.show_results = _this.exForm.has_results


    logActivity( 'accessed', 'copy_word_exercise')

    _this.getUserLevel()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  methods: {

    auto_answer_checker()
    {
      const _this = this


      
      let answer_lower = _this.answer.toLowerCase()


      
      if(answer_lower===_this.target_number)
      {
        this.answer_check()

      }
    },
    answer_check()
    {
        
      const _this = this

      let correct = false;

      let levelling_up = false



      
        if(_this.answer.toLowerCase()===_this.target_number)
        {
            correct = true
            


            if(!this.failed_already)
            {
                _this.correct_counter = 1
                _this.correct_streak = _this.correct_streak+1

                console.debug('correct_streak = ' + this.correct_streak)


                if(_this.correct_streak>=_this.level_up_target)
                {
                    if(_this.current_level<Object.keys(_this.level_properties).length)
                    {
                      this.correct_streak = 0
                      _this.current_level = _this.current_level+1

                        levelling_up = true

                      let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                      level_up_pop.show()
                    }

                }
            }
        }
        else
        {
            if(!_this.failed_already)
            {
                _this.incorrect_counter = _this.incorrect_counter+1
            }
            _this.failed_already = true
            _this.correct_streak = _this.correct_streak-1
            console.debug('fail')
            _this.result_message = "This isn't the number. Try again."
            _this.msg_colour = "color:red;fontSize:20px;";
        }


        console.debug('_this.correct_streak = ' + _this.correct_streak)
      updateDataDirectory(_this.level_address,{current_level:_this.current_level, level_up_counter:_this.correct_streak})

      if(correct && !levelling_up)
      {
        console.debug('here2')
        _this.result_message = "Congrats! You got the right number"
        _this.msg_colour = "color:green;fontSize:20px;";

        startConfetti(this)

        
        setTimeout(() => {
            this.nextExercise()
        }, 900);

      }
    },
    delete_digit()
    {
        if(this.answer.length>0)
        {
            this.answer = this.answer.substring(0,this.answer.length-1)
        }

    },
    select_letter(letter,index)
    {
        this.answer = this.answer+letter

        this.auto_answer_checker()

    },
    nextExercise()
    {
      const _this = this;
      _this.saveResults()
      _this.$emit('nextExercise');
    },
    goToResults()
    {
      this.saveResults()
      
      let goToResultsData = {curr_ex_done:false}
      this.$emit('goToResults', goToResultsData);
    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:this.correct_counter+this.incorrect_counter, n_digits:this.n_digits,level:this.current_level}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
      
        let results = {all_results:all_results, plot_results:plot_results}

        var copy_word_data = {resultsTablePre: {exercise_type: 'Write Numbers', exercise: 'Write Numbers', results: results, audio_address:''}}

        save_pre_computed_results(_this.exForm,_this.$firebase,copy_word_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', copy_word_data);
      }
    },
    getUserLevel()
      { 
        const _this = this

        let userType = localStorage.getItem('userCategory');


        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address


        if(userType!=undefined || userType==='slp')
        {
            let client_number = localStorage.getItem('client_uid');

            if(client_number!='undefined' && client_number!=null && client_number!='')
            {

                this.level_address = '/Users/'+ client_number + this.base_level_address

            }
        }


        let do_once = true


        getData(_this.level_address, function (err, result) {

          if(do_once)
          {
            do_once = false
            
            let current_level = result.child('current_level').val()
            let lvl_up_counter = result.child('level_up_counter').val()

            console.debug('current_level = ' + current_level)


            if(current_level==undefined || lvl_up_counter==undefined)
            {
              current_level = 1
              lvl_up_counter = 0
              updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
            }

            _this.correct_streak = lvl_up_counter

            if(current_level<1 || current_level>Object.keys(_this.level_properties).length)
            {
              current_level = 1
              
            }

            console.debug('current_level = ' + current_level)
            
            console.debug('lvl_up_counter = ' + lvl_up_counter)

            _this.current_level = current_level


            _this.curr_level_properties = _this.level_properties[_this.current_level]
            _this.n_digits = _this.curr_level_properties['n_digits']

          
            _this.setup_task()

          }
        })


      },
      read_instructions()
      {
        const _this = this
        this.instructions = 'Write the number you hear'

        this.task_instructions = [this.instructions,this.target_number]


        this.bv_tts.say_sentences(this.task_instructions, 125)

      },
      randomiseNumber(number_digits)
      {
        let number = ''

        this.placeholder_text = ''


        for(let k=0;k<number_digits;++k)
        {
            let random_number = Math.floor(Math.random() * 10);
            if(k==0 && random_number==0)
            {
                random_number = 1
            }
            number = number+random_number

            this.placeholder_text = this.placeholder_text + ' _'
            
        }

        return number

      },
      setup_task()
      {
        const _this = this
        this.instructions = 'Write the number you hear'

        let number = this.randomiseNumber(this.n_digits)

        this.target_number = number

        this.task_instructions = [this.instructions,number]


        this.bv_tts.say_sentences(this.task_instructions, 125)

      
      },
  },
}
</script>

<style scoped>

.inline-block-child {
  display: inline-block;
}

.custom-card_blue{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.empty_item:empty:before {
  content: "\200b";
}

.keyboard-button
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  background-color: white;
  border-color: blue;

}


.keyboard-button-delete
{
  width: 60mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  background-color: white;
  border-color: red;

}



</style>

