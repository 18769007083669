<template>
    <div>
      <div class="row d-flex mb-3" >
        <div class="col-xl-10 pe-0 d-flex flex-column">
          <div class="card card-body h-100">
            <div class="card card-body">
                <h4>
                    {{instruct_msg}}
                    <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(instruct_msg)">

                </h4>
            </div>
            <div style="margin-top: 3.5%;">
              <div v-if="ex_format === 'single' && rand_letter.length>0" class="row mt-2 justify-content-center">
                <div class="col-md-4" >
                    <div class="card card-body" @click="bv_tts.say(rand_letter[0].letter)">
                        <b class="read-text"> {{rand_letter[0].letter}} </b>
                    </div>
                </div>
            </div>
            <div v-if="ex_format === 'single' && rand_letter.length>0" class="row mt-1 justify-content-center">
                <div class="col-md-4" >
                    <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_letter[0].letter)">
                </div>
            </div>
            <div v-if="ex_format !== 'single' && rand_letter.length>2 && rand_words.length>2" class="row mt-2 justify-content-center">
                <div class="col-md-4" >
                <div :class="letter_display[0]" @click="select_letter(0)">
                  <b class="read-text">{{rand_letter[0].letter}} </b>
                </div>
              </div>
              
              <div class="col-md-4">
                <div :class="letter_display[1]" @click="select_letter(1)">
                  <b class="read-text">{{rand_letter[1].letter}} </b>
                </div>
              </div>
  
              <div class="col-md-4">
                <div :class="letter_display[2]" @click="select_letter(2)">
                  <b class="read-text">{{rand_letter[2].letter}} </b>
                </div>
              </div>
            </div>
            <div v-if="ex_format !== 'single' && rand_letter.length>2 && rand_words.length>2" class="row mt-1 justify-content-center">
                <div class="col-md-4" >
                   <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_letter[0].letter)">
              </div>
              
              <div class="col-md-4">
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_letter[1].letter)">
              </div>
  
              <div class="col-md-4">
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_letter[2].letter)">
              </div>
            </div>
            <div v-if="rand_words.length>2" class="row justify-content-center" style="margin-top: 20mm;">
              <div class="col-md-4" >
                <div :class="btn_display[0]" @click="select_button(0)">
                  <span class="read-text">{{rand_words[0].word}} </span>
                </div>
              </div>
              
              <div class="col-md-4">
                <div :class="btn_display[1]" @click="select_button(1)">
                  <span class="read-text">{{rand_words[1].word}} </span>
                </div>
              </div>
  
              <div class="col-md-4">
                <div :class="btn_display[2]" @click="select_button(2)">
                  <span class="read-text">{{rand_words[2].word}} </span>
                </div>
              </div>
            </div>
            <div v-if="rand_words.length>2" class="row mt-1 justify-content-center">
                <div class="col-md-4" >
                <!-- <div class="card" @click="select_letter(0)"> -->
                   <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_words[0].word)">
                <!-- </div> -->
              </div>
              
              <div class="col-md-4">
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_words[1].word)">
              </div>
  
              <div class="col-md-4">
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.say(rand_words[2].word)">
              </div>
            </div>
            
  
            <!-- <div class="row mt-4">
              <div class="row mt-2 justify-content-center">
                <button class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check()">
                  Submit answer
                </button>
              </div>
            </div> -->
  
            <div style="margin-top:1%;margin-bottom: 1%">
              <span class="empty_item" :style="msg_colour" style="margin-top:2%">{{result_message}}</span>
            </div>

            </div>
            
            
          </div>
        </div>
  
        <div class="modal fade" id="level_up_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Level up</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="m-3">
                    <div class="row">
                      <h4 >{{ level_up_message }}</h4>
                    </div>
                    <div class="row mt-2">
                      <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div class="modal fade" id="level_up_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Level up</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="m-3">
                            <div class="row">
                            <h4 >{{ level_up_message }}</h4>
                            </div>
                            <div class="row mt-2">
                            <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
        <div class="col-xl-2" style="padding-left: 0px">
          <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
            <p class="lead">Exercise {{ex_counter}} of {{number_exercises}}</p>
            <button class="btn btn-primary btn-lg w-100"  @click="nextExercise()"><strong>Next</strong> <i class="bi bi-skip-forward" style="margin-left: 5mm;"></i></button>
            <button v-if="show_results" class="btn btn-outline-warning btn-lg w-100 mt-4" @click="goToResults()">See your results</button>
          </div>
        </div>
      </div>
    </div>
      
  </template>
  <script>
  import {getData, getUserDataDir, updateUserData} from '@/firebase/index'
  import {save_pre_computed_results} from '@/utils/results/data_save_utils'
  import {logActivity} from '@/media_input/utils'
  import audioImg from '@/assets/images/audio_logo.png'
  import { useTTS } from '@/stores/tts'
  import {BV_Points} from '@/utils/bv_points'
  import { Modal } from 'bootstrap'
  import {find_words_containing_letters} from '@/media_input/words'
  import {startConfetti} from '@/common_js/confetti'

  export default {
    name: 'Antonym',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    data(){
      return {
        rand_letter:[],
        rand_letter_created_words:[],
        rand_words:[],
        levels_address:'Levels/Letter_word',
        number_hints:0,

        answer:'',
        placeholder_text: ' _ _ _',
        result_message: '',
        instruct_msg:'',
        selected_btn_id: 0,
        btn_display:[],
        letter_display:[],
        total_number_levels:1,
        max_level:false,
        level_up_counter:0,
        level_up_target:3,
        ex_level:'',
        ex_format:'single',
        displayOptNum:3,
        correct_answer:'',
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        nextExerciseTimeout: null,
        button_check: true,
        letter_press:false,
        correct_streak: 0,
        ex_counter: this.exForm.current_exercise_index + 1,
        pair_colours:['blue','blue','orange','orange','purple','purple'],
        total_press_counter:0,
        selected_pairs:[{'selected_letter_id':'','selected_word_id':''},{'selected_letter_id':'','selected_word_id':''},{'selected_letter_id':'','selected_word_id':''}],
        set_id:0,

        number_exercises: this.exForm.total_all_type_exercises,

        difficulty: 'easy',//unused?
        modifiers: {'easy':'e','medium':'m','hard':'h'},      
        revealed_letters:0,
        past_exercises: [],
        your_answer_txt: 'Your answer',
        failed_already: false,
        selected_antonym:'',
        hint_antonym:'',

        level_up_message:'',
        msg_colour:'',
        first_exercise: false,
        show_results: false,
        audioImg:audioImg,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points()
      }
    },
    created() {
      const _this = this
  

      _this.first_exercise = !_this.exForm.has_results
      _this.show_results = !_this.first_exercise

      logActivity( 'accessed', 'letter_word_match')
  
      _this.loadDifficulty()
    },
    beforeUnmount()
    {
      this.bv_tts.cancel()
    },
    methods: {
  
        select_button(id)
        {
          console.debug('select_button ' + id)

          this.result_message = ''

            if(this.ex_format === 'single')
            {
                this.button_check = false
                // for(let i = 0; i<this.displayOptNum;i++)
                // {
                //     this.btn_display[i] = 'card card-body';
                // }
                // this.btn_display[id] = 'card card-body custom-card_blue';
                this.selected_btn_id = id
                // this.bv_tts.say(this.rand_words[id].word)
                // this.$forceUpdate();
                this.answer_check()
            }
            else if(this.ex_format === 'match')
            {
                this.bv_tts.say(this.rand_words[id].word)
                if(this.total_press_counter == 1)
                {

                    if(this.selected_pairs[this.set_id].selected_letter_id !=='')
                    {
                        // console.log('selected_word_id = '+this.selected_pairs[set_id].selected_word_id+' selecred letetr id= '+this.selected_pairs[set_id].selected_letter_id)

                        // let letter_idx = this.selected_pairs[set_id].selected_letter_id
                        // this.letter_display[letter_idx] = 'card card-body';
                        // this.$forceUpdate();
                        // this.selected_pairs[set_id].selected_letter_id =''
                        this.selected_pairs[this.set_id].selected_word_id = id;


                        this.answer_check();
                        this.total_press_counter = 0;
                    }
                    else
                    {

                        this.result_message = 'You need to find a letter to match with the word selected.'
                        this.msg_colour = 'fontSize:24px;color:red;white-space: pre-wrap'
                        this.bv_tts.say(this.result_message)
                    }
                }
                else
                {
                    this.btn_display[id] = 'card card-body custom-card_blue';
                    this.selected_pairs[this.set_id].selected_word_id = id;
                    this.total_press_counter = 1;

                }
                

                
                // this.bv_tts.say(this.rand_words[id].word)

                // if(this.rand_words[id].pressed == false)
                // {
                //     this.rand_words[id].pressed = true
                // }
                // else
                // {
                    
                // }
                // console.log('selected_word_id = '+this.selected_pairs[set_id].selected_word_id+' selecred letetr id= '+this.selected_pairs[set_id].selected_letter_id)
                // this.btn_display[id] = 'card card-body custom-card_'+this.pair_colours[this.total_press_counter];
                // this.total_press_counter = this.total_press_counter +1;

            }
            
        },
        select_letter(id)
        {
          this.result_message = ''

          console.debug('select_letter ' + id)
            this.bv_tts.say(this.rand_letter[id].letter)
            if(this.total_press_counter == 1)
            {
                if(this.selected_pairs[this.set_id].selected_word_id !=='')
                {
                    // console.log('selected_word_id = '+this.selected_pairs[set_id].selected_word_id+' selecred letetr id= '+this.selected_pairs[set_id].selected_letter_id)

                    // let letter_idx = this.selected_pairs[set_id].selected_letter_id
                    // this.letter_display[letter_idx] = 'card card-body';
                    // this.$forceUpdate();
                    // this.selected_pairs[set_id].selected_letter_id =''
                    this.selected_pairs[this.set_id].selected_letter_id = id;

                    this.answer_check();
                    this.total_press_counter = 0;
                }
                else
                {
                    this.result_message = 'You need to find a word to match with the letter selected.'
                    this.msg_colour = 'fontSize:24px;color:red;white-space: pre-wrap'
                    this.bv_tts.say(this.result_message)
                }
            }
            else
            {
                this.letter_display[id] = 'card card-body custom-card_blue';
                this.selected_pairs[this.set_id].selected_letter_id = id;
                this.total_press_counter = 1;

            }
            // if(this.total_press_counter>=this.pair_colours.length)
            // {
            //     this.total_press_counter = 0;
            // }
            
            // let set_id = Math.floor(this.total_press_counter/2);
            // this.selected_pairs[set_id].selected_letter_id = id
            // this.bv_tts.say(this.rand_letter[id].letter)

            // if(this.rand_letter[id].pressed == false)
            // {
            //     this.rand_letter[id].pressed = true
            // }
            // else
            // {
            //     if(this.selected_pairs[set_id].selected_word_id !='')
            //     {
            //         console.log('selected_word_id = '+this.selected_pairs[set_id].selected_word_id+' selecred letetr id= '+this.selected_pairs[set_id].selected_letter_id)

            //         let word_idx = this.selected_pairs[set_id].selected_word_id
            //         this.btn_display[word_idx] = 'card card-body';
            //         this.$forceUpdate();
            //         this.selected_pairs[set_id].selected_word_id =''
            //     }
            // }
            // console.log('selected_word_id = '+this.selected_pairs[set_id].selected_word_id+' selecred letetr id= '+this.selected_pairs[set_id].selected_letter_id)

            // this.letter_display[id] = 'card card-body custom-card_'+this.pair_colours[this.total_press_counter];

            // this.total_press_counter = this.total_press_counter +1;

                // this.rand_letter[id].pressed = true;
            // }
            // else
            // {
            //     this.letter_display[id] = 'card card-body';
            // }
        },
    //   auto_answer_checker()
    //   {
    //     const _this = this
  
    //     if(_this.answer.toLowerCase()==_this.selected_antonym.toLowerCase() && _this.button_check)
    //     {
    //       if(!_this.failed_already)
    //       {
    //         _this.correct_counter = _this.correct_counter+1
    //       }
  
    //       console.debug('_this.correct_counter = ' + _this.correct_counter)
    //       _this.button_check = false
    //       _this.result_message = "Congrats! You got the right word"
    //       _this.msg_colour = "color:green;fontSize:20px;";
    //       _this.correct_streak = _this.correct_streak+1
   
  
    //       if(_this.correct_streak>=4)
    //       {
    //         if(_this.difficulty=='easy')
    //         {
    //           _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
    //           let level_up_pop = new Modal(document.getElementById('level_up_pop'));
    //           level_up_pop.show()
  
    //           _this.difficulty='medium'
    //           _this.correct_streak = 0
    //           _this.past_exercises = []
    //         }
    //         else if(_this.difficulty=='medium')
    //         {
    //           _this.past_exercises = []
  
    //           _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
    //           let level_up_pop = new Modal(document.getElementById('level_up_pop'));
    //           level_up_pop.show()
  
    //           _this.correct_streak = 0
  
    //           _this.difficulty='hard'
    //         }
    //       }
  
    //       updateUserData(_this.levels_address,{Level:_this.difficulty,Streak:_this.correct_streak})
  
    //     }
    //   },
      answer_check()
      {

          
        const _this = this
        if(_this.ex_format === 'single')
        {
            if(_this.button_check == false)
            {
                if(_this.rand_words[_this.selected_btn_id].word == _this.correct_answer)
                {
                    this.result_message = 'Well done, you did it!'
                    this.msg_colour = 'fontSize:24px;color:green;white-space: pre-wrap'
                    this.btn_display[this.selected_btn_id] = "card card-body custom-card_green"
                    startConfetti(this)
                    //this.$forceUpdate()

                    if(this.results.correct==0)
                    {
                      this.bv_tts.cancel()

                      this.bv_tts.say(this.result_message)

                    }

                    this.nextExerciseTimeout = setTimeout(() => {
                        this.nextExercise()
                    }, 2600);


                    if(this.first_attempt)
                    {

                      this.results.correct = this.results.correct + 1
                      this.results.total = this.results.total + 1
                      this.result_response(1)


                    }

                    this.first_attempt = false

                }
                else
                {

                    this.result_message = 'Oops.. not correct this time. Try again!'
                    this.msg_colour = 'fontSize:24px;color:red;white-space: pre-wrap'


                    let curr_button_id = this.selected_btn_id


                    this.btn_display[curr_button_id] = "card card-body custom-card_red"
                    setTimeout(() => {
                        this.btn_display[curr_button_id] = "card card-body"
                    }, 2000);
                    //this.$forceUpdate()
                    if(this.first_attempt)
                    {
                      this.bv_tts.say(this.result_message)

                      this.results.incorrect = this.results.incorrect + 1
                      this.results.total = this.results.total + 1
                      this.result_response(-1)


                    }

                    this.first_attempt = false
                }
            }
            else
            {
                _this.result_message = "Please select a word first. "
                _this.msg_colour = "color:red;fontSize:20px;";
            }
        }
        else if(_this.ex_format === 'match')
        {
            // for(let i in _this.selected_pairs)
            // {
                let letter_id = _this.selected_pairs[this.set_id].selected_letter_id
                let word_id = _this.selected_pairs[this.set_id].selected_word_id

                if(_this.rand_letter[letter_id].word == _this.rand_words[word_id].word)
                {
                    this.btn_display[word_id] = "card card-body custom-card_green"
                    this.letter_display[letter_id] = "card card-body custom-card_green"

                    
                    this.result_message = 'Well done, you did it!'
                    this.msg_colour = 'fontSize:24px;color:green;white-space: pre-wrap'

                    // if(this.set_id==0)
                    // {
                    //   //this.bv_tts.cancel()

                    //   this.bv_tts.say(this.result_message)
                    // }

                    if(this.set_id>1)
                    {
                        if(this.results.total==0)
                        {
                          this.results.correct = this.results.correct + 1
                          this.results.total = this.results.total + 1
                          this.result_response(1)


                        }

                        if(!this.show_level_up_message)
                        {
                            

                          this.set_id = 0
                          startConfetti(this)
                          this.nextExerciseTimeout = setTimeout(() => {
                              this.nextExercise()
                          }, 2600);

                        }
                        else
                        {

                          this.levelUpPopup()

                        }

                    }
                    else
                    {
                        this.set_id = this.set_id +1;
                    }
                    
                }
                else
                {
                    this.result_message = 'Oops.. not correct this time. Try again!'
                    this.msg_colour = 'fontSize:24px;color:red;white-space: pre-wrap'
                    this.btn_display[word_id] = "card card-body custom-card_red"
                    this.letter_display[letter_id] = "card card-body custom-card_red"
                    this.selected_pairs[this.set_id].selected_letter_id = ''
                    this.selected_pairs[this.set_id].selected_word_id = ''

                    setTimeout(() => {
                        this.btn_display[word_id] = "card card-body"
                        this.letter_display[letter_id] = "card card-body"
                    }, 1500);


                    this.$forceUpdate()
                    if(this.results.incorrect==0)
                    {
                      this.bv_tts.say(this.result_message)

                      this.results.incorrect = this.results.incorrect + 1
                      this.results.total = this.results.total + 1
                      this.result_response(-1)


                    }

                }
            // }
        }
        // if(_this.button_check==false)
        // {
        //   if(_this.correct_streak>=4)
        //   {
        //     if(_this.difficulty=='easy')
        //     {
        //       _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
        //       _this.difficulty='medium'
        //       _this.correct_streak = 0
        //       _this.past_exercises = []
        //     }
        //     else if(_this.difficulty=='medium')
        //     {
        //       _this.past_exercises = []
        //       _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
        //       _this.correct_streak = 0
  
        //       _this.difficulty='hard'
        //     }
        //   }
        // }
        // else
        // {
        //   if(_this.answer.toLowerCase()==_this.selected_antonym.toLowerCase())
        //   {
        //     _this.button_check = false
        //     _this.result_message = "Congrats! You got the right word"
        //     _this.correct_streak = _this.correct_streak+1
        //     _this.msg_colour = "color:green;fontSize:20px;";
        //   }
        //   else
        //   {
        //     if(!_this.failed_already)
        //     {
        //       _this.incorrect_counter = _this.incorrect_counter+1
        //     }
        //     _this.failed_already = true
        //     _this.correct_streak = _this.correct_streak-1
        //     _this.result_message = "This wasn't the word we were expecting. Try again (you can click 'Reveal Letter' for a tip) "
        //     _this.msg_colour = "color:red;fontSize:20px;";
        //   }
        // }
  
        // updateUserData(_this.levels_address,{Level:_this.difficulty, Streak:_this.correct_streak})
      },
      result_response(increment)
      {
        let level_down_target = 0-this.level_up_target
        this.level_up_counter = this.level_up_counter + increment

        if(this.max_level)
        {
          this.current_level = this.total_number_levels
        }

        let new_level = this.current_level


        if(this.level_up_counter >= this.level_up_target && this.current_level<this.total_number_levels)
        {
            this.level_up_counter = 0
            new_level = new_level + 1

            if (this.nextExerciseTimeout) {
                window.clearTimeout(this.nextExerciseTimeout); // will do nothing if no timeout with id is present
            }

            if(this.ex_format==='single')
            {

              this.levelUpPopup()
  

            }
            else
            {
              this.show_level_up_message = true
            }

            
        }
        else if(this.level_up_counter <= level_down_target)
        {
          this.level_up_counter = 0
          new_level = new_level -1
          if(new_level<1)
          {
            new_level = 1
          }
        }

        updateUserData(this.levels_address,{current_level:new_level,level_up_counter:this.level_up_counter})

        
      },
      levelUpPopup()
      {
        this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
        let level_up_pop = new Modal(document.getElementById('level_up_pop'));
        level_up_pop.show()
      
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()
        _this.$emit('nextExercise');
      },
      goToResults()
      {
        this.saveResults()
        
        let goToResultsData = {curr_ex_done:false}
        this.$emit('goToResults', goToResultsData);
      },
      saveResults() //to change
      {
        const _this = this
  
        if(this.results.correct!=0 || this.results.incorrect!=0)
        {
          let total = _this.results.correct+_this.results.incorrect
          let all_results = {Correct:_this.results.correct,Incorrect:_this.results.incorrect,Total:total}
  
          let plot_results = {Correct:_this.results.correct,Incorrect:_this.results.incorrect}
        
          let results = {all_results:all_results, plot_results:plot_results}
  
          var letter_word_matchData = {resultsTablePre: {exercise_type: 'Letter Word Match', exercise: 'Letter Word Match', results: results, audio_address:''}}
  
          save_pre_computed_results(_this.exForm,_this.$firebase,letter_word_matchData)
  
          _this.bv_points.add(10)
  
          _this.$emit('logExerciseResults', letter_word_matchData);
        }
      },
      loadDifficulty()
        {

            let _this = this
            let address = _this.levels_address

            let do_once = true

            getUserDataDir(address, function (err, result) {

                if(do_once)
                {
                  do_once = false
                  
                  let current_level = result.child('current_level').val()
                  let lvl_up_counter = result.child('level_up_counter').val()



                  if(current_level==undefined || lvl_up_counter==undefined)
                  {
                    current_level = 1
                    lvl_up_counter = 0
                    updateUserData(address,{current_level:current_level,level_up_counter:lvl_up_counter})
                  }


                  _this.current_level = current_level
                  // _this.current_level = 2;//for debugging
                console.debug('this.current_level = ' + current_level)
                
                  _this.level_up_counter = lvl_up_counter
                  _this.loadSettings()
                }

            })
        },
        loadSettings()
        {
            let _this = this
            // this.current_question_id = 0;
            let diff_ref = _this.$firebase.database().ref('Exercises').child('Settings').child('Letter_word')
            var do_once = true;
            getData(diff_ref , function (err, result) {

                if (do_once) 
                {

                    let settings = result.val()
                    _this.total_number_levels = Object.keys(settings).length + 1


                    console.debug('_this.total_number_levels = ' + _this.total_number_levels)

                    if(_this.current_level==_this.total_number_levels)
                    {
                      _this.max_level = true

                      _this.current_level = Math.ceil(Math.random()*(_this.total_number_levels-1))

                    }

                    console.log('_this.max_level = ' + _this.max_level)






                    let level_key = 'level'+_this.current_level

          
                    if(!(level_key in settings))
                    {
                      _this.current_level = 1
                      level_key = 'level'+_this.current_level

                    }
                    let temp_ex_level = settings[level_key]
                    _this.ex_level = _this.findResources(temp_ex_level)

                    console.debug('diff_level = '+_this.ex_level)
                    // console.debug(' !diff_level = '+_this.current_setup.word_diff)

                    // console.debug('keys = ' + Object.keys(_this.current_setup))


                    
                    do_once = false;
                    
                    _this.initialiser()
                }

            });
        },
        findResources(keywords)
        {
          let _this = this
          let output = ''
          if(keywords == 'simple_conso' || keywords == 'med_conso')
          {
            output = keywords
            _this.ex_format = 'single'
            _this.displayOptNum = 3
            _this.level_up_target = 3
          }
          else if(keywords == 'simple_conso_multi')
          {
            output = 'simple_conso'
            _this.ex_format = 'match'
            _this.displayOptNum = 3
            _this.level_up_target = 3
          }
          else if(keywords == 'med_conso_multi')
          {
            output = 'med_conso'
            _this.ex_format = 'match'
            _this.displayOptNum = 3
            _this.level_up_target = 3
          }
          else
          {
            console.log('unknow settings = '+keywords)
          }
          return output
        },
        initialiser()
        {
            let _this = this
            let simple_conso_arr = ['p','b','t','d','g','m','n','s','h','l','f','k']
            let med_conso_arr = ['p','b','t','d','g','m','n','v','s','h','l','r','z','sh','ch','th','f','y','k','w']

            for(let i = 0; i<_this.displayOptNum;i++)
            {
                this.btn_display[i] = 'card card-body';
                this.letter_display[i] = 'card card-body';
            }
            _this.button_check = true
            let selected_letters = []
            if(_this.ex_level == 'simple_conso')
            {
                selected_letters = _this.randomSelection(simple_conso_arr,_this.displayOptNum);
            }
            else if(_this.ex_level == 'med_conso')
            {
                selected_letters = _this.randomSelection(med_conso_arr,_this.displayOptNum);

            }
            else
            {
                console.log('unknow consonant list')
            }

            // let rand_letter_to_create_words = _this.randomSelection(_this.rand_letter,_this.displayOptNum)
            _this.rand_letter_created_words = [];
            _this.rand_letter = []
            // _this.rand_letter_ids = _this.randomSelection([0,1,2],_this.displayOptNum)
            for(let i in selected_letters)
            {
                let target_letter = selected_letters[i]

                let letter_position='WI'
                let size_conditions={'min':3,'max':6}

                let word = find_words_containing_letters(1,target_letter,letter_position,size_conditions)

                console.log('target_letter = ' + target_letter)
                console.log('word = ' + word[0])

                _this.rand_letter_created_words.push(word[0])
                _this.rand_letter.push({'letter':selected_letters[i], 'word':word[0], 'pressed':false})
            }
            _this.correct_answer = _this.rand_letter[0].word

            let temp_rand_words = _this.randomSelection(_this.rand_letter_created_words,_this.displayOptNum)
            _this.rand_words = []
            for(let n in temp_rand_words)
            {
                _this.rand_words.push({'word':temp_rand_words[n],'pressed':false})
            }
            // console.log('rand_creation = '+_this.rand_letter_created_words+' rand_words = '+_this.rand_words)

            if(_this.ex_format == 'single')
            {
                _this.instruct_msg = "Click the word starting with the following letter:"
            }
            else if(_this.ex_format == 'match')
            {
                _this.instruct_msg = "Match each word with their starting letters";
                _this.total_press_counter = 0;
                _this.set_id = 0

            }
            else
            {
                console.log('unknow format')
            }

            this.bv_tts.say(_this.instruct_msg)
        },
        randomSelection(arr,num_select)
        {
            let output = []
            let temp_arr = [...arr]
            for(let i = 0; i<num_select;i++)
            {
            let rand_ids = Math.floor(Math.random()*temp_arr.length)
            // console.log('temp arr -= '+temp_arr+' id = '+rand_ids)
            output.push(temp_arr[rand_ids])
            temp_arr.splice(rand_ids,1)
            }
            return output
        },
    //   getDifficulty()
    //   {
    //     const _this = this
  
    //     let address = _this.levels_address
  
    //     let do_once = true
  
    //     getUserDataDir(address, function (err, result) {
  
    //       if(do_once)
    //       {
    //         do_once = false
  
    //         let difficulty = result.child('Level').val()
    //         let correct_streak = result.child('Streak').val()
  
  
    //         if(difficulty==undefined || difficulty==null)
    //         {
    //           difficulty = 'easy'
    //           correct_streak = 0
  
    //           updateUserData(address, {Level:difficulty, Streak:correct_streak})
    //           console.debug('creating level')
    //         }
  
    //         console.debug('difficulty = ' + difficulty)
  
    //         _this.difficulty = difficulty
    //         _this.correct_streak = correct_streak
  
    //         _this.setup(difficulty)
    //       }
    //     })
    //   },
    //   setup(difficulty)
    //   {
    //     const _this = this;
    //     _this.failed_already = false
  
    //     _this.first_exercise = !_this.exForm.has_results
    //     _this.show_results = !_this.first_exercise
    //     console.debug('_this.show_results = ' + _this.show_results)
    //     console.debug('_this.exForm.current_exercise_index = ' + _this.exForm.current_exercise_index)
  
    //     if(_this.ex_counter>_this.total_exercises)
    //     {
    //       _this.goToResults()
    //       return
    //     }
  
    //     _this.result_message = ''
    //     _this.answer = ''
    //     _this.button_check = true
  
    //     _this.revealed_letters = 0
    //     var do_once = true;
  
    //     let modifier = _this.modifiers[difficulty]      
      
    //     let targetDir = 'Exercises/General/Antonyms/'+modifier
  
    //     getData(targetDir, function (err, result) {
  
    //       if(do_once)
    //       {
    //         do_once = false
            
    //         let total_exercises = result.numChildren()
  
    //         let random_exercise = Math.floor(Math.random()*total_exercises)
  
  
    //         while(_this.past_exercises.includes(random_exercise))
    //         {
  
    //             random_exercise = Math.floor(Math.random()*total_exercises)
    //         }
  
    //         _this.past_exercises.push(random_exercise)
  
    //         console.debug('_this.past_exercises = ' + _this.past_exercises)
  
  
    //         _this.selected_word = result.child(random_exercise).child('word').val()
    //         _this.selected_antonym = result.child(random_exercise).child('antonym').val()
    //         _this.hint_antonym = ''
    //         _this.placeholder_text = ''
    //         _this.your_answer_txt = 'Type in your answer here (a ' + _this.selected_antonym.length + ' letter word)'
  
    //         for(let i=0;i<_this.selected_antonym.length;++i)
    //         {
    //           _this.hint_antonym = _this.hint_antonym + ' * '
    //           _this.placeholder_text = _this.placeholder_text + ' _'
    //         }
    //       }
  
    //     });  
    //   },
    //   revealLetter()
    //   {
    //     const _this = this
  
    //     _this.number_hints = _this.number_hints + 1
  
    //     _this.revealed_letters = _this.revealed_letters+1
  
    //     _this.hint_antonym = _this.selected_antonym.substring(0,_this.revealed_letters)
  
    //     _this.correct_streak = _this.correct_streak -0.5
  
    //     for(let i=0;i<_this.selected_antonym.length-_this.revealed_letters;++i)
    //     {
    //       _this.hint_antonym = _this.hint_antonym + ' * '
    //     }
    //   },
    },
  }
  </script>
  
  <style scoped>
  
  .inline-block-child {
    display: inline-block;
  }
  
  .custom-card_blue{
      background-color: #b7d8f5;
      border-color: #b7d8f5;
      font-size: 20px;
  }
  .custom-card_purple{
      background-color: #c77ff1;      
      border-color: #c77ff1;
      font-size: 20px;
  }
  .custom-card_orange{
      background-color: #f4c974;
      border-color: #f4c974;
      font-size: 20px;
  }
  .custom-card_red{
      background-color: #ec6262;
      border-color: #ec6262;
      font-size: 20px;
  }
  .custom-card_green{
      background-color: #51df85;
      border-color: #51df85;
      font-size: 20px;
  }
  
  .empty_item:empty:before {
    content: "\200b";
  }
  
  
  </style>
  
  <!-- Result Description
  
  {
    "Antonym Exercise": {
      "Exercises": { //Need to remove this layer
        "General": { //Need to remove this layer
          "Antonyms": { //Need to remove this layer
            "exercises": { //Need to remove this layer
              "easy": { //Need to remove this layer
                "<key>": {
                  "audio_address": "<empty>",
                  "resultsTablePre": {
                    "audio_address": "<empty>",
                    "exercise": "Antonym Exercise",
                    "exercise_type": "Antonym Exercise",
                    "results": {
                      "all_results": {
                        "Correct": "<int>",
                        "Incorrect": "<int>",
                        "Number_Hints": "<int>",
                        "Total": "<int>"
                      },
                      "plot_results": {
                        "Correct": "<int>",
                        "Incorrect": "<int>",
                        "Total": "<int>"
                      }
                    }
                  },
                  "results_type": "pre_computed"
                }
              }
            }
          }
        }
      },
      "assessment_type": "USER_TRAINING",
      "plan_name": "Antonym Exercise"
    }
  }
  -->