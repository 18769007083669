<template>
    <div>
      <div class="row d-flex mb-3" style="min-height: 100vh;">
        <div class="col-xl-10 pe-0 d-flex flex-column">
  
          <div class="card card-body h-100">
  
            <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
            <div class="row">
              <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
            </div>
            <div class="row">
              <div class="col-md-6" >
                  <div class="row">
 
                  </div>
                  <div class="row">
                    <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
                  </div>
              </div>
              <div class="col-md-6">
                <div class="card card-body h-100">
                    <p class="lead" style="font-size: x-large;">
                          {{task_instructions}}
                      </p>

                      <div class="row align-items-center">
                        <div class="col-4">

                            <div :class="btn_display[0]" @click="select_button(0)">
                              <span class="read-text">{{1}} <img :src="audioImg" style="width:35px;;cursor: pointer;"></span>

                            </div>
                          </div>

                          <div class="col-4">
            

                            <div :class="btn_display[1]" @click="select_button(1)">
                              <span class="read-text">{{2}} <img :src="audioImg" style="width:35px;;cursor: pointer;"></span>
                            </div>
                          </div>

                          <div class="col-4">
             
                            <div :class="btn_display[2]" @click="select_button(2)">
                              <span class="read-text">{{3}}   <img :src="audioImg" style="width:35px;;cursor: pointer;"> </span>
                            </div>
                          </div>

                          <button v-if="selected_id!=-1" class="btn btn-primary btn-lg w-100 mt-2" @click="confirmSelection()">Confirm choice</button>
                          <div v-show="player!=undefined && player!=null" class="row" style="margin-top: 10%;" id="video_demo_instructions_div">
                            <div id="video_placeholder2"></div>
                          </div>

                          
                        </div>

                        <div class="modal fade" id="demo_video_pop" tabindex="-1">
                          <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Sound {{selected_id+1 }}</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div class="row justify-content-around">
                                  <div class="col-md-10">
                                    <div class="card card-body h-100">
                                      <div class="row" id="video_demo_instructions_div">
                                        <div id="video_placeholder"></div>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="col-md-2">

                                    <div class="card card-body h-100">


                                      <button class="btn btn-secondary w-100 mt-2"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                  </div>

                                </div>                  
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>

            


                  
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-xl-2" style="padding-left: 0px">
          <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
            <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
            <hr/>

            <p class="lead" font-size="medium">Task {{Math.min(task+1,n_tasks)}} of {{n_tasks}}</p>


            <button class="btn btn-info btn-lg w-100 mt-2" @click="nextTask()"> Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
            <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { Modal } from 'bootstrap'

  
  import {get_phonetic_representation_of_word} from '@/media_input/words'

  import {LevenshteinDistance} from '@/utils/text_distance_metrics'
  import homeplanImg from '@/assets/images/homeplan.png'
  import crossImg from '@/assets/images/cross.png'
  import doneIcon from '@/assets/images/icon_green_check.png'
  import SpeechRec from '@/media_input/SpeechRecognition'
  import audioImg from '@/assets/images/audio_logo.png'
  import loadingSVG from '@/assets/images/loader.svg'
  import {save_pre_computed_results} from '@/utils/results/data_save_utils'
  import words from '@/media_input/words'
  import constants from '@/utils/constants'
  
  import {logActivity} from '@/media_input/utils'
  import {startConfetti} from '@/common_js/confetti'
  import { useTTS } from '@/stores/tts'
  
  import {BV_Points} from '@/utils/bv_points'
  
  /*
  
  {
     "Address":[
        "/images/word_naming_easy/onion_1.jpg",
        "/images/word_naming_easy/onion_2.jpg"
     ],
     "category":"vegetables",
     "difficulty":"easy",
     "exercise_type":"name_a_picture_easy",
     "hints":[
        "This is a vegetable",
        "This vegetable has layers",
        "This vegetable grows in the ground"
     ],
     "instruct":"Press Record. Say the word that matches the picture below",
     "name":"onion;onions;red_onion;white_onion",
     "word_type":"noun",
     "wrong_words":"tomato;sweetcorn;pepper",
     "plan_name":"Picture naming",
     "assessment_type":"SLP_TRAINING",
     "curr_exercise_path":"Picture naming/16",
     "ex_path":"Picture naming/16",
     "today":"2023-07-11 17:20",
     "audioIns1":null,
     "imageAddress":[
        "/images/word_naming_easy/onion_1.jpg",
        "/images/word_naming_easy/onion_2.jpg"
     ],
     "videoAddress":null,
     "audioAddress":null,
     "audioIns2":null,
     "videoIns":null,
     "exercise_type":"name_a_picture_easy",
     "url":null,
     "url2image":null,
     "audioIns":null,
     "Ex_Description":"Press Record. Say the word that matches the picture below",
     "module_name":"Picture naming",
     "has_results":false,
     "current_exercise_index":0,
     "number_exercises":5
  }
  
  */
  export default {
      name: 'name_a_picture_easy',
      props: {
        exForm: {
          type: Object,
        },
      },
      data() {
        return {
          selected_id:-1,
          all_tasks_instructions:['Pick the word that matches the picture',
                                  'Select the first sound of the word',
                                  'What is another word starting with the same sound?',
                                  'Select the last sound of the word',
                                  'What does the word rhyme with?',
                                ],
          task_instructions:'',
          task_names:["Picture naming","First sound","Similar word","Last sounds","Rhyme"],
          no_result_input:true,
          task:-1,
          n_tasks:5,
          btn_display:[],
          sound_hints:false,
          recognition_setting: 0,
          client_demo: true,
          audioImg:audioImg,
          loadingSVG: loadingSVG,
          diffMarks:{
            0:{
              style:{color: '#000000'},
              label:'Auto'
            },
            1:{
              style:{color: '#000000'},
              label:'Manual'
            },
          },
          answer_text: '',
          answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
          imgSrc: loadingSVG,
          hints:['It is used to cook','It is made of metal'],
          answer_options:['Saucepan','Fridge'],
          first_exercise:true,
          chosen_image: '',
          target_word: '',
          single_target_word: '',
          player: null,
          phoneme_single_target_words:[null,null,null],
          target_img_number: '',
          instructions_msg: '',
          homeplanImg: homeplanImg,
          crossImg: crossImg,
          doneIcon: doneIcon,
          inputAcc:'',
          play_pause_text: 'Start',
          curr_exercise_number: 0,
          number_exercises:1,
          speech_rec: null,
          started: false,
          target_words: [],
          currentSentence: '',
          best_answer:'',
          results:{'correct':0,'incorrect':0,'total':0},
          results_by_task:{},
          uid: localStorage.getItem('uid'),
          userType: localStorage.getItem('userCategory'),
          region: localStorage.getItem('region'),
          resultsTable: [],
          half_word_url: null,
          half_word_urls:[null,null,null],
          retest_count: null,
          is_recording: false,
          congatulationsOnce: false,
          bv_tts: useTTS().tts_engine,
          bv_points: new BV_Points(),
        }
      },
      created() {
  
        const _this = this
  
        logActivity( 'accessed', 'picture_naming')
  
        // this.speech_rec = new SpeechRec(function(captions) {
        //   this.currentSentence = captions.original
        // }.bind(this))
  
        this.setup();
  
      },
      mounted()
      {
        const _this = this
  
        // if(_this.recognition_setting==0)
        // {
        //   let inital_text = 'Press Start. Name the picture.'
  
        //   _this.bv_tts.say(inital_text) 
        // }
      },
      beforeUnmount()
      {    
        this.bv_tts.cancel()
        // this.speech_rec.stop()

        if(this.player!=undefined && this.player!=null)
        {
          if(!this.player.paused)
          {
            this.player.pause();
            // this.player.stop();


          }
        }  
        

        this.player = null
  
        clearTimeout(window.countDownTimer)
        clearTimeout(window.countDownTimer2)
      },
      computed: {
  
        currentSentence_found: function () {
          if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
          {
  
            let curr_sentence = this.currentSentence.toString().toLowerCase()
  
            var regex = /[.,]/g;
  
            curr_sentence = curr_sentence.replace(regex, '');
  
            let words = curr_sentence.split(' ')
  
            console.debug('words = ' + words)
  
            for(let i in words)
            {
              let found_something = false
  
              let minimum_distance = 100
              let best_answer = null
  
              let word = words[i]
  
              console.debug('word = ' + word)
  
              for(let k in this.target_words)
              {
                let distance = LevenshteinDistance(this.target_words[k],word)
  
                distance = distance/Math.max(this.target_words[k].length,1)
  
                if(distance<minimum_distance && distance<0.25)
                {
                  best_answer = this.target_words[k]
                  this.stop_recording()
                  found_something = true
                }
              }
              if(best_answer!=null)
              {   
                if (this.congatulationsOnce == false) {
                    startConfetti(this);
                    this.congatulationsOnce = true;
                }
                this.found_correct_word(best_answer)
              }
              if(!found_something)
              {
                this.found_target_word = 'false'
              }
              else
              {
                return curr_sentence
  
              }
            }
            return curr_sentence
          }
  
          return ''
  
        },
      },
      methods: 
      {
        saveResults()
        {
          const _this = this
  
          if(this.results['correct']!=0 || _this.results['total']!=0)
          {
            let all_results = {results_by_task:this.results_by_task,Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1}
  
            let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}
  
            let results = {all_results:all_results, plot_results:plot_results}
  

            let category = _this.exForm.category

            if(category==undefined)
            {
              category='NA'
            }
            var word_naming_data = {resultsTablePre: {exercise_type: 'PCA', exercise: 'PCA', results: results, audio_address:'',word:_this.single_target_word,category:category,word_type:_this.exForm.word_type,difficulty:_this.exForm.difficulty}}
  
  
            save_pre_computed_results(_this.exForm, _this.$firebase, word_naming_data)
  
            _this.bv_points.add(10)
  
            _this.$emit('logExerciseResults', word_naming_data);
          }
        },
        nextTask()
        {


          if(this.player!=undefined && this.player!=null)
          {

            if(!this.player.paused)
            {


              this.player.pause();


            }


            this.player=undefined
          }
          

          this.$forceUpdate()


          this.setupTask()

        },
        nextExercise()
        {
          const _this = this;
          _this.saveResults()
  
          _this.$emit('nextExercise');
        },
        goToResults()
        {
          const _this = this
  
          _this.saveResults()
          let goToResultsData = {curr_ex_done:false}
          _this.$emit('goToResults', goToResultsData);
        },
        listenBeginningSound(index)
        {
          const _this = this
          _this.sound_hints = true
  
          this.bv_tts.cancel()
  

          {
            _this.listenPhoneme(index)
          }
        },
        listenEndSound(index)
        {
          const _this = this
          _this.sound_hints = true
  
          this.bv_tts.cancel()
  
  
      
          _this.listenPhoneme(index)
          
        },
        select_button(id)
        {

          for(let i = 0; i<3;i++)
          {
              this.btn_display[i] = 'card card-body';
          }

          if(this.selected_id==id)
          {
            this.selected_id = -1

          }
          else
          {
            this.selected_id = id
            this.btn_display[id] = 'card card-body custom-card_blue';
            this.listenFunction(id);

          }

          this.$forceUpdate();

        },
        confirmSelection()
        {

          if(this.shuffled_array[this.selected_id]==0)
          {
            this.correct_answer()

          }
          else
          {
            this.wrong_answer()

          }
        


        },
        correct_answer()
        {
          startConfetti(this);


          const _this = this



          _this.answer_text = 'Correct'
          _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'

          if(this.no_result_input)
          {
            this.no_result_input = false
            
            _this.results['total'] = _this.results['total'] + 1

            _this.results['correct'] = _this.results['correct'] + 1

            this.results_by_task[this.task_names[this.task]] = {'correct':1,'incorrect':0,'total':1}

            

          }


          this.btn_display[this.selected_id] = 'card card-body custom-card_green';


          this.$forceUpdate();

          this.nextTask()

        


        },
        wrong_answer()
        {
          const _this = this



          _this.answer_text = 'Not quite right'
          _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'


          if(this.no_result_input)
          {
            this.no_result_input = false
            
            _this.results['total'] = _this.results['total'] + 1

            _this.results['incorrect'] = _this.results['incorrect'] + 1

            this.results_by_task[this.task_names[this.task]] = {'correct':0,'incorrect':1,'total':1}



          }
          this.btn_display[this.selected_id] = 'card card-body custom-card_red';


          this.$forceUpdate();


        },
        listenFunction(id)
        {

          if(this.task==0)
          {

            this.bv_tts.say(this.answer_options[id])
          }
          else if(this.task==1)
          {
            this.listenBeginningSound(id)
          }
          else if(this.task==2)
          {
            this.bv_tts.say(this.answer_options[id])
          }
          else if(this.task==3)
          {
            this.listenEndSound(id)
          }
          else if(this.task==4)
          {
            this.bv_tts.say(this.answer_options[id])

          }

        },
        listenPhoneme(index,end_beginning='b')
        {
          console.debug('index = ' + index)
          const _this = this

          console.debug('_this.phoneme_single_target_words[index] = ' + _this.phoneme_single_target_words[index])
          let phoneme = _this.phoneme_single_target_words[index]

          console.debug('_this.phoneme_single_target_words[index] = ' + _this.phoneme_single_target_words[index])

          if(end_beginning=='e')
          {
            phoneme = _this.phoneme_single_target_words[index][_this.phoneme_single_target_words[index].length-2]

          }

          console.debug('phoneme = ' + phoneme)
  
          console.debug('here2')

          let simple_representation_phoneme = ''

          if(phoneme=='i')
          {
            phoneme='ɪ'
          }
          for(let i in constants.phonemeDisplayDictionary)
          {
            if(constants.phonemeDisplayDictionary[i]==phoneme)
            {
              simple_representation_phoneme = i
              break
            }
          }
          if(simple_representation_phoneme!='')
          {
            let video_file_location = '/BV/video_instructions/articulation_sound_only/'+simple_representation_phoneme+'.mp4'
            // let audio_file_location = 'audios/phoneme_pronunciation_new/'+phoneme+'.mp4'
            console.debug(video_file_location)
            this.playVideo(video_file_location)
          }
        },
        get_half_word_urls(word,index)
        {
          let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'
  
          const _this = this;
          const storage = _this.$firebase.storage();
  
          storage.ref(audio_file_location).getDownloadURL()
          .then((url) => {
            _this.half_word_urls[index] = url
            console.debug('Found half word url!!')
          })
          .catch((error) => {
            console.debug('error ' + error )
          });
        },
        playVideo(video_file_location)
        {
          const _this = this;
          const storage = _this.$firebase.storage();
  
          storage.ref(video_file_location).getDownloadURL()
          .then((url) => {
            _this.runVideo(url);
          })
          .catch((error) => {
            console.debug('error ' + error )
          });      
        },
        
        runVideo(url) {
          const _this = this

          document.getElementById('video_placeholder2').innerHTML='<video  id="movie" src="'+url+'" width="100%" controls autoplay> </video>';
          this.player =document.getElementById('movie');

          this.$forceUpdate()

          this.player.load();

          // let demo_video_pop = new Modal(document.getElementById('demo_video_pop'));
          // demo_video_pop.show()


          // console.debug('here3')
  
          // console.debug('url = ' + url)
          // document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="380px" width="600px" controls autoplay> </video>';
          // this.player =document.getElementById('movie');
          // this.player.load();

          // document.getElementById('demo_video_pop').addEventListener('hidden.bs.modal', function (event) {
                

          //   if(this.player!=undefined && this.player!=null)
          //   {
          //     if(!this.player.paused)
          //     {
          //       this.player.pause();

          //       this.player.stop()

          //     }
          //   }  
          //   // do something...
          // })

          
        },
        setup()
        {
          let _this = this;
  
          _this.answer_text = '';
  
          _this.first_exercise = !_this.exForm.has_results
  
  
          let client_demo = _this.exForm.client_demo
  
  
          if(client_demo!=undefined && client_demo!=null)
          {
              _this.client_demo = client_demo
          }
          
          console.debug('client_demo= '+_this.client_demo);

          for(let k in _this.exForm)
          {
            console.debug(k)
          }
  
          console.debug('_this.exForm.name = ' + _this.exForm.name)
          console.debug('_this.exForm.Address = ' + _this.exForm.Address)

          let img_address = _this.exForm.Address[Math.floor(Math.random() * _this.exForm.Address.length)]

          _this.target_word = _this.exForm.name
          _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
          _this.total_exercises = _this.exForm.total_all_type_exercises
          _this.retest_count = null


          _this.target_word = _this.target_word.toLowerCase()

        
  
          _this.target_words = _this.target_word.split(';')
          _this.single_target_word = _this.target_words[0]

  
  
  
          let wrong_words = _this.exForm.wrong_words.toLowerCase()
          wrong_words = wrong_words.split(';')
  
          _this.correct_order_options = [_this.target_words[0],wrong_words[0],wrong_words[1]]

          _this.answer_options = this.correct_order_options

          for(let k in this.answer_options)
          {
            console.debug('word = ' + _this.answer_options[k])
            get_phonetic_representation_of_word(_this.answer_options[k]).then((phonetic)=>
            {
              if(phonetic!=undefined && phonetic!=null)
              {
                phonetic = phonetic.Phonetic
                console.debug('phonetic = ' + phonetic)

              _this.phoneme_single_target_words[k] = phonetic

              }
              else{
                console.debug('not found = ' +_this.answer_options[k] )
              }

            })

          }



          // _this.hints = [_this.exForm.hints[Math.floor(Math.random() * _this.exForm.hints.length)]]
  
          var storageRef = _this.$firebase.storage().ref();
          storageRef.child(img_address).getDownloadURL().then(function(url) {
              _this.imgSrc = url;
          }).catch(function(error) {
  
          });

          this.setupTask()
        },
        shuffleArray(array) {
            for (var i = array.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }

            return array
        },
        setupTask()
        {
          const _this = this

          this.no_result_input = true

          this.player = null

          this.task = this.task+1

          this.selected_id = -1

          if(this.task<this.all_tasks_instructions.length)
          {
            // this.task_instructions = this.all_tasks_instructions[this.task]
            

            this.all_tasks_instructions[1] = 'Select the first sound of the word ' + this.single_target_word
            this.all_tasks_instructions[2] = 'What is another word starting with the same sound as ' + this.single_target_word + '?'
            this.all_tasks_instructions[3] = 'Select the last sound of the word  ' + this.single_target_word
            this.all_tasks_instructions[4] = 'What does ' + this.single_target_word + ' rhyme with?'

            this.task_instructions = this.all_tasks_instructions[this.task]

            this.bv_tts.say(this.task_instructions)
          }
          else
          {
            this.nextExercise()
          }

          for(let i = 0; i<3;i++)
          {
              this.btn_display[i] = 'card card-body';
              // this.letter_display[i] = 'card card-body';
          }




          if(this.task==0)
          {
            // Pick the right word

            this.shuffled_array = [0,1,2]

            this.shuffled_array = this.shuffleArray(this.shuffled_array)

            let temp_array = [0,1,2]



            for(let k in this.answer_options)
            {

              temp_array[k] = this.answer_options[this.shuffled_array[k]]

            }

            this.answer_options = temp_array




          }
          else if(this.task==1)
          {
            // Select the first sound of the word

            this.original_phoneme_single_target_words = this.phoneme_single_target_words

            
            let target_phoneme = _this.original_phoneme_single_target_words[0][0]

            console.debug('target_phoneme = ' + target_phoneme)


            this.answer_options = this.find_phonemes_excluding_target_phoneme(target_phoneme)


            let two_character_phoneme = _this.original_phoneme_single_target_words[0].substring(0,2)

            let found_phoneme = false

            for(let i in constants.phonemeDisplayDictionary)
            {
              if(constants.phonemeDisplayDictionary[i]==two_character_phoneme)
              {
                found_phoneme = true
                break
              }
            }

            if(found_phoneme)
            {
              target_phoneme = two_character_phoneme

              this.answer_options[0] = target_phoneme

            }

            console.debug('this.answer_options1 = ' + this.answer_options)

  
            this.shuffled_array = [0,1,2]

            this.shuffled_array = this.shuffleArray(this.shuffled_array)

            let temp_array = [0,1,2]



            for(let k in this.answer_options)
            {

              temp_array[k] = this.answer_options[this.shuffled_array[k]]

            }

            this.phoneme_single_target_words = temp_array

            console.debug('this.phoneme_single_target_words = ' + this.phoneme_single_target_words)



            // this.shuffled_array = [0,1,2]

            // this.shuffled_array = this.shuffleArray(this.shuffled_array)

            // let temp_array = [0,1,2]


            // this.original_phoneme_single_target_words = this.phoneme_single_target_words

            // for(let k in this.phoneme_single_target_words)
            // {

            //   temp_array[k] = this.phoneme_single_target_words[this.shuffled_array[k]]

            // }

            // this.phoneme_single_target_words = temp_array




          }
          else if(this.task==2)
          {

            // What is another word starting with the same sound?
            let word_2_phoneme = []
            
            for(let k in words.word_2_phoneme)
            {
              word_2_phoneme.push(words.word_2_phoneme[k])
            }

            word_2_phoneme = this.shuffleArray(word_2_phoneme)

            console.debug('this.target_word = ' + this.single_target_word)

            let phoneme_representation = '/'+ this.original_phoneme_single_target_words[0] + '/'

            console.debug('phoneme_representation = ' + phoneme_representation)

            let incorrect_count = 0
            let correct_count = 0

            let target_n_characters = 5

            if(phoneme_representation!=undefined && phoneme_representation!=null)
            {

              this.answer_options = ['','','']

              let phoneme_representation_count = phoneme_representation.length

              let cut_phoneme_representation = phoneme_representation.substring(0, Math.min(phoneme_representation_count,3));


              for(let k in word_2_phoneme)
              {

                let curr_word = word_2_phoneme[k]

                if(cut_phoneme_representation.length<=curr_word.length && curr_word.length>=target_n_characters-1 && curr_word.length<=target_n_characters+3)
                {
                  let match = true

                  for(let p in cut_phoneme_representation)
                  { 
                    if(cut_phoneme_representation[p]!=curr_word[p])
                    {
                      match = false
                      break
                    }

                  }


                  if(correct_count==0 && match && phoneme_representation!=curr_word)
                  {
                    this.answer_options[0] = words.phoneme_2_word[curr_word]
                    correct_count = 1


                  }
                  else if(incorrect_count<2 && !match)
                  {
                    this.answer_options[1+incorrect_count] = words.phoneme_2_word[curr_word]

                    incorrect_count = incorrect_count+1

                  }

                  if(correct_count==1 && incorrect_count==2)
                  {
                    break;
                  }
                }


              }

            }

            this.shuffled_array = [0,1,2]

            this.shuffled_array = this.shuffleArray(this.shuffled_array)

            let temp_array = [0,1,2]



            for(let k in this.answer_options)
            {

              temp_array[k] = this.answer_options[this.shuffled_array[k]]

            }

            _this.answer_options = temp_array

            console.debug('this.answer_options = ' + this.answer_options)
      

  

          }
          else if(this.task==3)
          {

            // Select the last sound of the word
            console.debug('_this.original_phoneme_single_target_words[0]  ' + _this.original_phoneme_single_target_words[0])


            let target_phoneme = _this.original_phoneme_single_target_words[0][_this.original_phoneme_single_target_words[0].length-1]

            if(target_phoneme==':')
            {
              target_phoneme = _this.original_phoneme_single_target_words[0].substring(_this.original_phoneme_single_target_words[0].length-2, _this.original_phoneme_single_target_words[0].length);
            }


            this.answer_options = this.find_phonemes_excluding_target_phoneme(target_phoneme)

            console.debug('this.answer_options1 = ' + this.answer_options)

  
            this.shuffled_array = [0,1,2]

            this.shuffled_array = this.shuffleArray(this.shuffled_array)

            let temp_array = [0,1,2]



            for(let k in this.answer_options)
            {

              temp_array[k] = this.answer_options[this.shuffled_array[k]]

            }

            this.phoneme_single_target_words = temp_array

            console.debug('this.answer_options2 = ' + this.answer_options)




            // for(let k in _this.answer_options)
            // {
            //   console.debug('k = ' + k)

            //   console.debug('_this.answer_options[k] = ' + _this.answer_options[k])


            //   _this.phoneme_single_target_words[k] = words.word_2_phoneme[_this.answer_options[k]]

            //   console.debug('words.word_2_phoneme[_this.answer_options[k]] = ' + words.word_2_phoneme[_this.answer_options[k]])
            // }

            // console.debug('_this.phoneme_single_target_words = ' + _this.phoneme_single_target_words)

          }
          else if(this.task==4)
          {
            // What does the word rhyme with?

            let rhymes = this.exForm['rhymes']
            console.debug('rhymes = ' + rhymes)
            let not_rhymes = this.exForm['wrong_words']

            not_rhymes = not_rhymes.split(";")

            not_rhymes = this.shuffleArray(not_rhymes)

            let rhyme_options = [rhymes[Math.floor(Math.random() * rhymes.length)],not_rhymes[0],not_rhymes[1]]

            console.debug('rhyme_options = ' + rhyme_options)
            this.shuffled_array = [0,1,2]

            this.shuffled_array = this.shuffleArray(this.shuffled_array)

            let temp_array = [0,1,2]



            for(let k in this.answer_options)
            {

              temp_array[k] = rhyme_options[this.shuffled_array[k]]

            }

            

            this.answer_options = temp_array

            console.debug('this.answer_options = ' + this.answer_options)



          }

        },
        find_phonemes_excluding_target_phoneme(target_phoneme)
        {

          const _this = this

          let output = [target_phoneme]

          let count = 0

          let keys = Object.keys(constants.phonemeDisplayDictionary)

              
          while(output.length<3 && count<20)
          {

            count = count+1
            let random_key = keys[Math.floor(Math.random() * keys.length)]

            console.debug('random_key = ' + random_key)

            let random_phoneme = constants.phonemeDisplayDictionary[random_key]

            console.debug('random_phoneme = ' + random_phoneme)

            let new_phoneme = true

            for(let k in output)
            {
              if(random_phoneme==output[k])
              {
                new_phoneme = false
                break
              }
            }

            if(new_phoneme)
            {
              output.push(random_phoneme)
            }

          }

          return output



        },
        playPause()
        {
          let _this = this;
  
          _this.play_status = true;
          _this.resumeRecording()
          _this.results['total'] = 1
        },
        resumeRecording()
        {
          let _this = this;
          // recordAudioMedia()
          _this.is_recording = true;
          _this.congatulationsOnce = false;
          // _this.runTimer()
          _this.start_recording()
        },
        start_recording() {
          const _this = this
          this.speech_rec.start(this.target_words,false,this.region)
          this.started = true
          this.play_pause_text = 'Recording'
          console.debug('started')
  
          clearTimeout(window.countDownTimer)
  
          clearTimeout(window.countDownTimer2)
          
          window.countDownTimer2 = setTimeout(() => {
            _this.answer_text = 'It is recording now, name the picture out loud'
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }, 500)
            
          window.countDownTimer = setTimeout(() => {
            if(_this.play_status)
            {
              _this.started = false
              _this.play_pause_text = 'Start'
  
              _this.answer_text = "Oops we missed what you said! Press Start and try again"
              _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
            }
            _this.stop_recording()
          }, 45000)
        },
        stop_recording() {
          const _this = this
          _this.play_status = false;
          _this.currentSentence = ''
          _this.speech_rec.stop()
        },
        found_correct_word(curr_sentence)
        {
          const _this = this
  
          _this.results['total'] = 1
  
          _this.best_answer = curr_sentence
  
          _this.answer_text = 'Congratulations! You said the right word: ' + curr_sentence
          _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          _this.play_pause_text = 'Start'
  
  
          _this.results['correct'] = 1
          _this.results['incorrect'] = 0
  
          clearTimeout(window.countDownTimer)
          clearTimeout(window.countDownTimer2)
        },
        manualAnswer(is_correct)
        {
          const _this = this
          _this.results['total'] = 1
  
          if(is_correct)
          {
            // _this.answer_text = 'Congratulations! You said the right word: ' + found_word
            _this.results['correct'] = 1
            _this.results['incorrect'] = 0
          }
          else
          {
            _this.results['correct'] = 0
            _this.results['incorrect'] = 1
          }
          _this.nextExercise()
        },
        pause()
        {
          let _this = this;
          _this.play_status = false;
          _this.play_pause_text = 'Start'
        },
      }
  }
  </script>
  
  <style scoped>

.custom-card_blue{
      background-color: #b7d8f5;
      border-color: #b7d8f5;
      font-size: 20px;
  }
  .custom-card_purple{
      background-color: #c77ff1;      
      border-color: #c77ff1;
      font-size: 20px;
  }
  .custom-card_orange{
      background-color: #f4c974;
      border-color: #f4c974;
      font-size: 20px;
  }
  .custom-card_red{
      background-color: #ec6262;
      border-color: #ec6262;
      font-size: 20px;
  }
  .custom-card_green{
      background-color: #51df85;
      border-color: #51df85;
      font-size: 20px;
  }
  .custom-button-BV
  {
      color: white;
      background-color: #195e52;
      border-color: #195e52;
      height: 20mm;
      width: 35mm;
  }
  </style>
  
  <!-- Results Descriptions
  {
    "Name a picture": {
      "<key>": { 
        "audio_address": "<empty>",
        "resultsTablePre": {
          "audio_address": "<empty>",
          "category": "<string>",
          "difficulty": "<string>",
          "exercise": "Word Naming",
          "exercise_type": "Word Naming",
          "results": {
            "all_results": {
              "Correct": "<int>",
              "Identified_Answer": "string",
              "Incorrect": "<int>", //right now this is always 0, but we should improve
              "Show_Answer": "<bool>",
              "Show_Options": "<bool>",
              "Sound_Hints": "<bool>",
              "Total": "<int>" //number tries
            },
            "plot_results": {
              "Correct": "<int>",
              "Incorrect": "<int>"
            }
          },
          "word": "<target_word>",
          "word_type": "<string>" //noun, verb, adjective
        },
        "results_type": "pre_computed"
  
      },
      "assessment_type": "USER_TRAINING/SLP_TRAINING",
      "plan_name": "Name a picture"
    }
  } -->
  
  
  <!--
  {
    "Name a picture": { --decide the module name ID (this or 'Word Naming')
      "<key>": { --move under 'exercises'
        "audio_address": "<empty>", --not used remove
        "resultsTablePre": {
          "audio_address": "<empty>", --not used remove
          "category": "<string>",
          "difficulty": "<string>",
          "exercise": "Word Naming", --change to 'Name of picture'
          "exercise_type": "Word Naming", --change to 'Name a picture'
          "results": {
            "all_results": {
              "Correct": "<int>",
              "Identified_Answer": "string",
              "Incorrect": "<int>", //right now this is always 0, but we should improve
              "Show_Answer": "<bool>",
              "Show_Options": "<bool>",
              "Sound_Hints": "<bool>",
              "Total": "<int>" //number tries
            },
            "plot_results": {
              "Correct": "<int>",
              "Incorrect": "<int>"
            }
          },
          "word": "<target_word>",
          "word_type": "<string>" //noun, verb, adjective
        },
        "results_type": "pre_computed" --not used remove
  
      },
      "assessment_type": "USER_TRAINING/SLP_TRAINING",
      "plan_name": "Name a picture" --not used remove
    }
  } -->