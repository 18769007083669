<template>
  <div>
    <div class="card card-body mb-4 v-patient_interface-1">
      <div class="accordion" id="accordionTraining">
        <div class="accordion-item mb-3">
          <div class="accordion-header">
            <button 
              :class="['accordion-button', {'collapsed' : !isCollapse('voice')}]" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#voice_training_collapse" 
              aria-controls="voice_training_collapse" 
              @click="rememberCollapse('voice')">
              <h5 class="text-start" >Voice <img :src="Voice_training" style="width: 20px"/></h5>
            </button>
          </div>
          <div :class="['accordion-collapse collapse', {'show' : isCollapse('voice')}]" id="voice_training_collapse" data-bs-parent="#accordionTraining">
            <div class="accordion-body">
              <div class="row mb-4">
                <div class="row">
                  <div class="col-md-3">
                    <Warm_Up 
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"></Warm_Up>
                  </div>
                  <div class="col-md-3">
                    <Pitch 
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Pitch>
                  </div>
                  <div class="col-md-3">
                    <Phonation
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Phonation>
                  </div>
                  <div class="col-md-3">
                    <Words_n_phrases
                      section="words_n_phrases"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Words_n_phrases>
                  </div>
                </div>
                <div class="row mt-2">

                  <div class="col-md-3">
                    <Sentence_level
                      section="sentence_level"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Sentence_level>
                  </div>
                  <div class="col-md-3">
                    <Paragraph_level
                      section="paragraph_level"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Paragraph_level>
                  </div>
                  <!-- NOTE: removed because of controversy
                  <div class="col-md-3">
                    <Facial_Expressions
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      @module_configuration_change="module_configuration=$event"></Facial_Expressions>
                  </div>
                  -->
                  <div class="col-md-3">
                    <Singing 
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')"  
                      @selected_module="selected_module=$event"></Singing>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="m-2" style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <div class="accordion-item mb-3">
          <div class="accordion-header">
            <button 
              :class="['accordion-button', {'collapsed' : !isCollapse('speech')}]" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#speech_training_collapse"
              aria-controls="speech_training_collapse"
              @click="rememberCollapse('speech')">
              <h5 class="text-start">Speech <img :src="Speech_training" style="width: 20px"/></h5>
            </button>
          </div>
          <div :class="['accordion-collapse collapse', {'show': isCollapse('speech')}]" id="speech_training_collapse" data-bs-parent="#accordionTraining">
            <div class="accordion-body">
              <div class="row mb-4">
                <div class="row mt-2">
                  <div class="col-md-3">
                    <Speech_Articulation
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Speech_Articulation>
                  </div>
                  <div class="col-md-3">
                    <Word_Pairs
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Word_Pairs>
                  </div>
                  <div class="col-md-3">
                    <Words_n_phrases
                      section="speech_words_n_phrases"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Words_n_phrases>
                  </div>
                  <div class="col-md-3">
                    <Sentence_level
                      section="speech_sentence_level"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Sentence_level>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-3">
                    <Paragraph_level
                      section="speech_paragraph_level"
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event"
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Paragraph_level>
                  </div>
                  <div class="col-md-3">
                    <Speech_rate
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Speech_rate>
                  </div>
                  <!--
                  <div class="col-md-3">
                    <Facial_Muscles
                      @add_to_assignment_list="find($event, true, 'training')" 
                      @start_training="find($event, false, 'training')" 
                      @selected_module="selected_module=$event" 
                      :number_exercises="number_exercises" 
                      @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                      @module_configuration_change="module_configuration=$event"></Facial_Muscles>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="m-2" style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <div class="accordion-item mb-3">
          <div class="accordion-header">
            <button 
              :class="['accordion-button', {'collapsed' : !isCollapse('language')}]" 
              data-bs-toggle="collapse" 
              data-bs-target="#language_training_collapse"
              aria-controls="language_training_collapse"
              @click="rememberCollapse('language')">
              <h5 class="text-start" aria-controls="language_training_collapse">Language <img :src="Language_training" style="width: 20px"/></h5>
            </button>
          </div>
          <div :class="['accordion-collapse collapse', {'show': isCollapse('language')}]" id="language_training_collapse" data-bs-parent="#accordionTraining">
            <div class="accordion-body">
              <div class="row mb-4">
                <div class="col-md-3">
                  <Expressive_Semantics
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                    @module_configuration_change="module_configuration=$event"></Expressive_Semantics>
                </div>
                <div class="col-md-3">
                  <Visual_Audio_Comprehension 
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Visual_Audio_Comprehension>
                </div>
                <div class="col-md-3">
                  <Reading 
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"
                    @module_configuration_change="module_configuration=$event"></Reading>
                </div>
                <div class="col-md-3">
                  <Writing_Spelling
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Writing_Spelling>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="m-2" style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <div class="accordion-item mb-3">
          <div class="accordion-header">
            <button 
              :class="['accordion-button', {'collapsed' : !isCollapse('cognitive')}]" 
              data-bs-toggle="collapse" 
              data-bs-target="#cognitive_training_collapse"
              aria-controls="cognitive_training_collapse"
              @click="rememberCollapse('cognitive')">
              <h5 class="text-start" aria-controls="cognitive_training_collapse">Cognitive <img :src="Cognitive_training" style="width: 20px"/></h5>
            </button>
          </div>
          <div :class="['accordion-collapse collapse', {'show': isCollapse('cognitive')}]" id="cognitive_training_collapse" data-bs-parent="#accordionTraining">
            <div class="accordion-body">
              <div class="row mb-4">
                <div class="col-md-3">
                  <Memory 
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Memory>
                </div>
                <div class="col-md-3">
                  <Life_Skills
                    @add_to_assignment_list="find($event, true, 'training')" 
                    @start_training="find($event, false, 'training')" 
                    @selected_module="selected_module=$event" 
                    :number_exercises="number_exercises" 
                    @onNumberExercisesChange="number_exercises=$event;onNumberExercisesChange()"></Life_Skills>
                </div>
              </div>
              <!--
              <div class="col-md-3">
                <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='braingame'">
                  <strong>Brain game</strong>
                </div>
                <div class="modal fade" id="braingame_modal" tabindex="-1" aria-labelledby="braingame_modal" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Brain game</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">

                        <div class="row" style="height:20vh">
                          <div class="col-4 border-3 border-success border-end">
                            <button :class="['btn','mb-2', 'w-100', selected_module == 'braingame'?'btn-primary':'btn-light']" type="button" @click="selected_module='braingame'">
                              Brain game
                            </button>
                          </div>
                          <div class="col-8">
                            <div v-show="selected_module == 'braingame'">
                              <p class="lead">Brain game</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-success me-2" @click="find(selected_module, false, 'training')" >Add to home training list</button>
                        <button type="button" class="btn btn-primary" @click="find(selected_module, false, 'training')" >Start training</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->

            </div>
          </div>
        </div>
        <hr class="m-2" style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <div class="accordion-item mb-3 v-patient_interface-2">
          <div class="accordion-header">
            <button 
              :class="['accordion-button', {'collapsed' : !isCollapse('custom')}]" 
              data-bs-toggle="collapse" 
              data-bs-target="#custom_training_collapse"
              aria-controls="custom_training_collapse"
              @click="rememberCollapse('custom')">
              <h5 class="text-start" aria-controls="custom_training_collapse">Custom Training <img :src="homework" style="width: 20px"/></h5>
            </button>
          </div>
          <div :class="['accordion-collapse collapse', {'show': isCollapse('custom')}]" id="custom_training_collapse" data-bs-parent="#accordionTraining">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer; background-color: rebeccapurple" data-bs-toggle="modal" data-bs-target="#create_custom_modal">
                    <strong style="color: white">Create Custom Module</strong>
                  </div>
                </div>
                <div class="col-md-6" v-if="custom_modules.length > 0">
                  <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#custom_modal" @click="selected_module=''">
                    <strong>My Modules</strong>
                  </div>
                </div>
              </div>
              <div class="modal fade" id="custom_modal" tabindex="-1" aria-labelledby="custom_modal" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Modules</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                      <div class="row" style="height:50vh">
                        <div class="col-4 border-3 border-success border-end">
                          <button v-for="(module, index) in custom_modules_sorted" :key="index" :class="['btn','mb-2', 'w-100', selected_module == module.label?'btn-primary':'btn-light']" type="button" @click="selectCustomModule(module)">
                            {{module.label}}
                          </button>
                        </div>
                        <div class="col-8">
                          <div v-for="(module, index) in custom_modules" :key="index" v-show="selected_module == module.label">
                            <p class="lead">Practise custom module</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="find(selected_module, true, 'custom')" data-bs-dismiss="modal">Add to home training list</button>
                      <button type="button" class="btn btn-primary" @click="find(selected_module, false, 'custom')" data-bs-dismiss="modal">Start training</button>
                      <button type="button" class="btn btn-warning me-2" @click="modifyCustomModule_(selected_module)" data-bs-dismiss="modal">Modify module</button>
                      <button type="button" class="btn btn-danger me-2" @click="deleteCustomModule_(selected_module)">Delete module</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal fade" id="create_custom_modal" tabindex="-1" aria-labelledby="create_custom_modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create custom module</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="lead">Please choose an exercise template below</p>
              <button class="btn btn-primary me-2" @click="gotoActivityCustomModule()" data-bs-dismiss="modal" aria-label="Close">Real life activities</button>
              <button class="btn btn-warning" @click="gotoVoiceAndSpeechCustomModule()" data-bs-dismiss="modal" aria-label="Close">Voice and speech</button>
          </div>
        </div>
      </div>
    </div>

    <Assignment_list class="v-patient_interface-3" :table_assignment_list="table_assignment_list" list_type="homework" @startModule="startClinicModule" @assignment_list_updated="load_assignment_list"></Assignment_list>
    
  </div>
</template>
<script>

///////////////////////////////////////////////////////////////////////////////////////////////////////

import Voice_training from '@/assets/images/dashboard/slt/training/collapse/voice_training.png'
import Speech_training from '@/assets/images/dashboard/slt/training/collapse/speech_training.png'
import Language_training from '@/assets/images/dashboard/slt/training/collapse/language_training.png'
import Custom_training from '@/assets/images/dashboard/slt/training/collapse/custom_training.png'
import Cognitive_training from '@/assets/images/dashboard/slt/training/collapse/cognitive_training.png'
import homework from '@/assets/images/dashboard/slt/training/collapse/homework.png'

import { getData, updateData } from '@/firebase/index'

import { Modal } from 'bootstrap'

import { getTmpAssignmentList } from '@/utils/assignment/common.js'

import { useToast } from "vue-toastification";
import Assignment_list from "../common/assignment_list.vue"

import Warm_Up from "./sections/voice/warm_up.vue"
import Singing from "./sections/voice/singing.vue"
import Pitch from "./sections/voice/pitch.vue"
import Phonation from "./sections/voice/phonation.vue"
import Facial_Expressions from "./sections/voice/facial_expressions.vue"

import Speech_Articulation from "./sections/speech/articulation.vue"
import Word_Pairs from "./sections/speech/word_pairs.vue"
import Words_n_phrases from "./sections/speech/words_n_phrases.vue"
import Sentence_level from "./sections/speech/sentence_level.vue"
import Paragraph_level from "./sections/speech/paragraph_level.vue"
import Speech_rate from "./sections/speech/speech_rate.vue"
import Facial_Muscles from "./sections/speech/facial_muscles.vue"

import Expressive_Semantics from "./sections/language/expressive_semantics.vue"
import Visual_Audio_Comprehension from "./sections/language/visual_audio_comprehension.vue"
import Reading from "./sections/language/reading.vue"
import Writing_Spelling from "./sections/language/writing_spelling.vue"

import Memory from "./sections/cognitive/memory.vue"
import Life_Skills from "./sections/cognitive/life_skills.vue"

export default {
  name: 'slt_training',
  components: {
    Assignment_list, 
    Warm_Up, Singing, Pitch, Phonation, Facial_Expressions,
    
    Speech_Articulation, Word_Pairs, Words_n_phrases, Sentence_level, Paragraph_level, Speech_rate, Facial_Muscles,

    Expressive_Semantics, Visual_Audio_Comprehension, Reading, Writing_Spelling,

    Memory, Life_Skills
  },
  props: { },
  data(){
    return {

      training_modules_database: 'BaseUserPlan/Training_modules',

      homework: homework,
      Voice_training: Voice_training,
      Speech_training: Speech_training,
      Language_training: Language_training,
      Custom_training: Custom_training,
      Cognitive_training: Cognitive_training,

      module_min_max_exercises: {
        'Karaoke': {"default": 0, "max": 0},//NA
        'MayMeMyMoeMum': {"default": 0, "max": 0},//NA
        'BreathingInstruction': {"default": 0, "max": 0},//NA
        'Pitch_instructions': {"default": 5, "max": 15},
        'Pitch_Normal_2_High': {"default": 5, "max": 15},
        'Pitch_Normal_2_Low': {"default": 5, "max": 15},
        'Pitch_Normal_2_High_No_Assistance': {"default": 5, "max": 15},
        'Pitch_Normal_2_Low_No_Assistance': {"default": 5, "max": 15},
        'Loudness&Phonation15': {"default": 5, "max": 15},
        'WordArticulation': {"default": 5, "max": 30},
        'FunctionalSentences': {"default": 5, "max": 15},
        'DailyNewsTitleInstructions': {"default": 5, "max": 15},
        'ReadingComprehensionDailyNewsMid': {"default": 5, "max": 15},

        'PubQuiz': {"default": 5, "max": 15},
        'Speech_BackgroundNoise': {"default": 5, "max": 10},
        'DailyNewsLongInstructions': {"default": 5, "max": 15},
        'DailyNewsShortInstructions': {"default": 5, "max": 15},
        'FunFacts': {"default": 5, "max": 15},
        'ShortParagraph': {"default": 5, "max": 15},
        'ShortParagraphPhoneme': {"default": 5, "max": 15},

        'PictureDescription': {"default": 3, "max": 10},

        'Count_in_images': {"default": 5, "max": 15},

        'SpeakNumbers': {"default": 5, "max": 15},

        'TongueTwistersSelection': {"default": 3, "max": 5},
        'Jokes': {"default": 5, "max": 15},
        'pd_facial_expressions': {"default": 5, "max": 15},//NA
        'PhonemeArticulation': {"default": 5, "max": 15},
        'SinglePhonemeArticulation': {"default": 3, "max": 15},
        'MinimalPairs': {"default": 5, "max": 15},
        'DailyNewsPaceLong': {"default": 5, "max": 15},
        'stroke_facial_expressions': {"default": 5, "max": 15,"default_per_module":{"frown":2,"blow":2,"disgust":5,"excited":5,"eyebrows":2,"facial_say_p":2,"pout_lips":2,"puff_cheeks_both":5,"puff_cheeks_side":3,"smile":5,"smile_sides":2,"surprised":5}},//NA
        'stroke_oral_muscle': {"default": 5, "max": 15,"default_per_module":{"frown":2,"blow":2,"disgust":5,"excited":5,"eyebrows":2,"facial_say_p":2,"pout_lips":2,"puff_cheeks_both":5,"puff_cheeks_side":3,"smile":5,"smile_sides":2,"surprised":5}},//NA
        'AutoSpeech': {"default": 3, "max": 15},
        'WordFinding': {"default": 5, "max": 15},
        'WordCategory': {"default": 5, "max": 15},
        'CommonSayings': {"default": 10, "max": 15},
        'PCA': {"default": 5, "max": 15},
        'SpokenPCA': {"default": 5, "max": 15},

        'PictureNaming': {"default": 5, "max": 15},
        'SceneClick': {"default": 5, "max": 15},//NA
        'SceneDescription': {"default": 5, "max": 15},//NA
        'PositionExercises': {"default": 5, "max": 15},//NA
        'MatchingShapes': {"default": 5, "max": 5},
        'ListenMinimalPairs': {"default": 5, "max": 15},
        'WriteNumbers': {"default": 5, "max": 15},

        'WriteWordsAudio': {"default": 5, "max": 15},
        'CopyWord': {"default": 5, "max": 15},
        'CopyWordLetter': {"default": 1, "max": 15},
        'CopyWordSubject': {"default": 1, "max": 15},

        'WriteWordsPicture': {"default": 5, "max": 15},
        'WordSearch': {"default": 5, "max": 10},
        'Hangman': {"default": 3, "max": 10},
        'PaceInterest': {"default": 1, "max": 5},

        'ReadingComprehension': {"default": 1, "max": 1},

        'Orla': {"default": 4, "max": 7},
        'ReadingImages': {"default": 8, "max": 20},

        'ReadMenu': {"default": 4, "max": 7},
        'LetterWordMatch': {"default":5,"max":15},

        'AntonymExercises': {"default": 5, "max": 7},
        'TokenAssessment': {"default": 25, "max": 25},
        'ReversiblePassive': {"default": 3, "max": 3},
        'MemoryImage': {"default": 6, "max": 6},
        'StoryMemory': {"default": 5, "max": 5},
        'ClockGame': {"default": 5, "max": 7},
        'NumberSequence': {"default": 5, "max": 5},
        'MoneyRecognition': {"default": 5, "max": 8},
        'GiveDirections': {"default": 5, "max": 15},

      },

      client_uid: localStorage.getItem('client_uid'),
      uid: localStorage.getItem('uid'),

      module_configuration: {},

      module_type: "",
      curr_module: null,

      number_exercises: 5,
      
      pre_defined_module_data: null, //this is when the module has video instructions that need to be added on top of the exercises
      
      autoGenWords: [],

      table_assignment_list: [],
      start_object: 
      {
        module_path:'', 
        assessment_type: "SLP_TRAINING", 
        module_name: '', 
        current_exercise_index: 0,
      },

      form: 
      {
        name: 'temp',
        n_item:50,
        value:'',
        phoneme: null,
        day: null,
        changes_confirm: false,
        pre_loaded_exercises: null
      },
      
      //this is part of custom modules
      mod_options:[],

      selected_module: '',
      custom_modules: [],
      selected_module_object:null,

      custom_speech_modal:null,
      custom_activity_modal:null,
      show_custom_speech_modal: false,
    }
  },
  async created() {
    sessionStorage.setItem("view_mode", "training")
    
    this.load_assignment_list()

    this.getUserCustomModulesCascade();
  },
  mounted() {
  },
  computed: {
    custom_modules_sorted() {
      let sorted = this.custom_modules.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase()?1:-1)
      return sorted
    },

  },
  watch: {
    selected_module(newModule, oldModule) {
      if (newModule in this.module_min_max_exercises) {
        this.number_exercises = this.module_min_max_exercises[newModule]['default']
      }
    },
  },
  methods: {
    async load_assignment_list() {

      let general_package_lists = await Promise.all(getTmpAssignmentList(this.client_uid, 'homework'))
      this.table_assignment_list = general_package_lists[0].concat(general_package_lists[1])
      
    },
    isCollapse(name) {
      return (localStorage.getItem('training_collapse') === name)
    },
    rememberCollapse(name) {
      if (this.isCollapse(name)) {
        localStorage.setItem('training_collapse', "" )
      } else {
        localStorage.setItem('training_collapse', name )
      }
    },
    async onNumberExercisesChange() {
      let _this = this
      if (this.selected_module != '' && this.number_exercises > this.module_min_max_exercises[this.selected_module]['max']) {
        //alert(this.selected_module)
        //setTimeout(function() {
          
          const DEF_DELAY = 200;

          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
          }

          await sleep(DEF_DELAY);

          _this.number_exercises = _this.module_min_max_exercises[_this.selected_module]['max']
        //}, 1000);

        //alert("Exceeded number of available exercises. Please select a lower number of exercises")

      } else if (this.selected_module != '' && this.number_exercises <= 0 && this.module_min_max_exercises[this.selected_module]['default'] != 0) {
        //alert("Please select a higher number of exercises")
        //_this.number_exercises = 1
      } else {
        //_this.number_exercises = 1
        //this.number_exercises = this.number_exercises
      }

    },
    selectCustomModule(module)
    {
      this.selected_module=module.label
      this.selected_module_object = module
    },
    showCustomSpeechModal() {
      this.custom_speech_modal = new Modal(document.getElementById('custom_speech_modal'));
      this.custom_speech_modal.show();
    },
    showCustomActivityModal(){
      this.show_custom_speech_modal = true
      this.custom_activity_modal = new Modal(document.getElementById('custom_activity_modal'));
      this.custom_activity_modal.show();
    },
    hideCustomActivityModal() {
      this.custom_activity_modal.hide();
      this.getUserCustomModulesCascade()
      this.show_custom_speech_modal = false
    },
    hideCustomSpeechModal() {
      this.custom_speech_modal.hide();
      this.getUserCustomModulesCascade()
    },
    resetFormData()
    {
      this.form =
      {
        name: 'temp',
        n_item:50,
        value:'',
        phoneme: null,
        day: null,
        changes_confirm: false,
        pre_loaded_exercises: null
      }
    },
    modifyCustomModule_(name)
    {
      const _this = this
      if(name==undefined || name ==null || name==='')
      {
        alert('Select a module to modify')
        return
      }

      let module_address = _this.selected_module_object.value.module_address

      //ADDRESS LOCATION example:
      //module_address = Custom_Modules/l2tz8MOPrKOboiGGo4t7RRVhQiK2/Modules/CustomVoiceExercises/aaa training.vue:2303
      //
      /*
        "aaa": {
          "exercises": [
            {
              "address": "Exercises/user_exercises/l2tz8MOPrKOboiGGo4t7RRVhQiK2/Modules/SpeechModules/-NYX5bHafrESj8c8j8ux",
              "exercise_range": "single_exercise",
              "name": "vvv"
            },
            {
              "address": "Exercises/user_exercises/l2tz8MOPrKOboiGGo4t7RRVhQiK2/Modules/SpeechModules/-NYX5bHequUcOIK-PWpH",
              "exercise_range": "single_exercise",
              "name": "vvv"
            },
            {
              "address": "Exercises/user_exercises/l2tz8MOPrKOboiGGo4t7RRVhQiK2/Modules/SpeechModules/-NYX5bHhYdzflo_E8PUe",
              "exercise_range": "single_exercise",
              "name": "vvv"
            }
          ],
          "homework": true,
          "mod_name": "vvv"
        }


      */
      console.debug('module_address = ' + module_address)

      
      let subfolder = 'SpeechModules'

      if(module_address.includes('SpeechModules'))
      {
        subfolder = 'SpeechModules'
      }
      else if(module_address.includes('ActivityModules'))
      {
        subfolder = 'ActivityModules'
      } else {
        alert("Error")
        return
      }

      let user_modules_ref = _this.$firebase.database().ref(module_address);

      let do_once = true;
      getData(user_modules_ref, function (err, result) {
        if(do_once)
        {
          do_once = false;
          _this.parseCustomModule(result.val(), subfolder)
        }
      });

    },
    async parseCustomModule(db_module_data, type) //type is either Activity or SpeechModules
    {
      const _this = this

      for(let i in db_module_data)
      {
        console.debug('db_module_data['+i+'] = ' + db_module_data[i])
      }
      
      let all_exercise_addresses = []


      if('exercises' in db_module_data)
      {
        let db_exercises = db_module_data['exercises']

        for(let i in db_exercises)
        {

          let curr_db_exercise = db_exercises[i]

          let exercise_address = curr_db_exercise['address']

          all_exercise_addresses.push(exercise_address)

        }
      }
      else
      {
        for(let i in db_module_data)
        {
          if(i.includes('Module'))
          {
            let curr_db_exercise = db_module_data[i]

            let exercise_address = curr_db_exercise['address']

            all_exercise_addresses.push(exercise_address)
          }
        }
      }
      //console.debug('all_exercise_addresses = ' + all_exercise_addresses)


      if(all_exercise_addresses.length>0)
      {
        // for each exercise found we will need to grab the information
        // some info right now has to be the same for all the custom exercises in a grouping
        // these are: repeats, voicetype,speechtype, assist and instruct

        let promiseArray = []

        for(let n in all_exercise_addresses)
        {
          promiseArray.push(_this.getSingleExerciseData(all_exercise_addresses[n]))
        }
          
        let all_exercises_data = await Promise.all(promiseArray);
        //console.debug(all_exercises_data)
        
        let module_data_local = {
          name: '',
          keywords: '',
          instruct: '',
          n_item : 0,

          text : [],
          n_repeats : [],
          assist : [],
          Audio_Instructions : [],
          Video_Instructions : [],
          Video_Address : [],
          Audio_Address : [],
          voicetype : [],
          speechtype : [],
          capture_audio : '',
          checkbox : ''
        }

        module_data_local['name'] = db_module_data['mod_name']

        // finding the number of repeats by checking how many times the same exercise is repeating

        let trimmed_exercises = []

        for(let n in all_exercises_data)
        {

          let curr_n_repeats = 0
          let curr_exercise = all_exercises_data[n]

          if (!module_data_local.text.includes(curr_exercise.text)) {

            for(let n_j in all_exercises_data) {
              if(curr_exercise['text'] === all_exercises_data[n_j]['text'])
              {
                curr_n_repeats = curr_n_repeats + 1
              }
            }

            console.debug('n_repeats = ' + curr_n_repeats)
            module_data_local.text.push(curr_exercise.text)
            module_data_local.n_repeats.push(curr_n_repeats)

            trimmed_exercises.push(curr_exercise)
          }
        }

        module_data_local['n_item'] = trimmed_exercises.length
        //console.debug(trimmed_exercises)
        //console.debug(module_data_local)
        
        let tags_shared_by_all_exercises = ['instruct','voicetype','speechtype','assist','capture_audio','checkbox']

        for(let i in tags_shared_by_all_exercises)
        {
          if(tags_shared_by_all_exercises[i] in trimmed_exercises[0])
          {
            module_data_local[tags_shared_by_all_exercises[i]] = trimmed_exercises[0][tags_shared_by_all_exercises[i]]
          }
        }

        console.debug('module_data_local')
        console.debug(module_data_local)

        let null_default_tags = ['Audio_Instructions','Video_Instructions','Audio_Address','Video_Address', 'text']

        for(let k in null_default_tags)
        {
          module_data_local[null_default_tags[k]] = []
        }

        for(let n in trimmed_exercises)
        {
          let curr_exercise = trimmed_exercises[n]
          
          for(let k in null_default_tags)
          {
            if(null_default_tags[k] in curr_exercise)
            {
              module_data_local[null_default_tags[k]].push(curr_exercise[null_default_tags[k]])
            }
            else
            {
              module_data_local[null_default_tags[k]].push(null)
            }
          }
        }

        console.debug('type = ' + type)
        console.debug(module_data_local)

        if(type==='SpeechModules')
        {
          _this.$router.push({name: 'custom_voice_speech', query: { edit_mode: true, module_data: JSON.stringify(module_data_local)}})
        }
        else if(type==='ActivityModules')
        {
          _this.$router.push({name: 'custom_activity', query: { edit_mode: true, module_data: JSON.stringify(module_data_local)}})
        }

      }
    },
    gotoActivityCustomModule()
    {
      this.$router.push({'name': 'custom_activity'})
    },
    gotoVoiceAndSpeechCustomModule()
    {
      this.$router.push({'name': 'custom_voice_speech'})
    },
    async getSingleExerciseData(exercise_address)
    {
      const _this = this

      let exercise_data = new Promise(function(resolve)
      {
        let do_once = true

        let exercise_address_ref = _this.$firebase.database().ref(exercise_address);

        getData(exercise_address_ref , function (err, result) {
          if (do_once) {
            do_once = false
            exercise_data = result.val()
            resolve(exercise_data)
          }
        });
      })

      return exercise_data
    },
    deleteCustomModule_(name)
    {
      const _this = this

      if(name==undefined || name ==null || name==='') {
        alert('Select a module to delete')
        return
      }
      
      let user_modules_dir = _this.selected_module_object.value.module_address

      if(!user_modules_dir.includes(this.uid)) {
        return 
      }

      let user_modules_ref = _this.$firebase.database().ref(user_modules_dir);

      user_modules_ref.remove().then(function() {
        _this.getUserCustomModulesCascade();
        const toast = useToast()
        toast.success("Custom module deleted", {timeout: 5000})
        
      })
    },
    async addToAssignmentList(module_handle, module_type)
    {
      const _this = this
      
      let modifier_types = module_handle.child('modifier').val()

      console.debug('modifier_types = ' + modifier_types)

      _this.pre_defined_module_data = null

      if(modifier_types!=undefined && modifier_types!=null)
      {
        if('pre-defined' in modifier_types) {
          _this.pre_defined_module_data = modifier_types['pre-defined']
        }

        if(!('phoneme' in modifier_types)) {
          _this.module_configuration['phoneme'] = 'any'
        }
      }
      
      if(!module_handle.hasChild('exercises_location') && (modifier_types == undefined || modifier_types == null) && module_type === "training")
      {
        console.debug('Add to homework list - no modifier')
        let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;

        let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
        let push_key = tmp_slt_homework_list.push().key; 
        
        push_key = this.table_assignment_list.length + "__" + push_key // alphanumerical order is mandatory, so this is required
        
        let curr_module_data = module_handle.val()

        curr_module_data['modifier'] =  ''
        curr_module_data['number_exercises'] = _this.number_exercises
        if (_this.number_exercises > 0) {
          curr_module_data['exercises'] = curr_module_data['exercises'].slice(0, _this.number_exercises);
        } else {
          curr_module_data['exercises'] = curr_module_data['exercises'];
        }


        updateData(tmp_slt_homework_list.child(push_key), curr_module_data)
      } else { //doesn't have exercises_location - custom, word articulation moduleZ
        console.debug('modifier_types = ' + modifier_types);

        console.debug('saveNewPlan')

        let curr_module_data = _this.curr_module.val()

        if (_this.pre_defined_module_data != undefined && _this.pre_defined_module_data != null) {

          if ('exercises' in _this.pre_defined_module_data) {

            let temp_exercise_data = _this.pre_defined_module_data['exercises']

            if (curr_module_data['exercises'] == undefined) {
              curr_module_data['exercises'] = []
            }

            if (_this.selected_module==="PhonemeArticulation") {
              for(let k in temp_exercise_data) {
                temp_exercise_data[k]['address'] = temp_exercise_data[k]['address'] + _this.module_configuration['phoneme'].toUpperCase() 
              }
            }

            if (_this.selected_module==="SinglePhonemeArticulation") {
              for(let k in temp_exercise_data) {
                temp_exercise_data[k]['address'] = temp_exercise_data[k]['address'] + _this.module_configuration['phoneme'].toUpperCase() 
              }
            }


            curr_module_data['exercises'] = temp_exercise_data.concat(curr_module_data['exercises'])

          } else {
            _this.savePreDefinedDataToNewPlan()
          }

        }

        if(_this.selected_module.includes("FunFacts"))
        {
          _this.saveNewTextsPlan()
        } else if(_this.selected_module.includes("ShortParagraph")) {
          _this.saveNewParagraphPlan()
        } else if(_this.selected_module.includes("ShortParagraphPhoneme")) {
          _this.saveNewParagraphPlan()
        }
         else if(_this.selected_module.includes("PubQuiz")) {
          _this.saveNewPubQuizPlan()
        } else if(_this.selected_module === "ReadingComprehension") {
          _this.saveNewReadingComprehensionPlan()
        } else if(_this.selected_module === "PaceInterest") {
          _this.saveNewPaceInterestPlan()
        }  
        
        else if(_this.selected_module.includes("WordArticulation") && (_this.autoGenWords.length>0 || _this.module_configuration['words_form'].text.length>0)) {
          _this.saveNewWordsPlan(module_handle)
        } else {
          console.debug('here 6')

          let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
          let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
          let push_key = tmp_slt_homework_list.push().key;

          push_key = _this.table_assignment_list.length + "__" + push_key 

          curr_module_data['modifier'] =  ''


          if('address' in curr_module_data)
          {


            if(_this.curr_module.val().exercises_location && _this.selected_module.includes("News") && _this.module_configuration['topic']) {
              

              curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['topic']


              curr_module_data['modifier'] = _this.module_configuration['topic']

              if(curr_module_data['exercises'] == undefined) {
                curr_module_data['exercises'] = []
              }

              curr_module_data['exercises'].push({'address':curr_module_data['address'],'exercise_range':'multi_exercises','number_exercises':_this.number_exercises})

              delete curr_module_data['address']
            }
          }

          if('address' in curr_module_data)
          {
            if(_this.selected_module.includes("pd_facial_expressions") && _this.module_configuration.hasOwnProperty('pd_facial_expression') && _this.curr_module.val().exercises_location)
            {
              curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['pd_facial_expression']

              curr_module_data['modifier'] = _this.module_configuration['pd_facial_expression']
            }
          }

          if('address' in curr_module_data)
          {
            if(_this.selected_module.includes("stroke_facial_expressions") && _this.module_configuration.hasOwnProperty('stroke_facial_expression') && _this.curr_module.val().exercises_location)
            {
              curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['stroke_facial_expression']

              curr_module_data['modifier'] = _this.module_configuration['stroke_facial_expression']

              _this.number_exercises = _this.module_min_max_exercises["stroke_facial_expressions"]["default_per_module"][_this.module_configuration['stroke_facial_expression']]
            }
          }

          if('address' in curr_module_data)
          {
            if(_this.selected_module.includes("stroke_oral_muscle") && _this.module_configuration.hasOwnProperty('stroke_oral_muscle') && _this.curr_module.val().exercises_location)
            {
              curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['stroke_oral_muscle']

              curr_module_data['modifier'] = _this.module_configuration['stroke_oral_muscle']

              _this.number_exercises = _this.module_min_max_exercises["stroke_oral_muscle"]["default_per_module"][_this.module_configuration['stroke_oral_muscle']]
            }
          }

          if(_this.selected_module==="PhonemeArticulation" && _this.module_configuration['phoneme'] )
          {
            curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['phoneme']

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['phoneme']

            curr_module_data['modifier'] = _this.module_configuration['phoneme']

            if(curr_module_data['exercises'] == undefined) {
              curr_module_data['exercises'] = []
            }

            curr_module_data['exercises'].push({'address':curr_module_data['address'],'exercise_range':'multi_exercises','number_exercises':_this.number_exercises})

            delete curr_module_data['address']
          }

          if(_this.selected_module==="SinglePhonemeArticulation" && _this.module_configuration['phoneme'] )
          {
            curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['phoneme'].toUpperCase()

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['phoneme']

            curr_module_data['modifier'] = _this.module_configuration['phoneme']

            if(curr_module_data['exercises'] == undefined) {
              curr_module_data['exercises'] = []
            }

            curr_module_data['exercises'].push({'address':curr_module_data['address'],'exercise_range':'multi_exercises','number_exercises':_this.number_exercises})

            delete curr_module_data['address']
          }


          if(_this.selected_module.includes("TongueTwistersSelection") && _this.module_configuration.hasOwnProperty('tongue_twister_phoneme') && _this.module_configuration['tongue_twister_phoneme'] != 'any')
          {
            curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['tongue_twister_phoneme']

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['tongue_twister_phoneme']

            curr_module_data['modifier'] = _this.module_configuration['tongue_twister_phoneme']
          }

          
          if(_this.selected_module.includes("MinimalPairs") && _this.module_configuration.hasOwnProperty('sound_change') &&  _this.module_configuration['sound_change'] != 'any')
          {
            curr_module_data['address'] = curr_module_data['address'] + _this.module_configuration['sound_change']

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['sound_change']

            curr_module_data['modifier'] = _this.module_configuration['sound_change']

            curr_module_data['group_type'] = 'random_assortment'

            curr_module_data['plan_type'] = 'random_assortment'


            curr_module_data['modifier'] = _this.module_configuration['sound_change']
          }

          if(_this.selected_module.includes("WordCategory") && _this.module_configuration.hasOwnProperty('word_category_type'))
          {
            curr_module_data['address'] = curr_module_data['address'] + '/' + _this.module_configuration['word_category_type']

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['word_category_type']

            curr_module_data['modifier'] = _this.module_configuration['word_category_type']
          }

          if(_this.selected_module.includes("WordFinding") && _this.module_configuration.hasOwnProperty('difficulty'))
          {
            curr_module_data['address'] = curr_module_data['address'] + '/' + _this.module_configuration['difficulty']

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + _this.module_configuration['difficulty']

            curr_module_data['modifier'] = _this.module_configuration['difficulty']
          }

          if(_this.selected_module.includes("AutoSpeech") )
          {
            curr_module_data['address'] = curr_module_data['address'] + '/' + 'Easy'

            curr_module_data['exercises_location'] = curr_module_data['exercises_location'] + 'Easy'

            curr_module_data['modifier'] = 'Easy'
          }

          curr_module_data['current_exercise_index'] = 0    

          if (_this.number_exercises != undefined && module_type != 'custom') {
            curr_module_data['number_exercises'] = _this.number_exercises
          } 

          if('Module1' in curr_module_data) {
            curr_module_data['Module1']['number_exercises'] = _this.form.n_item
          }

          console.debug('target_dir  = ' + target_dir)
          console.debug('push_key = ' + push_key)
          
          updateData(tmp_slt_homework_list.child(push_key), curr_module_data)
        }
      }

      const toast = useToast()

      let assignment_name = module_handle.child('mod_name').val();
      toast.success("Assignment " + assignment_name + " added to the home training list\nScroll down to send the assignment list.", {timeout: 5000})
      
      _this.resetFormData()

      let general_package_lists = await Promise.all(getTmpAssignmentList(this.client_uid, 'homework'))
      this.table_assignment_list = general_package_lists[0].concat(general_package_lists[1])
    },
    savePreDefinedDataToNewPlan()
    {
      console.debug('savePreDefinedDataToNewPlan')
      const _this = this

      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;

      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key

      if(_this.module_configuration['phoneme'] && _this.module_configuration['phoneme']!='any')
      {
        _this.pre_defined_module_data['Module0']['address'] = _this.pre_defined_module_data['Module0']['address'] + _this.module_configuration['phoneme'].toUpperCase()

        console.debug("_this.pre_defined_module_data['Module0']['address'] = " + this.pre_defined_module_data['Module0']['address'])

        updateData(tmp_slt_homework_list.child(push_key),{modifier:_this.module_configuration['phoneme']})
      }

      updateData(tmp_slt_homework_list.child(push_key),_this.pre_defined_module_data)
    },
    saveNewWordsPlan(module_handle)
    {
      const _this  = this;

      console.debug('save new words plan')      
      let uid = localStorage.getItem('uid');

      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('SpeechModules');

      if(_this.autoGenWords.length>0)
      {
        _this.module_configuration['words_form'].text = _this.autoGenWords;
      }
      
      let random_string = [...Array(6)].map(() => Math.random().toString(36)[2]).join('');


      _this.form.mod_name =   module_handle.child('mod_name').val();

      console.debug('_this.form.mod_name = ' + _this.form.mod_name)
      
      if(this.form.mod_name==undefined || this.form.mod_name==null) {
        this.form.mod_name = ''
      }

      if(_this.form.mod_name=='')
      {
        for(let i in _this.module_configuration['words_form'].text) {
          _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['words_form'].text[i] + '/'
        }
      }
      
      _this.form.n_item = _this.number_exercises
      
      console.debug('modified ex name = '+_this.form.mod_name);
      console.debug('module_page_ref = ' + module_page_ref)

      updateData(module_page_ref.child(_this.form.mod_name + _this.module_configuration['phoneme'] + '_' + random_string), _this.module_configuration['words_form']);

      let form_temp = {
        name: _this.form.mod_name,
        instruct: _this.module_configuration['words_form'].instruct,
        text: _this.form.mod_name,
        random_words: true,
        phoneme:_this.module_configuration['phoneme'],
        syllable_conditions:_this.module_configuration['syllable_conditions'],
        phoneme_position:_this.module_configuration['phoneme_position'],
        assist:['spm_live'],
        voicetype: ['spm'],
        speechtype:['intelligibility'],
      };


      for(let n in _this.module_configuration['words_form'].text)
      {
        let text_temp = _this.module_configuration['words_form'].text[n];
        
        form_temp.text = text_temp;
        form_temp.name = _this.form.mod_name+'-' + n;
        console.debug('text form= ' + form_temp.text);
        let folderName = 'item' + n;
        
        updateData(module_page_ref.child(_this.form.mod_name + _this.module_configuration['phoneme'] + '_' + random_string).child('txt_items').child(folderName),form_temp);
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      console.debug('target_dir = ' + target_dir)
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name:_this.form.mod_name, keywords:_this.module_configuration['words_form'].keywords});
      for(let n=0; n<_this.module_configuration['words_form'].text.length; n++)
      {
        let user_addr = '/Custom_Exercises/'+uid+'/Modules/SpeechModules/'+_this.form.mod_name+ _this.module_configuration['phoneme'] + '_' + random_string + '/txt_items/item'+n;
        let modx = {address:user_addr, category:'Speech', id:n, name:_this.form.mod_name+'-'+n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n),modx); 
      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 

      curr_module_data['modifier'] =  'Words with ' + _this.module_configuration['phoneme'] + ' sound'      
      curr_module_data['number_exercises'] = _this.number_exercises
      curr_module_data['mod_name'] = 'Words with ' + _this.module_configuration['phoneme'] + ' sound'  

      for(let i in curr_module_data)
      {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key), curr_module_data)
    },
    saveNewParagraphPlan()
    {
      const _this  = this;

      console.debug('save new paragraph plan')      
      let uid = localStorage.getItem('uid');

   
      console.debug('short_paragraph_form.mod_name = ' + _this.module_configuration['short_paragraph_form'].mod_name)

      _this.module_configuration['short_paragraph_form'].mod_name = _this.module_configuration['short_paragraph_form'].topic

      _this.number_exercises = _this.module_configuration['short_paragraph_form'].text.length
      
      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('SpeechModules');
      
      console.debug('modified ex name = '+_this.module_configuration['short_paragraph_form'].topic);
      console.debug('module_page_ref = ' + module_page_ref)

      updateData(module_page_ref.child(_this.module_configuration['short_paragraph_form'].topic), _this.module_configuration['short_paragraph_form']);
      // alert(_this.module_configuration['short_paragraph_form'].mod_name)
      // console.debug('form text all= ',_this.module_configuration['short_paragraph_form'].text[1]);
      let form_temp = {
        name: _this.module_configuration['short_paragraph_form'].mod_name,
        instruct: _this.module_configuration['short_paragraph_form'].instruct,
        text: _this.module_configuration['short_paragraph_form'].mod_name,
        assist:['spm_live'],
        voicetype: ['spm'],
        speechtype:['sentencegibility'],
      };

      for(let n=0; n<_this.module_configuration['short_paragraph_form'].text.length; n++)
      {
        // form_temp.text = [];
        let text_temp = _this.module_configuration['short_paragraph_form'].text[n];

        form_temp.text = text_temp;
        form_temp.name = _this.module_configuration['short_paragraph_form'].mod_name + '-' + n;
        console.debug('text form= ' + form_temp.text);
        let folderName = 'item' + n;
        updateData(module_page_ref.child(_this.module_configuration['short_paragraph_form'].topic).child('txt_items').child(folderName), form_temp);
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      console.debug('target_dir = ' + target_dir)
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name:_this.module_configuration['short_paragraph_form'].mod_name, keywords:_this.module_configuration['short_paragraph_form'].topic});
      for(let n=0; n<_this.module_configuration['short_paragraph_form'].text.length; n++)
      {
        let user_addr = '/Custom_Exercises/'+uid+'/Modules/SpeechModules/' + _this.module_configuration['short_paragraph_form'].topic + '/txt_items/item' + n;
        let modx = {address:user_addr, category:'Speech', id:n, name:_this.module_configuration['short_paragraph_form'].mod_name + '-' + n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n),modx); 
      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 



      if('paragraphphoneme' in this.module_configuration['short_paragraph_form'])
      {
        curr_module_data['modifier'] = this.module_configuration['short_paragraph_form'].paragraphphoneme

      }

      curr_module_data['number_exercises'] = _this.module_configuration['short_paragraph_form'].text.length

      curr_module_data['mod_name'] = 'Short paragraphs about ' + _this.module_configuration['short_paragraph_form'].topic  

      for(let i in curr_module_data)
      {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key),curr_module_data)
    },
    saveNewReadingComprehensionPlan()
    {
      const _this  = this;

      console.debug('save reading comprehension plan')      
      let uid = localStorage.getItem('uid');

      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('LanguageModules');


      _this.number_exercises = 1
      
      console.debug('modified ex name = '+ _this.module_configuration['topic']);
      console.debug('module_page_ref = ' + module_page_ref)

      let reading_comprehension_form = {
        name: _this.module_configuration['topic'],
        keywords:'',
        instruct: 'Read the text and answer as many questions as you can',
        data:[],
        n_item:1,
      }

      updateData(module_page_ref.child(_this.module_configuration['topic']), reading_comprehension_form);
      // alert(_this.short_paragraph_form.mod_name)
      // console.debug('form text all= ',_this.short_paragraph_form.text[1]);
      let form_temp = {
        name: _this.module_configuration['topic'],
        instruct: reading_comprehension_form.instruct,
        text: _this.module_configuration['topic'],
        exercise_type:'reading_comprehension'
      };

      for(let n=0; n< _this.module_configuration['data'].length;n++)
      {
        // form_temp.text = [];
        let data = _this.module_configuration['data'][n];

        console.debug('data = ' + data)

        form_temp.all_questions = _this.module_configuration['data'][n].all_questions

        form_temp.name = _this.module_configuration['topic'] + '-' + n;
        let folderName = 'item' + n;

        updateData(module_page_ref.child(_this.module_configuration['topic']).child('txt_items').child(folderName), form_temp); 
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name: _this.module_configuration['topic'], keywords:_this.module_configuration['topic']});
      
      for(let n=0; n<_this.module_configuration['data'].length; n++) {

        let user_addr = '/Custom_Exercises/'+uid+'/Modules/LanguageModules/' + _this.module_configuration['topic'] + '/txt_items/item'+n;
        let modx = {address:user_addr, category:'Language', id:n, name:_this.module_configuration['topic'] + '-' + n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n), modx); 

      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 

      curr_module_data['number_exercises'] = _this.module_configuration['data'].length

      curr_module_data['mod_name'] = 'Reading comprehension about ' + _this.module_configuration['topic']

      for(let i in curr_module_data) {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key), curr_module_data)
    },
    saveNewPaceInterestPlan()
    {
      const _this  = this;

      console.debug('save pace interest plan')      
      let uid = localStorage.getItem('uid');


      let n_exercises = _this.module_configuration['pace_interest']['text'].length

      console.debug('n_exercises = ' + n_exercises)
      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('SpeechModules');


      _this.number_exercises = _this.module_configuration['pace_interest'].text.length
      
      console.debug('module_page_ref = ' + module_page_ref)


      let topic = _this.module_configuration['pace_interest'].topic

      console.debug('modified ex name = '+ topic);


      let pace_interest_form = {
        name: topic,
        keywords:'',
        instruct: 'Press Start. Read the paragraph below aloud. Try to keep your pace with the highlighted word. No need to match exactly. Press Next when finished.',
        data:[],
        n_item:n_exercises,
      }

      updateData(module_page_ref.child(topic), pace_interest_form);

      let form_temp = {
        name: topic,
        instruct: pace_interest_form.instruct,
        text:topic,
        speechtype: ["sentencegibility","speech_rate"],
        exercise_type:'pace_training'
      };

      for(let n=0; n< _this.module_configuration['pace_interest'].text.length;n++)
      {
        form_temp.text =_this.module_configuration['pace_interest'].text[n];

        console.debug('curr_text = ' + form_temp.text)

        form_temp.name = _this.module_configuration['topic'] + '-' + n;
        let folderName = 'item' + n;

        updateData(module_page_ref.child(topic).child('txt_items').child(folderName), form_temp); 
     
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name: topic, keywords:topic});
      
      for(let n=0; n<_this.module_configuration['pace_interest'].text.length; n++) {

        let user_addr = '/Custom_Exercises/'+uid+'/Modules/SpeechModules/' + topic + '/txt_items/item'+n;
        let modx = {address:user_addr, category:'Speech', id:n, name:topic + '-' + n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n), modx); 

      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 

      curr_module_data['number_exercises'] = n_exercises

      curr_module_data['mod_name'] = 'Pace training with text about ' + topic

      for(let i in curr_module_data) {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key), curr_module_data)
    },
    saveNewPubQuizPlan()
    {
      const _this  = this;

      console.debug('save pub quiz plan')      
      let uid = localStorage.getItem('uid');

      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('SpeechModules');

      if(!_this.module_configuration['pub_quiz_form'].mod_name)
      {
        _this.module_configuration['pub_quiz_form'].mod_name = _this.module_configuration['pub_quiz_form'].topic  +'/'
      }

      _this.number_exercises = _this.module_configuration['pub_quiz_form'].data.length
      
      console.debug('modified ex name = '+_this.module_configuration['pub_quiz_form'].topic);
      console.debug('module_page_ref = ' + module_page_ref)

      updateData(module_page_ref.child(_this.module_configuration['pub_quiz_form'].topic), _this.module_configuration['pub_quiz_form']);

      let form_temp = {
        name: _this.module_configuration['pub_quiz_form'].mod_name,
        instruct: _this.module_configuration['pub_quiz_form'].instruct,
        text: _this.module_configuration['pub_quiz_form'].mod_name,
        exercise_type:'quiz',
        assist:['spm_live'],
        voicetype: ['spm'],
        speechtype:['sentencegibility'],
      };

      for(let n=0; n<_this.module_configuration['pub_quiz_form'].data.length;n++)
      {
        // form_temp.text = [];
        let data = _this.module_configuration['pub_quiz_form'].data[n];

        form_temp.question = data.question
        form_temp.answer = data.answer
        form_temp.options = data.options

        form_temp.name = _this.module_configuration['pub_quiz_form'].mod_name+'-'+n;
        let folderName = 'item'+n;
        updateData(module_page_ref.child(_this.module_configuration['pub_quiz_form'].topic).child('txt_items').child(folderName),form_temp); 
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name:_this.module_configuration['pub_quiz_form'].mod_name, keywords:_this.module_configuration['pub_quiz_form'].topic});
      for(let n=0;n<_this.module_configuration['pub_quiz_form'].data.length;n++)
      {
        let user_addr = '/Custom_Exercises/'+uid+'/Modules/SpeechModules/'+_this.module_configuration['pub_quiz_form'].topic+'/txt_items/item'+n;
        let modx = {address:user_addr, category:'Speech', id:n, name:_this.module_configuration['pub_quiz_form'].mod_name+'-'+n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n),modx); 
      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 

      curr_module_data['number_exercises'] = _this.module_configuration['pub_quiz_form'].data.length

      curr_module_data['mod_name'] = 'Pub quiz about ' + _this.module_configuration['pub_quiz_form'].topic  

      for(let i in curr_module_data)
      {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key),curr_module_data)
    },
    saveNewTextsPlan()
    {
      const _this  = this;

      console.debug('save new texts plan')      
      let uid = localStorage.getItem('uid');

      let module_page_ref = _this.$firebase.database().ref('Custom_Exercises').child(uid).child('Modules').child('SpeechModules');

      if(!_this.module_configuration['sentences_form'].mod_name)
      {
        for(let i in _this.module_configuration['sentences_form'].text)
        {
          _this.module_configuration['sentences_form'].mod_name = _this.module_configuration['sentences_form'].topic  + '_' + i + '/'
        }
      }

      _this.number_exercises = _this.module_configuration['sentences_form'].text.length
      
      
      console.debug('modified ex name = '+_this.module_configuration['sentences_form'].topic);
      console.debug('module_page_ref = ' + module_page_ref)

      updateData(module_page_ref.child(_this.module_configuration['sentences_form'].topic), _this.module_configuration['sentences_form']);
      // alert(_this.module_configuration['sentences_form'].mod_name)
      // console.debug('form text all= ',_this.module_configuration['sentences_form'].text[1]);
      let form_temp = {
        name: _this.module_configuration['sentences_form'].mod_name,
        instruct: _this.module_configuration['sentences_form'].instruct,
        text: _this.module_configuration['sentences_form'].mod_name,
        assist:['spm_live'],
        voicetype: ['spm'],
        speechtype:['sentencegibility'],
      };

      for(let n=0; n<_this.module_configuration['sentences_form'].text.length;n++)
      {
        // form_temp.text = [];
        let text_temp = _this.module_configuration['sentences_form'].text[n];

        form_temp.text = text_temp;
        form_temp.name = _this.module_configuration['sentences_form'].mod_name + '-' + n;

        console.debug('text form= ' + form_temp.text);

        let folderName = 'item' + n;
        updateData(module_page_ref.child(_this.module_configuration['sentences_form'].topic).child('txt_items').child(folderName), form_temp);        
      }
        
      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' ;
      
      console.debug('target_dir = ' + target_dir)
      let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
      let push_key = tmp_slt_homework_list.push().key;
      push_key = _this.table_assignment_list.length + "__" + push_key
      
      updateData(tmp_slt_homework_list.child(push_key), {mod_name:_this.module_configuration['sentences_form'].mod_name, keywords: _this.module_configuration['sentences_form'].topic});
      for(let n=0;n<_this.module_configuration['sentences_form'].text.length;n++)
      {
        let user_addr = '/Custom_Exercises/'+uid+'/Modules/SpeechModules/' + _this.module_configuration['sentences_form'].topic + '/txt_items/item' + n;
        let modx = {address:user_addr, category:'Speech', id:n, name:_this.module_configuration['sentences_form'].mod_name + '-' + n};
        updateData(tmp_slt_homework_list.child(push_key).child('exercises').child(n),modx); 
      }

      let temp_curr_module_data =  _this.curr_module.val()

      let curr_module_data = {}
      curr_module_data['keywords'] = temp_curr_module_data['keywords'] 

      curr_module_data['number_exercises'] = _this.module_configuration['sentences_form'].text.length


      curr_module_data['mod_name'] = 'Fun facts about ' + _this.module_configuration['sentences_form'].topic  

      for(let i in curr_module_data)
      {
        console.debug('curr_module_data = ' + i  + ' ' + curr_module_data[i])
      }

      updateData(tmp_slt_homework_list.child(push_key),curr_module_data)
    },
    getUserCustomModulesCascade()
    {
      let _this = this;
      
      let do_once = true;

      _this.mod_options = [];
      _this.custom_modules = [];

      let uid = localStorage.getItem('uid')

      let user_modules_dir = 'Custom_Modules/' + uid + '/Modules/'

      //console.debug('user_modules_dir = ' + user_modules_dir)

      let user_modules_ref = _this.$firebase.database().ref(user_modules_dir);

      _this.user_modules_dir = user_modules_dir;


      getData(user_modules_ref,function (err, result) {

        if(do_once)
        {
          do_once = false;

          let module_cate = 'Custom';
          let level_arr = [];

          for(let i in _this.mod_options)
          {
            if(_this.mod_options[i].value==module_cate)
            {
              _this.mod_options.splice(i,1)
            }
          }

          // result.child('Clients').child(_this.client_uid).child('Modules').forEach(function(childSnapshot)
          result.forEach(function(childSnapshot)
          {

            let module_level_temp = childSnapshot.key;

            //console.debug('module_level_temp = ' + module_level_temp)
            let module_level = module_level_temp;
            let module_level_label = module_level_temp
            // module_level_temp.substr(2);
            let exercise_arr = [];

            childSnapshot.forEach(function(child_level)
            {

              let module_exercise = child_level.val();
              var module_name = child_level.child('mod_name').val();
              var module_descript = child_level.child('keywords').val();
              let homework = module_exercise.homework


              // var module_date = childSnapshot.child('date').val();
              var key = child_level.key
              let module_address = user_modules_dir + module_level_temp+'/'+key
              // child_exercise.push({homework_temp: homework,mod_address: module_address});

              if(module_name!=null)
              {
                exercise_arr.push({value:{value:child_level,module_address:module_address},label:module_name})
                _this.custom_modules.push({value:{value:child_level,module_address:module_address},label:module_name})
              }
            });

            level_arr.push({value:module_level,label:module_level_label,children:exercise_arr});

          });
              
          _this.mod_options.push({value:module_cate,label:module_cate,children:level_arr});
        }
      });

    },
    find(name, send_to_homework, module_type) {
      let _this = this

      if (module_type === "training" && this.number_exercises > this.module_min_max_exercises[name]['max']) {
        alert("Please reduce the number of exercises to be less than " + this.module_min_max_exercises[name]['max'])
        return
      }

      if (module_type === "training" && this.number_exercises <= 0 && this.module_min_max_exercises[name]['max'] > 0) {
        alert("Please increase the number of exercises to be more than 0")
        return
      }

      if (name === "WordArticulation") { //if name is words or fun facts check that form is valid
        if (this.module_configuration['words_form'].text.length < 1) {
          alert("Please generate some words first.")
          return
        }
      }
      if (name === "FunFacts") {
        if (this.module_configuration['sentences_form'].text.length < 1) {
          alert("Please generate some fun facts first.")
          return
        }
      }
      if (name === "ShortParagraph") {
        if (this.module_configuration['short_paragraph_form'].text.length < 1) {
          alert("Please generate a paragraph first.")
          return
        }
      }
      if (name === "ShortParagraphPhoneme") {
        if (this.module_configuration['short_paragraph_form'].text.length < 1) {
          alert("Please generate a paragraph first.")
          return
        }
      }
      if (name === "PubQuiz") {
        if (this.module_configuration['pub_quiz_form'].data.length < 1) {
          alert("Please generate a quiz first.")
          return
        }
      }

      if (name === "ReadingComprehension") {
        if (this.module_configuration['data'].length < 1) {
          alert("Please generate the topic first.")
          return
        }
      }

      if (name === "PaceInterest") {
        if (this.module_configuration['name'] === '' || this.module_configuration['pace_interest'].text.length < 1) {
          alert("Please generate the topic first.")
          return
        }
      }

      if (module_type === 'training') {
        let found = false;      
        
        getData(_this.training_modules_database, function (err, result) {
          result.forEach(function(childSnapshot)
          {
            let module_name_db = childSnapshot.key;

            if (module_name_db == name && !found) {
              found = true;

              _this.curr_module = childSnapshot

              if (send_to_homework) {
                _this.addToAssignmentList(childSnapshot, module_type)
              } else {
                _this.startClinicModule(childSnapshot, module_type)
              }
              
            }
          });
          if (!found) {
            alert("couldn't find module in database. Please contact BV!")
          } 
        });
      
      } else if (module_type === 'assignment') {      
        
        //there is no need to find we know the id
      } else if (module_type === 'custom') {//Custom modules
        let module_address = _this.selected_module_object.value.module_address

        let user_modules_ref = _this.$firebase.database().ref(module_address);

        getData(user_modules_ref,function (err, result) {
          _this.curr_module = result

          if (send_to_homework) {
            _this.addToAssignmentList(result, module_type)
          } else {
            _this.startClinicModule(result, module_type)
          }
        });
      }

    },
    startClinicModule(module_handle, module_type)
    {
      const _this = this;

      console.debug('here 1')
          
      if(module_type === "custom") {

        let module_address = _this.selected_module_object.value.module_address
        let module_name = module_handle.child('mod_name').val();

        let exercise_arr = [];
        module_handle.child('exercises').forEach(function(exercise_handle)
        {
          let exercise_id = exercise_handle.key

          let exercise_address = _this.training_modules_database + '/' + module_name + '/exercises/' + exercise_id

          exercise_arr.push({exercise_handle: exercise_handle, module_address: exercise_address})
        });

        let module_data = {label: module_name, exercises: exercise_arr, module_address: module_address}

        _this.start_object.module_path = module_data.module_address//mod address

        _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
      } else if (module_type === "assignment") {

        console.debug('assignment')
        let module_address = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/homework/general/' + module_handle.key

        let module_name = module_handle.child('mod_name').val();

        let module_val = module_handle.val()

      

        let exercise_arr = [];
        module_handle.child('exercises').forEach(function(exercise_handle)
        {
          let exercise_id = exercise_handle.key

          let exercise_address = _this.training_modules_database + '/' + module_name + '/exercises/' + exercise_id

          exercise_arr.push({exercise_handle: exercise_handle, module_address: exercise_address})
        });


        let module_data = {
          label: module_name, 
          exercises: exercise_arr, 
          module_address: module_address
        }

        console.debug('module_val = ' + Object.keys(module_val))
        if('group_type' in module_val)
        {
          module_data.group_type = module_val.group_type
          _this.start_object.group_type = module_val.group_type
        }

        if('plan_type' in module_val)
        {
          module_data.plan_type = module_val.plan_type
          _this.start_object.plan_type = module_val.plan_type
        }


        _this.start_object.module_path = module_data.module_address//mod address
        _this.start_object.module_name = module_name

        _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

      } else if (module_type === "training") {
                

        console.debug('here 2')

        let module_name = module_handle.child('mod_name').val();

        let module_name_db = module_handle.key;
        let module_address = _this.training_modules_database + '/' + module_name_db
        let exercise_arr = [];

        module_handle.child('exercises').forEach(function(exercise_handle)
        {
          let exercise_id = exercise_handle.key

          let exercise_address = _this.training_modules_database + '/' + module_name + '/exercises/' + exercise_id

          if(module_name != null && exercise_arr.length < _this.number_exercises) {
            exercise_arr.push({exercise_handle: exercise_handle, module_address: exercise_address})
          }
        });

        let module_data = {label: module_name, exercises: exercise_arr, module_address: module_address}
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        console.debug('module_name = ' + module_name);
        let modifier_types = module_handle.child('modifier').val()

        if(_this.selected_module.includes("FunFacts") && (_this.autoGenWords.length>0 || _this.module_configuration['sentences_form'].text.length>0))
        {

          const _this  = this;
          if(_this.autoGenWords.length>0 && _this.module_configuration['sentences_form'].text.length==0) {
            _this.module_configuration['sentences_form'].text = _this.autoGenWords;
          }

          if(_this.form.mod_name==undefined || _this.form.mod_name==null)
          {
            _this.form.mod_name= ''
          }


          if(_this.form.mod_name=='')
          {
            for(let i in _this.module_configuration['sentences_form'].text)
            {
              _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['sentences_form'].text[i] + '/'
            }
          }

          _this.form.n_item = _this.module_configuration['sentences_form'].text.length

          console.debug('modifed ex name = '+_this.module_configuration['sentences_form'].name);

          _this.pre_loaded_exercises = []


          for(let n in _this.module_configuration['sentences_form'].text)
          {
            let form_temp = {
              name: _this.form.mod_name,
              instruct: _this.module_configuration['sentences_form'].instruct,
              text: _this.form.mod_name,
              assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['sentencegibility'],
              module_name: 'Word list',
              curr_exercise_path: 'word_list/',
              assessment_type: 'SLP_TRAINING'
            };

            let text_temp = _this.module_configuration['sentences_form'].text[n];

            form_temp.text = text_temp;
            form_temp.name = _this.form.mod_name+'-'+n;
            console.debug('text form= '+form_temp.text);

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises)
            {
              console.debug('_this.pre_loaded_exercises[n] = '  + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)
        }

        if(_this.selected_module.includes("ShortParagraph") &&  _this.module_configuration['short_paragraph_form'].text.length>0)
        {

          const _this  = this;

          _this.form.mod_name = _this.module_configuration['short_paragraph_form'].topic
          _this.form.n_item = _this.module_configuration['short_paragraph_form'].text.length

          console.debug('modifed ex name = '+_this.module_configuration['short_paragraph_form'].name);

          _this.pre_loaded_exercises = []

          for(let n in _this.module_configuration['short_paragraph_form'].text)
          {
            let form_temp = {
              name: _this.form.mod_name,
              instruct: _this.module_configuration['short_paragraph_form'].instruct,
              text: _this.form.mod_name,
              assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['sentencegibility'],
              module_name: 'Short Paragraph',
              curr_exercise_path: 'short_paragraph/',
              assessment_type: 'SLP_TRAINING'
            };

            let text_temp = _this.module_configuration['short_paragraph_form'].text[n];

            form_temp.text = text_temp;
            form_temp.name = _this.form.mod_name + '-' + n;
            console.debug('text form= ' + form_temp.text);

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises)
            {
              console.debug('_this.pre_loaded_exercises[n] = ' + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)

        }


        if(_this.selected_module.includes("ShortParagraphPhoneme") &&  _this.module_configuration['short_paragraph_form'].text.length>0)
        {

          const _this  = this;

          _this.form.mod_name = _this.module_configuration['short_paragraph_form'].topic
          _this.form.n_item = _this.module_configuration['short_paragraph_form'].text.length

          console.debug('modifed ex name = '+_this.module_configuration['short_paragraph_form'].name);

          _this.pre_loaded_exercises = []

          for(let n in _this.module_configuration['short_paragraph_form'].text)
          {
            let form_temp = {
              name: _this.form.mod_name,
              instruct: _this.module_configuration['short_paragraph_form'].instruct,
              text: _this.form.mod_name,
              assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['sentencegibility'],
              module_name: 'Short Paragraph',
              curr_exercise_path: 'short_paragraph/',
              assessment_type: 'SLP_TRAINING'
            };

            let text_temp = _this.module_configuration['short_paragraph_form'].text[n];

            form_temp.text = text_temp;
            form_temp.name = _this.form.mod_name + '-' + n;
            console.debug('text form= ' + form_temp.text);

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises)
            {
              console.debug('_this.pre_loaded_exercises[n] = ' + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)

        }

        if(_this.selected_module.includes("PubQuiz") &&  _this.module_configuration['pub_quiz_form'].data.length>0)
        {
          console.debug('start pub quiz')

          const _this  = this;

          if(!_this.form.mod_name)
          {
            _this.form.mod_name=''
          }


          if(_this.form.mod_name=='')
          {
            for(let i in _this.module_configuration['pub_quiz_form'].data)
            {
              _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['pub_quiz_form'].data[i].question + '/'
            }
          }

          _this.form.n_item = _this.module_configuration['pub_quiz_form'].data.length

          console.debug('modifed ex name = '+_this.module_configuration['pub_quiz_form'].name);

          _this.pre_loaded_exercises = []


          for(let n in _this.module_configuration['pub_quiz_form'].data)
          {
            let form_temp = {
              name: _this.module_configuration['pub_quiz_form'].mod_name,
              instruct: _this.module_configuration['pub_quiz_form'].instruct,
              text: _this.module_configuration['pub_quiz_form'].mod_name,
              assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['sentencegibility'],
              module_name: 'Pub quiz',
              curr_exercise_path: 'Pub Quiz/',
              assessment_type: 'SLP_TRAINING',
              exercise_type:'quiz'
            };

            let data = _this.module_configuration['pub_quiz_form'].data[n];

            form_temp.question = data.question;
            form_temp.answer = data.answer;
            form_temp.options = data.options;


            for(let k in form_temp)
            {
              console.debug('form_temp['+k+'] = ' + form_temp[k])
            }

            form_temp.name = _this.form.mod_name+'-'+n;

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises)
            {
              console.debug('_this.pre_loaded_exercises[n] = '  + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)
        }

        console.debug('here 3')


        if(_this.selected_module.includes("PaceInterest") &&  _this.module_configuration['pace_interest'].text.length>0)
        {

          const _this  = this;

          _this.form.mod_name = _this.module_configuration['pace_interest'].topic
          _this.form.n_item = _this.module_configuration['pace_interest'].text.length

          console.debug('modifed ex name = '+_this.module_configuration['pace_interest'].name);

          _this.pre_loaded_exercises = []

          for(let n in _this.module_configuration['pace_interest'].text)
          {
            let form_temp = {
              name: _this.form.mod_name,
              instruct: _this.module_configuration['pace_interest'].instruct,
              text: _this.form.mod_name,
              module_name: 'Pace Interest',
              speechtype:['sentencegibility','speech_rate'],
              exercise_type:'pace_training',
              curr_exercise_path: 'pace_interest/',
              assessment_type: 'SLP_TRAINING'
            };

            let text_temp = _this.module_configuration['pace_interest'].text[n];

            form_temp.text = text_temp;
            form_temp.name = _this.form.mod_name + '-' + n;
            console.debug('text form= ' + form_temp.text);

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises)
            {
              console.debug('_this.pre_loaded_exercises[n] = ' + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)

        }



        
        // if(_this.selected_module.includes("PaceInterest") && _this.module_configuration['data'].length>0)
        // {
        //   console.debug('start pace interest')

        //   const _this  = this;

        //   if(!_this.form.mod_name) {
        //     _this.form.mod_name=''
        //   }


        //   if(_this.form.mod_name=='') {
        //     for(let i in _this.module_configuration['data']) {
        //       // _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['data'][i].question + '/'
        //     }
        //   }

        //   _this.form.n_item = _this.module_configuration['data'].length

        //   console.debug('modifed ex name = '+ _this.module_configuration['name']);

        //   _this.pre_loaded_exercises = []

        //   let data = _this.module_configuration['data']
          


        //   for(let n=0; n<data.length; n++)
        //   {
        //     console.debug('data.length = ' + data.length)
        //     console.debug('data[n] = ' + data[n])

        //     for(let k in data[n]) {
        //       console.debug('data[n]['+k+']! = ' + data[n][k])
        //     }

        //     let form_temp = {
        //       name: 'pace_interest_' + n,
        //       instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
        //       text: data[n].all_questions[0].paragraph,
        //       module_name: 'Pace Interest',
        //       speechtype:['sentencegibility','speech_rate'],
        //       exercise_type:'pace_training',
        //       curr_exercise_path: 'text_list/',
        //       assessment_type: 'USER_TRAINING',
        //     }

        //     _this.pre_loaded_exercises.push(form_temp) 
        //   }

        //   _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

        // }

        if(_this.selected_module.includes("ReadingComprehension") && _this.module_configuration['data'].length>0)
        {
          console.debug('start reading comprehension')

          const _this  = this;

          if(!_this.form.mod_name) {
            _this.form.mod_name=''
          }


          if(_this.form.mod_name=='') {
            for(let i in _this.module_configuration['data']) {
              // _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['data'][i].question + '/'
            }
          }

          _this.form.n_item = _this.module_configuration['data'].length

          console.debug('modifed ex name = '+ _this.module_configuration['name']);

          _this.pre_loaded_exercises = []

          let data = _this.module_configuration['data']


          for(let n=0; n<data.length; n++)
          {
            console.debug('data.length = ' + data.length)
            console.debug('data[n] = ' + data[n])

            for(let k in data[n]) {
              console.debug('data[n]['+k+']! = ' + data[n][k])
            }

            let form_temp = {
              name: 'reading_comprehension_' + n,
              instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',

              all_questions: data[n].all_questions,
              module_name: 'Reading comprehension',
              exercise_type:'reading_comprehension',
              curr_exercise_path: 'text_list/',
              assessment_type: 'USER_TRAINING',
            }

            _this.pre_loaded_exercises.push(form_temp) 
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

        }
          
        if(_this.selected_module.includes("WordArticulation") && (_this.autoGenWords.length>0 || _this.module_configuration['words_form'].text.length>0))
        {

          const _this  = this;
          if(_this.autoGenWords.length > 0 && _this.module_configuration['words_form'].text.length == 0) {
            _this.module_configuration['words_form'].text = _this.autoGenWords;
          }

          if(this.form.mod_name==undefined || this.form.mod_name==null) {
            this.form.mod_name = ''
          }

          if(_this.form.mod_name=='') {
            for(let i in _this.module_configuration['words_form'].text) {
              _this.form.mod_name = _this.form.mod_name  + _this.module_configuration['words_form'].text[i] + '/'
            }
          }

          console.debug('_this.form.mod_name = ' + _this.form.mod_name)

          _this.form.n_item = _this.module_configuration['words_form'].text.length

          console.debug('modifed ex name = '+_this.module_configuration['words_form'].name);

          _this.pre_loaded_exercises = []


          for(let n in _this.module_configuration['words_form'].text)
          {
            let form_temp = {
              name: _this.form.mod_name,
              instruct: _this.module_configuration['words_form'].instruct,
              text: _this.form.mod_name,
              assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['intelligibility'],
              module_name: 'Word list',
              curr_exercise_path: 'word_list/',
              assessment_type: 'SLP_TRAINING'
            };

            let text_temp = _this.module_configuration['words_form'].text[n];

            form_temp.text = text_temp;
            form_temp.name = _this.form.mod_name+'-'+n;
            console.debug('text form= '+form_temp.text);

            _this.pre_loaded_exercises.push(form_temp)
              
            for(let n in _this.pre_loaded_exercises) {
              console.debug('_this.pre_loaded_exercises[n] = '  + _this.pre_loaded_exercises[n].text)
            }
          }

          _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

          console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)
        }

        if(_this.form.pre_loaded_exercises != undefined && _this.form.pre_loaded_exercises!=null && _this.form.pre_loaded_exercises.length>0)
        {
          _this.start_object.module_path = module_name
          _this.start_object.pre_loaded_exercises = _this.form.pre_loaded_exercises
          _this.start_object.module_name = module_name
          
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}})

          return
        }
        
        if(!module_handle.hasChild('exercises_location') && (modifier_types==undefined || modifier_types==null))
        {
          _this.start_object.module_path = module_data.module_address
          _this.start_object.module_name = module_name
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          
          return
        }


        if(module_handle.val().exercises_location && _this.selected_module.includes("News") && _this.module_configuration['topic']) {
          
          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['topic']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        }

        if(_this.selected_module.includes("pd_facial_expressions") && _this.module_configuration.hasOwnProperty('pd_facial_expression') && module_handle.val().exercises_location) {

          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['pd_facial_expression']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.total_requested_input_task_exercises = 5
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        }

        if(_this.selected_module.includes("stroke_facial_expressions") && _this.module_configuration.hasOwnProperty('stroke_facial_expression') && module_handle.val().exercises_location) 
        {

          let target_address = _this.curr_module.val().exercises_location + '/' + this.module_configuration['stroke_facial_expression']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.total_requested_input_task_exercises = 5
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        }
        
        if(_this.selected_module.includes("stroke_oral_muscle") && _this.module_configuration.hasOwnProperty('stroke_oral_muscle') && module_handle.val().exercises_location) 
        {

          let target_address = _this.curr_module.val().exercises_location + '/' + this.module_configuration['stroke_oral_muscle']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.total_requested_input_task_exercises = 5
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        }

        if(this.module_configuration['phoneme'] && _this.selected_module.includes("WordArticulation")) {
          
          let target_address = _this.curr_module.val().exercises_location + '/' + this.module_configuration['phoneme']

          let group_type = 'random_assortment'

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = group_type
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        } else if (_this.selected_module === "PhonemeArticulation" && _this.module_configuration.hasOwnProperty('phoneme')) {
          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['phoneme']

          let group_type = 'random_assortment'

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = group_type
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises

          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
          return
        }
        else if (_this.selected_module === "SinglePhonemeArticulation" && _this.module_configuration.hasOwnProperty('phoneme')) {
          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['phoneme'].toUpperCase()

          let group_type = 'random_assortment'

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = group_type
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises

          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: JSON.stringify(_this.start_object)}});
          return
        }

        if(_this.selected_module.includes("ListenMinimalPairs")) {

          let target_address = _this.curr_module.val().exercises_location

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }

        if(_this.selected_module.includes("WriteNumbers")) {

          let target_address = _this.curr_module.val().exercises_location

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }


        if(_this.selected_module.includes("MinimalPairs") && this.module_configuration.hasOwnProperty('sound_change')) {

          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['sound_change']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }

        if(_this.selected_module.includes("TongueTwistersSelection") && this.module_configuration.hasOwnProperty('tongue_twister_phoneme')) {

          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['tongue_twister_phoneme']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }

        if(_this.selected_module.includes("WordCategory") && _this.module_configuration.hasOwnProperty('word_category_type')) {

          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['word_category_type']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = 'Word Category'
          _this.start_object.plan_type = 'random'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises

          for(let n in _this.start_object) {
            console.debug('start_object['+n+'] = ' + _this.start_object[n])
          }

          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }

        if(_this.selected_module.includes("WordFinding") && _this.module_configuration.hasOwnProperty('difficulty')) {

          let target_address = _this.curr_module.val().exercises_location + '/' + _this.module_configuration['difficulty']

          _this.start_object.module_path = target_address
          _this.start_object.module_name = 'Word Finding'
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises

          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
        }

        if(_this.selected_module.includes("CopyWord")) {

          let target_address = _this.curr_module.val().exercises_location

          _this.start_object.module_path = target_address
          _this.start_object.module_name = module_name
          _this.start_object.plan_type = 'random_assortment'
          _this.start_object.total_requested_input_task_exercises = _this.number_exercises
          
          _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});

          return
          }
        
        console.debug("other modules")
        //all other modules go here
        _this.moduleAddresses = [];
            
        module_handle.forEach(function(childSnapshot)
        {
          let module_address = childSnapshot.child('address').val();
          let module_category = childSnapshot.child('category').val();

          if(module_address!=undefined) {
            _this.moduleAddresses.push({address:module_address, category:module_category});
          }

        });

        let group_type = module_handle.child('group_type').val()

        let target_address = ''

        if(group_type!=undefined && group_type!=null && group_type=='random_assortment') {
          target_address = module_handle.child('exercises_location').val()
        } else {
          group_type='fixed_assortment'
        }

        let temp_exercises = module_handle.child('number_exercises').val()
        
        if(temp_exercises==undefined || temp_exercises==null || temp_exercises<1) {
          temp_exercises = _this.number_exercises
        }
        
        console.debug('target_address = ' + target_address)
        console.debug('group_type: ' + group_type)
        console.debug('total_requested_input_task_exercises: ' + temp_exercises)
        console.debug('module_name: ' + module_name)

        _this.start_object.module_path = target_address  
        _this.start_object.module_name = module_name
        _this.start_object.plan_type = group_type
        _this.start_object.total_requested_input_task_exercises = temp_exercises
        
        _this.$router.push({ name: 'clinic_training', query: {module_name: module_name, start_object: encodeURIComponent(JSON.stringify(_this.start_object))}});
      }
      
    },
    
  }
}
</script>
<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}

</style>